import React, { useEffect, useState } from "react";
import { myFeach, myPutFeach } from "../../api";

export default function EditSelectFieldModal({
  activeInputKey,
  closeModal,
  seteditedFieledValue,
  oldValue,
  reqestType,
  reqId,
  regetReqDetailes,
}) {
  const [list, setlist] = useState([]);
  const [loader, setloader] = useState({ status: "", text: "" });
  const [submitLoader, setsubmitLoader] = useState({ status: "", text: "" });
  const [newValue, setnewValue] = useState(
    oldValue?.id ? oldValue.id : oldValue._id
  );
  const handleGetList = () => {
    myFeach(activeInputKey.getListApiPath, "GET")
      .then(({ data }) => {
        let keys = Object.keys(data);
        let firstKey = keys[0];
        setlist(data[firstKey]);
        setloader({ text: "", status: "done" });
      })
      .catch((err) => {
        console.log(err);
        setloader({ text: "فشل تحميل السنوات", status: "failed" });
      });
  };
  const handleSubmit = () => {
    setsubmitLoader({ status: "loading", text: "" });
    let filedKey=activeInputKey.key==="albums"?"album":activeInputKey.key
    myPutFeach(`${reqestType.submitChangesApiPath}/${reqId}`, {
      [filedKey]:
        activeInputKey.key === "albums" ? newValue : [newValue],
    })
      .then((res) => {
        setsubmitLoader({ status: "done", text: "" });
        regetReqDetailes();
        closeModal();
      })
      .catch((err) => {
        console.log(err);
        setsubmitLoader({ status: "failed", text: "فشل تعديل الحقل" });
      });
  };
  useEffect(() => {
    console.log(newValue);
    handleGetList();
  }, []);
  return (
    <div className=" absolute top-9 left-32  my_rounded shadow-sm shadow-black bg-white flex flex-col px-3 pb-2 edit_new_content">
      <div className="w-full ">
        <button onClick={closeModal} className="h3">
          x
        </button>
      </div>
      <label>{activeInputKey.title}</label>
      {loader.status === "done" ? (
        <select
          defaultValue={newValue}
          onChange={(e) => setnewValue(e.target.value)}
          className="_my_greay_border w-80 h-9"
        >
          <>
            <option></option>
            {list.map((elem, index) => (
              <>
                {elem?.name !== "الكل" && elem?.name !== "وثائق" && (
                  <option value={elem?.id ? elem.id : elem._id} key={index}>
                    {elem?.year}
                    {elem?.name}
                  </option>
                )}
              </>
            ))}
          </>
        </select>
      ) : (
        <div className="_my_greay_border w-80 h-9 flex items-center">
          {loader.status === "failed" ? (
            <h4 className="text-red-400 w-full text-center text-xs mt-2">
              {loader.text}
            </h4>
          ) : (
            <div className="loader"></div>
          )}
        </div>
      )}
      <button
        type="button"
        className="bg_secondary h-9 w-80  my_rounded mt-2"
        onClick={handleSubmit}
      >
        {submitLoader.status == "loading" ? (
          <div className="loader"></div>
        ) : (
          "حفظ"
        )}
      </button>
      {submitLoader.status === "failed" && (
        <h4 className="text-red-400 w-full text-center text-xs mt-2">
          {submitLoader.text}
        </h4>
      )}
    </div>
  );
}
