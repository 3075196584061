import React, { useEffect, useRef, useState } from "react";
import CustomFileInput from "../reuseable/CustomFileInput";
import CustomInput from "../reuseable/CustomInput";
import CategoryMoal from "../books/CategoriesModl";
import { myFeach, myPutFeach } from "../../api";
import SucsessMessageModal from "../reuseable/SucsessMessageModal";
import PageLoader from "../reuseable/PageLoader";
import CustomTextArea from "../reuseable/CustomTextArea";
export default function AddBookModal({
  closeModal,
  regetVideos,
  isEdit,
  videoId,
  vedio_audio,
}) {
  const [videoData, setvideoData] = useState({
    mediaPath: "",
    title: "",
    publishedAt: "",
  });
  const [inputsErr, setinputsErr] = useState({});
  const [getOldDataLoader, setgetOldDataLoader] = useState({
    status: isEdit ? "loading" : "done",
    text: "جاري تحميل بيانات المقطع",
  });
  const [submitLoader, setsubmitLoader] = useState({ status: "", text: "" });
  const [sucsessMsg, setsucsessMsg] = useState(false);
  const [categorys, setcategorys] = useState({
    loader: { status: "loading", text: "" },
    data: [],
  });
  const [openCategoryMoal, setopenCategoryMoal] = useState({ status: false });
  // handle get all categories to show on dropdwoen
  const getCategories = () => {
    if (categorys.loader.status !== "loading") {
      setcategorys({
        ...categorys,
        loader: { status: "loading", text: "جاري تحميل التصنييفات" },
      });
    }
    myFeach(`categories`, "GET")
      .then(({ data }) => {
        setcategorys({
          loader: {
            status: data.categories.length == 0 ? "failed" : "done",
            text: data.categories.length == 0 ? "لا يوجد تصنيفات" : "",
          },
          data: data.categories,
        });
      })
      .catch((err) => {
        console.log(err);
        setcategorys({ loader: { status: "failed", text: "" }, data: [] });
      });
  };
  const checkIfCatSelectHasValue = () => {
    if (isEdit && videoData?.categories?.length) {
      return true;
    } else {
      return false;
    }
  };
  const handleSelectCategory = (e) => {
    if (e.target.value == "false") {
      setvideoData({ ...videoData, categories: [] });
    } else {
      setvideoData({
        ...videoData,
        categories: [e.target.value],
      });
    }
  };
  // handle get book old data if its edit page
  const getVideoOldData = () => {
    if (getOldDataLoader.status !== "loading") {
      setgetOldDataLoader({
        status: "loading",
        text: "جاري تحميل بيانات المقطع",
      });
    }
    myFeach(`media/${videoId}`, "GET")
      .then(({ data }) => {
        console.log(data);
        setvideoData({
          imagePath: data.imagePath,
          title: data.title,
          publishedAt: data.publishedAt,
          mediaPath: data.mediaPath,
          categories: [...data.categories],
        });
        setgetOldDataLoader({ status: "done", text: "" });
      })
      .catch((err) => {
        console.log(err);
        setgetOldDataLoader({
          status: "failed",
          text: "فشل تحميل بيانات المقطع",
        });
      });
  };
  // handle on change inputs
  const handleOnchange = (e) => {
    setvideoData({ ...videoData, [e.target.name]: e.target.value });
  };
  // handle get current year to be maximam of input
  function getCurrentYear() {
    const currentDate = new Date();
    const currentYear = currentDate.getFullYear();
    return currentYear;
  }
  // handle submit book if its edit or add book
  const checkReqInputs = () => {
    let myinputsErr;
    for (let key in videoData) {
      if (!videoData[key]) {
        if (key !== "imagePath") {
          myinputsErr = { ...myinputsErr, [key]: "هذا الحقل مطلوب" };
        }
      }
    }
    setinputsErr({ ...myinputsErr });
    if (myinputsErr) {
      return false;
    }
  };
  const removeFalsyKeys = (obj) => {
    for (let key in obj) {
      if (!obj[key]) {
        delete obj[key];
      }
    }
    return obj;
  };
  const handleAddvideo = () => {
    setsubmitLoader({ status: "loading", text: "" });
    myFeach(`media/type/${vedio_audio}`, "POST", videoData, "authrized")
      .then((res) => {
        setsubmitLoader({ status: "done", text: "" });
        regetVideos();
        setsucsessMsg(true);
        const selectElement = document.getElementById("mySelect");
        if (selectElement) {
          selectElement.selectedIndex = 0;
        }
        if (vedio_audio === "audio") {
          setvideoData({});
        } else {
          setvideoData({});
        }
      })
      .catch((err) => {
        setsubmitLoader({ status: "failed", text: "" });
        console.log("err", err);
        let myInputsErr = JSON.parse(err.message).errors;
        setinputsErr({ ...myInputsErr });
      });
  };
  const handleEditVedio = () => {
    setsubmitLoader({ status: "loading", text: "" });
    myPutFeach(`media/${vedio_audio}/${videoId}`, removeFalsyKeys(videoData))
      .then((data) => {
        setsubmitLoader({ status: "done", text: "" });
        regetVideos();
        setsucsessMsg(true);
        getVideoOldData();
      })
      .catch((err) => {
        setsubmitLoader({ status: "failed", text: "فشل تعديل المقطع" });
        console.log("err", err);
        let myInputsErr = JSON.parse(err.message).errors;
        setinputsErr({ ...myInputsErr });
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkReqInputs() === false) {
      return;
    } else {
      if (isEdit) {
        handleEditVedio();
      } else {
        handleAddvideo();
      }
    }
    // checkReqInputs();
  };
  useEffect(() => {
    getCategories();
    if (isEdit) {
      getVideoOldData();
    }
  }, []);
  return (
    <div className="modal_container">
      {/* modales */}
      {sucsessMsg && (
        <SucsessMessageModal
          close={() => setsucsessMsg(false)}
          text={"تمت الإضافة بنجاح"}
        />
      )}
      {openCategoryMoal.status && (
        <CategoryMoal
          categorys={categorys.data}
          closeModal={() => setopenCategoryMoal({ status: false })}
          regetCategorys={getCategories}
          getCategoriesLoader={categorys.loader}
        />
      )}
      {/* end modals */}
      <button onClick={closeModal} className="modal_bg"></button>
      <div className="esc_modal_year_detailes">
        <button onClick={closeModal}>x</button>
      </div>
      <div className="modal_content">
        {getOldDataLoader.status === "done" ? (
          <form onSubmit={handleSubmit}>
            <div className="inputs w-10/12">
              <div className="mt-8 w-10/12">
                <CustomFileInput
                  addedImage={videoData.imagePath}
                  title="قم برفع الصورة بحد أقصى 2MB"
                  img="https://i.postimg.cc/3J8fqYnd/imge.png"
                  errours={inputsErr}
                  name="imagePath"
                  onchangeFun={
                    (url) => setvideoData({ ...videoData, imagePath: url })
                    // videoData={...videoData,imagePath:url}
                  }
                  uplodePath={`media/${vedio_audio}/image`}
                  isFile={false}
                  isEdit={{ status: isEdit, path: videoData.imagePath }}
                />
              </div>
              <CustomInput
                handleOnchange={handleOnchange}
                width="w-7/12"
                label="عنوان المقطع"
                name="title"
                errours={inputsErr}
                value={videoData.title}
              />
              <CustomInput
                handleOnchange={handleOnchange}
                width="w-7/12"
                label="رابط لمقطع"
                name="mediaPath"
                errours={inputsErr}
                value={videoData.mediaPath}
                langoge="english"
              />
              <CustomInput
                handleOnchange={handleOnchange}
                width="w-7/12"
                label="سنة رفع المقطع"
                name="publishedAt"
                errours={inputsErr}
                value={videoData.publishedAt}
                type="number"
                // min="1000"
                max={getCurrentYear()}
              />
              {vedio_audio == "audio" && (
                <CustomTextArea
                  handleOnchange={handleOnchange}
                  width="w-7/12"
                  label="إضافة تعليق على المقطع"
                  name="brief"
                  errours={inputsErr}
                  value={videoData.brief}
                />
              )}
              <div className="w-full">
                <label>اختر التصنيف</label>
                {categorys.loader.status === "done" && (
                  <div className="w-full flex ">
                    <select
                      id="mySelect"
                      defaultValue={
                        checkIfCatSelectHasValue() &&
                        videoData.categories[0]._id
                      }
                      className="w-7/12 thin_border h-8"
                      onChange={handleSelectCategory}
                    >
                      <option value={false}></option>
                      {categorys.data.map((elem, index) => (
                        <option
                          key={index}
                          value={elem.id ? elem.id : elem._id}
                        >
                          {elem.name}
                        </option>
                      ))}
                    </select>
                    <button
                      type="button"
                      className="my_rounded bg_success px-3 py-1 mx-2"
                      onClick={() => setopenCategoryMoal({ status: true })}
                    >
                      تعديل التصنيف
                    </button>
                  </div>
                )}
                {categorys.loader.status === "loading" && (
                  <div className="w-7/12 thin_border h-8 flex items-center justify-center">
                    <div className="loader"></div>
                  </div>
                )}
                {categorys.loader.status === "failed" && (
                  <div className="w-full flex">
                    <div className="w-7/12 thin_border h-8 flex items-center justify-center">
                      <h4 className="text-red-400 text-xs">
                        {categorys.loader.text}
                      </h4>
                    </div>
                    <button
                      type="button"
                      className="my_rounded bg_success px-3 py-1 mx-2"
                      onClick={() => setopenCategoryMoal({ status: true })}
                    >
                      تعديل التصنيف
                    </button>
                  </div>
                )}
              </div>
            </div>
            <button
              disabled={submitLoader.status === "loading"}
              type="submit"
              className="my_rounded bg_secondary flex justify-center items-center w-full mt-4 py-2 h-12 h3"
            >
              {submitLoader.status === "loading" ? (
                <div className="loader"></div>
              ) : (
                "حفظ"
              )}
            </button>
            {submitLoader.status === "failed" && (
              <h4 className="text-xs w-full text-center text-red-400 mt-2">
                فشل إضافة المقطع
              </h4>
            )}
          </form>
        ) : (
          <PageLoader loader={getOldDataLoader} text={getOldDataLoader.text} />
        )}
      </div>
    </div>
  );
}
const categories = [
  { title: "التصنيف 1", id: "key 1" },
  { title: "اتصنيف 2", id: "key 2" },
  { title: "التصنيف 3", id: "" },
];
