import React, { useEffect, useState } from "react";
import { myFeach } from "../../api";
import PageLoader from "../reuseable/PageLoader";
export default function PersonDetailesModal({
  setopenDetailesModal,
  headers,
  detailes,
  id,
}) {
  const closeModal = () => {
    setopenDetailesModal({ status: false, personId: "" });
  };
  const [loader, setloader] = useState({
    status: "loading",
    text: "جاري تحميل بيانات الشخص",
  });
  const [person, setperson] = useState({
    status: "loading",
    text: "جاري تحميل بيانات الشخص",
  });

  // handle get people based on search key
  const getPerson = () => {
    // setloader({ status: "loading", text: "يتم تحميل بيانات الأشخاص" });
    myFeach(`people/${id}`, "GET")
      .then(({ data }) => {
        console.log(data);
        setloader({ status: "done", text: "" });
        setperson({
          img: data.profileImage
            ? data.profileImage
            : "https://i.postimg.cc/zvQT2nJ6/Avatar.png",
          name: `${data.firstName} ${data.lastName}`,
          detailes: data.brief ? data.brief : "------------",
          years: data.years,
          birth_date: data.yearOfBirth,
          death_date: data.yearOfDeath,
        });
      })
      .catch((err) => {
        console.log("error", err);
        setloader({ status: "failed", text: "فشل تحميل بيانات الأشخاص" });
      });
  };

  useEffect(() => {
    getPerson();
    console.log(id);
  }, []);
  return (
    <>
      <div className="modal_container">
        <button onClick={closeModal} className="modal_bg"></button>
        <div className="small_modal_conrent">
          <button className="text_greay text-xl" onClick={closeModal}>
            X
          </button>
          {loader.status === "done" ? (
            <div className="person_detaile">
              <figure className="m-auto h-32 w-full ">
                <img
                  className="block h-full my_rounded m-auto"
                  src={person.img}
                />
              </figure>
              <h3 className="mx-auto mt-2 w-fit font_primary">
                {person?.title}
              </h3>
              {detailes?.audio && <audio controls className="mx-auto mt-4" />}
              <div className="person_data w-4/5 mx-auto mt-10">
                {headers.map((elem, index) => (
                  <div className="row" key={index}>
                    <h4 className={`${elem.class} title `}>{elem.title}</h4>
                    {elem.key !== "years" ? (
                      <>
                        <h4 className={`${elem.class} info`}>
                          {person[elem?.key]}{" "}
                        </h4>
                      </>
                    ) : (
                      <h4 className={`${elem.class} info`}>
                      {person.years.map((elem,index)=>(
                          <span key={index} className="inline-block" >{index!==0&&","} {elem.year}</span>
                      ))}
                      </h4>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <PageLoader text={loader.text} loader={loader} />
          )}
        </div>
      </div>
    </>
  );
}
