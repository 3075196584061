import React, { useState } from "react";
// components
import CustomTextArea from "../reuseable/CustomTextArea";
import CustomFileInput from "../reuseable/CustomFileInput";
import EditAlbumsModal from "./EditAlbumsModal";
import { myFeach, myPutFeach } from "../../api";
import { useEffect } from "react";
import SucsessMessageModal from "../reuseable/SucsessMessageModal";

export default function AddPhotoModal({
  closeModal,
  isEdit = false,
  regetAlbums,
  regetPhotos,
  oldPhoToData,
}) {
  const [addedImageData, setaddedImageData] = useState({
    imagePath: isEdit ? oldPhoToData.imagePath : "",
    description: isEdit ? oldPhoToData?.description : "",
    album: isEdit ? oldPhoToData.album : "",
  });
  const [openShowAlbumsModal, setopenShowAlbumsModal] = useState(false);
  const [inputsErr, setinputsErr] = useState({});
  const [albums, setalbums] = useState({
    loader: { status: "loading", text: "جاري تحميل الألبومات" },
    data: [],
  });
  const [submitLoader, setsubmitLoader] = useState({ status: "", text: "" });
  const [sucsessMsg, setsucsessMsg] = useState(false);
  const getAllAlbums = () => {
    if (albums.loader.status !== "loading") {
      setalbums({
        ...albums,
        loader: { status: "loading", text: "جاري تحميل الألبومات" },
      });
    }
    myFeach(`albums`, "GET")
      .then(({ data }) => {
        if (data.albums.length == 0) {
          setalbums({
            ...albums,
            data: [],
            loader: { status: "failed", text: "لا يوجد ألبومات لعرضها" },
          });
        } else {
          setalbums({
            data: data.albums,
            loader: { status: "done", text: "" },
          });
          if (!isEdit) {
            setaddedImageData({
              ...addedImageData,
              album: data.albums.find((item) => item.name === "أخرى")._id,
            });
          }
        }
      })
      .catch((err) => {
        console.log("errp...", err);
        setalbums({
          ...albums,
          loader: { status: "failed", text: "فشل تحميل الألبومات" },
        });
      });
  };
  // handle submit image if its add or edit
  const checkReqInputs = () => {
    let myinputsErr;
    for (let key in addedImageData) {
      if (!addedImageData[key]) {
        if (key !== "album") {
          myinputsErr = { ...myinputsErr, [key]: "هذا الحقل مطلوب" };
        }
      }
    }
    setinputsErr({ ...myinputsErr });
    if (myinputsErr) {
      return false;
    }
  };
  const removeFalsyKeys = (obj) => {
    for (let key in obj) {
      if (!obj[key]) {
        delete obj[key];
      }
    }
    return obj;
  };
  const handleAddImage = () => {
    setsubmitLoader({ status: "loading", text: "" });
    let docsAlbumId = albums.data.find((item) => item.name === "وثائق")?._id;
    let url =
      addedImageData.album === docsAlbumId ? "images?type=وثيقه" : "images";
    myFeach(url, "POST",removeFalsyKeys(addedImageData) , "authrized")
      .then((res) => {
        setsubmitLoader({ status: "done", text: "" });
        // regetAlbums();
        regetPhotos();
        setsucsessMsg(true);
        setaddedImageData({
          ...addedImageData,
          description: "",
          imagePath: "",
          album: albums.data.find((item) => item.name === "أخرى")?._id,
          // imagePath:""
        });
        // document.getElementById("myFileInput").value=""
      })
      .catch((err) => {
        setsubmitLoader({ status: "failed", text: "" });
        console.log("err", err);
        let myInputsErr = JSON.parse(err.message).errors;
        setinputsErr({ ...myInputsErr });
      });
  };
  const handleEditImage = () => {
    setsubmitLoader({ status: "loading", text: "" });
    myPutFeach(`images/${oldPhoToData.id}`,removeFalsyKeys(addedImageData) )
      .then((data) => {
        setsubmitLoader({ status: "done", text: "" });
        regetPhotos();
        setsucsessMsg(true);
        // setTimeout(() => {
        //   closeModal();
        // }, 500);
      })
      .catch((err) => {
        setsubmitLoader({ status: "failed", text: "فشل تعديل الصورة" });
        console.log("err", err);
        let myInputsErr = JSON.parse(err.message).errors;
        setinputsErr({ ...myInputsErr });
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (checkReqInputs() === false) {
      return;
    } else {
      if (isEdit) {
        handleEditImage();
      } else {
        handleAddImage();
      }
    }
  };
  useEffect(() => {
    getAllAlbums();
  }, []);
  return (
    <div className="modal_container">
      {openShowAlbumsModal && (
        <EditAlbumsModal
          getAlbumsLoader={albums.loader}
          regetalbums={getAllAlbums}
          albums={albums.data}
          closeModal={() => setopenShowAlbumsModal(false)}
        />
      )}
      {sucsessMsg && (
        <SucsessMessageModal
          close={() => setsucsessMsg(false)}
          text={"تمت الإضافة بنجاح"}
        />
      )}
      <button onClick={closeModal} className="modal_bg"></button>
      <div className="esc_modal_year_detailes">
        <button onClick={closeModal}>x</button>
      </div>
      <div className="modal_content">
        <form onSubmit={handleSubmit}>
          <div className="inputs w-10/12">
            <div className="mt-8 w-10/12">
              <CustomFileInput
                addedImage={addedImageData?.imagePath}
                title="قم برفع الصورة بحد أقصى 2"
                img="https://i.postimg.cc/3J8fqYnd/imge.png"
                errours={inputsErr}
                name="imagePath"
                onchangeFun={(url) =>
                  setaddedImageData({ ...addedImageData, imagePath: url })
                }
                uplodePath="images/upload"
                isFile={false}
                isEdit={{ status: isEdit, path: addedImageData.imagePath }}
              />
            </div>
            <div className="w-full my-6">
              <label>اختر الألبوم</label>
              <div className="w-full flex mt-2">
                {albums.loader.status === "done" ? (
                  <>
                    <select
                      onChange={(e) =>
                        setaddedImageData({
                          ...addedImageData,
                          album: e.target.value,
                        })
                      }
                      value={addedImageData.album}
                      className="w-7/12 thin_border h-8 flex items-center justify-center"
                    >
                      {albums.data.map((elem, index) => (
                        <>
                          {index !== 0 && (
                            <option value={elem?._id} key={index}>
                              {elem.name}
                            </option>
                          )}
                        </>
                      ))}
                    </select>
                    <button
                      type="button"
                      className="my_rounded bg_success px-3 py-1 mx-2"
                      onClick={() => setopenShowAlbumsModal(true)}
                    >
                      تعديل الألبومات
                    </button>
                  </>
                ) : (
                  <div className="w-7/12 thin_border h-8 flex items-center justify-center">
                    {albums.loader.status === "failed" ? (
                      <h6 className="text-xs text-red-400">
                        {albums.loader.text}
                      </h6>
                    ) : (
                      <div className="loader"></div>
                    )}
                  </div>
                )}
              </div>
            </div>
            {/* <CustomTextArea
              handleOnchange={(e) =>
                setaddedImageData({
                  ...addedImageData,
                  description: e.target.value,
                })
              }
              value={(addedImageData?.description)?addedImageData.description:""}
              name="description"
              width="w-7/12"
              label="إضافة تعليق على الصورة"
            /> */}
            <>
              <div className="form_row flex w-full justify-between mt-4 ">
                <div className={`w-7/12 flex flex-col`}>
                  <label>إضافة تعليق على الصورة</label>
                  <textarea
                    onChange={(e) =>
                      setaddedImageData({
                        ...addedImageData,
                        description: e.target.value,
                      })
                    }
                    name="description"
                    type="text"
                    className={`my_rounded _my_greay_border w-full h-36 px-1 `}
                    value={addedImageData?.description}
                  />
                </div>
              </div>
              {inputsErr["description"] && (
                <h4 className="w-full text-red-400 text-xs mt-1">
                  {inputsErr["description"]}
                </h4>
              )}
            </>
          </div>
          <button
            disabled={submitLoader.status === "loading"}
            type="submit"
            className="my_rounded bg_secondary w-full mt-4 py-2 h3 h-12"
          >
            {submitLoader.status === "loading" ? (
              <div className="loader"></div>
            ) : (
              "حفظ"
            )}
          </button>
          {submitLoader.status === "failed" && (
            <h6 className="text-xs text-center text-red-400">
              {submitLoader.text}
            </h6>
          )}
        </form>
      </div>
    </div>
  );
}
