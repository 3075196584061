import React from "react";

export default function RequestIcon({ pathname }) {
  return (
    <>
      {pathname !== "/requests" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            opacity="0.7"
            d="M19.74 7.44H0V4.42C0 1.98 1.98 0 4.42 0H6.75C8.38 0 8.89 0.53 9.54 1.4L10.94 3.26C11.25 3.67 11.29 3.73 11.87 3.73H14.66C17.03 3.72 19.05 5.28 19.74 7.44Z"
            fill="white"
          />
          <path
            d="M19.99 8.84104C19.97 8.35104 19.89 7.89104 19.74 7.44104H0V14.651C0 17.601 2.4 20.001 5.35 20.001H14.65C17.6 20.001 20 17.601 20 14.651V9.07104C20 9.00104 20 8.91104 19.99 8.84104ZM12.5 14.251H10.81V16.001C10.81 16.411 10.47 16.751 10.06 16.751C9.65 16.751 9.31 16.411 9.31 16.001V14.251H7.5C7.09 14.251 6.75 13.911 6.75 13.501C6.75 13.091 7.09 12.751 7.5 12.751H9.31V11.001C9.31 10.591 9.65 10.251 10.06 10.251C10.47 10.251 10.81 10.591 10.81 11.001V12.751H12.5C12.91 12.751 13.25 13.091 13.25 13.501C13.25 13.911 12.91 14.251 12.5 14.251Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="20"
          height="20"
          viewBox="0 0 20 20"
          fill="none"
        >
          <path
            d="M19.74 7.44H0V4.42C0 1.98 1.98 0 4.42 0H6.75C8.38 0 8.89 0.53 9.54 1.4L10.94 3.26C11.25 3.67 11.29 3.73 11.87 3.73H14.66C17.03 3.72 19.05 5.28 19.74 7.44Z"
            fill="white"
          />
          <path
            d="M19.99 8.84104C19.97 8.35104 19.89 7.89104 19.74 7.44104H0V14.651C0 17.601 2.4 20.001 5.35 20.001H14.65C17.6 20.001 20 17.601 20 14.651V9.07104C20 9.00104 20 8.91104 19.99 8.84104ZM12.5 14.251H10.81V16.001C10.81 16.411 10.47 16.751 10.06 16.751C9.65 16.751 9.31 16.411 9.31 16.001V14.251H7.5C7.09 14.251 6.75 13.911 6.75 13.501C6.75 13.091 7.09 12.751 7.5 12.751H9.31V11.001C9.31 10.591 9.65 10.251 10.06 10.251C10.47 10.251 10.81 10.591 10.81 11.001V12.751H12.5C12.91 12.751 13.25 13.091 13.25 13.501C13.25 13.911 12.91 14.251 12.5 14.251Z"
            fill="#D59A2C"
          />
        </svg>
      )}
    </>
  );
}
