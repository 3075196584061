import React, { useState } from "react";
import { myPutFeach } from "../../api";

export default function EditFiledModal({
  closeModal,
  seteditedFieledValue,
  activeInputKey,
  oldValue,
  reqestType,
  reqId,
  regetReqDetailes,
}) {
  const [loader, setloader] = useState({ status: "", text: "" });
  const handleSubmit = () => {
    setloader({ status: "loading", text: "" });
    myPutFeach(`${reqestType.submitChangesApiPath}/${reqId}`, {
      [activeInputKey.key]: oldValue,
    })
      .then((res) => {
        setloader({ status: "done", text: "" });
        regetReqDetailes()
        closeModal();
      })
      .catch((err) => {
        console.log(err);
        setloader({ status: "failed", text: "فشل تعديل الحقل" });
      });
  };
  return (
    <div className=" absolute top-9 left-32  my_rounded shadow-sm shadow-black bg-white flex flex-col px-3 pb-2 edit_new_content">
      <div className="w-full ">
        <button onClick={closeModal} className="h3">
          x
        </button>
      </div>
      <label>{activeInputKey.title}</label>
      <input
        value={oldValue}
        className="_my_greay_border w-80 h-9"
        onChange={(e) => seteditedFieledValue(e.target.value)}
      />
      <button
        type="button"
        className="bg_secondary h-9 w-80  my_rounded mt-2"
        onClick={handleSubmit}
      >
        {loader.status == "loading" ? <div className="loader"></div> : "حفظ"}
      </button>
      {loader.status === "failed" && (
        <h4 className="text-red-400 w-full text-center text-xs mt-2">
          {loader.text}
        </h4>
      )}
    </div>
  );
}
