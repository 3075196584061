import React, { useEffect, useState } from "react";
// data
// components
import CustomTable from "../components/reuseable/CustomTable";
// icons
import { BiShow } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { myFeach } from "../api";
import Pagenation from "../components/reuseable/Pagination";
import PageLoader from "../components/reuseable/PageLoader";
import Confirm from "../components/reuseable/Confirm";
import SucsessMessageModal from "../components/reuseable/SucsessMessageModal";
import VideoSlider from "../components/videos/VediosSlider";
import AddVideoCoverModal from "../components/videos/AddCoverVideoModal";
import AddVideoModal from "../components/videos/AddVideoModal";
import VideoDetailesModal from "../components/videos/VideosDetailes";
export default function Videos() {
  const [modal, setmodal] = useState({
    status: false,
    modalType: "",
    id: "",
  });
  const [activeVideo, setactiveVideo] = useState("");
  const [activeSlider, setactiveSlider] = useState();
  const [openConfirmModal, setopenConfirmModal] = useState({
    status: false,
    text: "",
    id: "",
    isCover: "",
  });
  const [confirmLoader, setconfirmLoader] = useState({
    status: "confirm",
    text: "",
  });
  const [slider, setslider] = useState({
    data: [],
    loader: { status: "loading", text: "جاري تحميل إعلانات المقاطع" },
  });
  const [videosList, setvideosList] = useState({
    data: [],
    loader: { status: "loading", text: "يتم تحميل المقاطع" },
    numberOfPages: "",
  });
  const [searchKey, setsearchKey] = useState("");
  const [page, setpage] = useState(1);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // check modal status
  const checkModal = (modalName) => {
    let result = false;
    if (modal.status === true && modal.modalType === modalName) {
      result = true;
    }
    return result;
  };
  const getSliderData = () => {
    if (slider.loader.status !== "loading") {
      setslider({
        ...slider,
        loader: { status: "loading", text: "جاري تحميل الإعلانات" },
      });
    }
    myFeach("media/slider/type/video", "GET")
      .then(({data} ) => {
        if (data?.allMedia?.length == 0) {
          setslider({
            ...slider,
            data: data.allMedia,
            loader: {
              status: "failed",
              text: "لا يوجد أي إعلانات عن مقاطع لعرضها",
            },
          });
        } else {
          setslider({
            ...slider,
            data: data.allMedia,
            loader: { status: "done", text: "" },
          });
        }
      })
      .catch((err) => {
        console.log("err....", err);
        setslider({
          ...slider,
          loader: { status: "failed", text: "فشل تحميل إعلانات المقاطع" },
        });
      });
  };
  const getAllVideos = () => {
    if (videosList.loader.status !== "loading") {
      setvideosList({
        ...videosList,
        loader: { status: "loading", text: "جاري تحميل المقاطع" },
      });
    }
    myFeach(`media/type/video?title=${searchKey} &page=${page}&limit=10`, "GET")
      .then(({ data }) => {
        if (data.media.length == 0) {
          setvideosList({
            ...videosList,
            data: [],
            loader: { status: "failed", text: "لا يوجد مقاطع لعرضها" },
          });
        } else {
          setvideosList({
            data: data.media,
            loader: { status: "done", text: "" },
            numberOfPages: data.numberOfPages,
          });
        }
      })
      .catch((err) => {
        console.log("errp...", err);
        setvideosList({
          ...videosList,
          loader: { status: "failed", text: "فشل تحميل المقاطع" },
        });
      });
  };
  // handleD delete book
  const deleteBook = (id) => {
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`media/${id}`, "DELETE")
      .then((result) => {
        setconfirmLoader({ status: "done", text: "" });
        setopenConfirmModal({
          status: "sucsess",
          text: "تم حذف المقطع بنجاح",
        });
        getAllVideos();
      })
      .catch((err) => {
        console.log("err", err);
        // need update
        // console.log("errour",JSON.parse(err.message))
        // let errMsg = JSON.parse(err.message).errors[0].msg;
        setconfirmLoader({ status: "failed", text: "فشل حذف المقطع" });
      });
  };
  // handleD delete slider
  const deleteSlider = (id) => {
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`media/slider/${id}`, "DELETE")
      .then((result) => {
        setconfirmLoader({ status: "done", text: "" });
        setopenConfirmModal({
          status: "sucsess",
          text: "تم مسح إعلان المقطع بنجاح",
        });
        getSliderData();
      })
      .catch((err) => {
        console.log("err", err);
        // need update
        // console.log("errour",JSON.parse(err.message))
        // let errMsg = JSON.parse(err.message).errors[0].msg;
        setconfirmLoader({ status: "failed", text: "فشل حذف إعلان المقطع" });
      });
  };
  useEffect(() => {
    getSliderData();
  }, []);
  useEffect(() => {
    getAllVideos();
  }, [page]);
  return (
    <div className="page_container books_page_container">
      {/* modales */}
      {checkModal("detailes") && (
        <VideoDetailesModal
          openDetailesModal={modal}
          setopenDetailesModal={setmodal}
          headers={detailesHeader}
          id={modal.id}
          vedio_audio="video"
        />
      )}
      {checkModal("cover") && (
        <AddVideoCoverModal
          closeModal={() => setmodal({ ...modal, status: false })}
          coverType="book"
          title="كتاب"
          reloadBooksSlider={getSliderData}
          vedio_audio="video"
        />
      )}
      {checkModal("book") && (
        <AddVideoModal
          closeModal={() => setmodal({ ...modal, status: false })}
          regetVideos={getAllVideos}
          isEdit={modal.edit}
          videoId={activeVideo}
          vedio_audio="video"
        />
      )}
      {openConfirmModal.status === "confirmDelete" && (
        <Confirm
          close={() => {
            setopenConfirmModal({ status: false, text: "" });
            setconfirmLoader({ status: "confirm", text: "" });
          }}
          text={openConfirmModal.text}
          confirmFun={
            openConfirmModal.isCover
              ? () => deleteSlider(activeSlider)
              : () => deleteBook(activeVideo)
          }
          loader={confirmLoader}
        />
      )}
      {openConfirmModal.status === "sucsess" && (
        <SucsessMessageModal
          close={() => setopenConfirmModal({ status: false, text: "" })}
          text={openConfirmModal.text}
        />
      )}
      {/* end madals */}
      {slider.loader.status === "done" ? (
        <VideoSlider
        vedio_audio="video"
        handleDelete={(id) => {
            console.log(id)
            setopenConfirmModal({
              id: id,
              status: "confirmDelete",
              text: "أنت على وشك حذف هذا الإعلان هل أنت متأكد؟",
              isCover:true
            });
            setactiveSlider(id);
          }}
          openAddCoverModal={() =>
            setmodal({ status: true, modalType: "cover" })
          }
          data={slider.data}
          sliderType={"books"}
        />
      ) : (
        <div className="items-center flex flex-col ">
          <PageLoader loader={slider.loader} text={slider.loader.text} />
          {slider.loader.text === "لا يوجد أي إعلانات عن مقاطع لعرضها" && (
            <button
              className="bg_secondary my_rounded px-6 py-2 mt-2"
              onClick={() => setmodal({ status: true, modalType: "cover" })}
            >
              أضافة إعلان +
            </button>
          )}
        </div>
      )}
      <div className="flex justify-between items-center mt-8">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            getAllVideos();
          }}
          className="search flex items-center"
        >
          <input
            onChange={(e) => setsearchKey(e.target.value)}
            className="_my_greay_border px-3 py-1 text-2xl"
            type="text"
          />
          <button className="my_rounded h-10 py-2 px-2  bg_secondary mx-2 ">
            <BsSearch color="white" size={25} />
          </button>
        </form>
        <button
          className="bg_secondary my_rounded px-6 py-2"
          onClick={() =>
            setmodal({ modalType: "book", status: true, edit: false })
          }
        >
          أضافة مقطع +
        </button>
      </div>
      {videosList.loader.status === "done" ? (
        <>
          <CustomTable header={tableHeader}>
            {videosList.data.map((elem, index) => (
              <tr key={index} className="border_buttom_greay">
                <td className="flex items-center w-auto">{elem.title}</td>
                <td className="">
                  <button
                    className="m-1"
                    onClick={() =>
                      setmodal({
                        ...modal,
                        status: true,
                        modalType: "detailes",
                        id: elem.id,
                      })
                    }
                  >
                    <BiShow color="#D59A2C" size={25} />
                  </button>
                  <button
                    className="m-1"
                    onClick={() => {
                      setmodal({ modalType: "book", status: true, edit: true });
                      setactiveVideo(elem.id);
                    }}
                  >
                    <FiEdit2 color="#4ACA5B" size={25} />
                  </button>
                  <button
                    className="m-1"
                    onClick={() => {
                      scrollToTop();
                      setopenConfirmModal({
                        id: elem.id,
                        status: "confirmDelete",
                        text: "أنت على وشك حذف هذا المقطع هل أنت متأكد؟",
                        isCover:false
                      });
                      setactiveVideo(elem.id);
                    }}
                  >
                    <AiFillDelete color="#F61C0D" size={25} />
                  </button>
                </td>
              </tr>
            ))}
          </CustomTable>
          <Pagenation
            setpage={setpage}
            page={page}
            numperOfPages={videosList.numberOfPages}
          />
        </>
      ) : (
        <PageLoader loader={videosList.loader} text={videosList.loader.text} />
      )}
    </div>
  );
}
const tableHeader = [
  { title: "عنوان المقطع", size: "2/3" },
  { title: "", size: "1/3" },
];
const detailesHeader = [
  { title: "عنوان المقطع", key: "title", class: "h-11" },
  { title: "تاريخ النشر", key: "publishedAt", class: "h-11" },
  { title: "رابط المقطع", key: "mediaPath", class: "h-11" },

];








