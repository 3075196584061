import React from "react";

export default function MessageIcon({ pathname }) {
  return (
    <>
      {pathname == "/messages" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 3.75C14 5.96 15.79 7.75 18 7.75C18.73 7.75 19.41 7.54 20 7.19V13.71C20 16.46 17.76 18.69 15 18.69H13.5C13.19 18.69 12.89 18.84 12.7 19.09L11.2 21.08C10.54 21.96 9.46 21.96 8.8 21.08L7.3 19.09C7.13 18.87 6.77 18.69 6.5 18.69H5C2.24 18.69 0 16.46 0 13.71V6.73C0 3.98 2.24 1.75 5 1.75H14.56C14.21 2.34 14 3.02 14 3.75ZM9 10.75C9 11.3 9.44 11.75 10 11.75C10.56 11.75 11 11.3 11 10.75C11 10.2 10.55 9.75 10 9.75C9.45 9.75 9 10.2 9 10.75ZM13 10.75C13 11.3 13.44 11.75 14 11.75C14.56 11.75 15 11.3 15 10.75C15 10.2 14.55 9.75 14 9.75C13.45 9.75 13 10.2 13 10.75ZM5 10.75C5 11.3 5.44 11.75 6 11.75C6.56 11.75 7 11.3 7 10.75C7 10.2 6.55 9.75 6 9.75C5.45 9.75 5 10.2 5 10.75Z"
            fill="#D59A2C"
          />
          <path
            d="M18.5 7C19.4283 7 20.3185 6.63125 20.9749 5.97487C21.6313 5.3185 22 4.42826 22 3.5C22 2.57174 21.6313 1.6815 20.9749 1.02513C20.3185 0.368749 19.4283 0 18.5 0C17.5717 0 16.6815 0.368749 16.0251 1.02513C15.3687 1.6815 15 2.57174 15 3.5C15 4.42826 15.3687 5.3185 16.0251 5.97487C16.6815 6.63125 17.5717 7 18.5 7Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="22"
          height="22"
          viewBox="0 0 22 22"
          fill="none"
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M14 3.75C14 5.96 15.79 7.75 18 7.75C18.73 7.75 19.41 7.54 20 7.19V13.71C20 16.46 17.76 18.69 15 18.69H13.5C13.19 18.69 12.89 18.84 12.7 19.09L11.2 21.08C10.54 21.96 9.46 21.96 8.8 21.08L7.3 19.09C7.13 18.87 6.77 18.69 6.5 18.69H5C2.24 18.69 0 16.46 0 13.71V6.73C0 3.98 2.24 1.75 5 1.75H14.56C14.21 2.34 14 3.02 14 3.75ZM9 10.75C9 11.3 9.44 11.75 10 11.75C10.56 11.75 11 11.3 11 10.75C11 10.2 10.55 9.75 10 9.75C9.45 9.75 9 10.2 9 10.75ZM13 10.75C13 11.3 13.44 11.75 14 11.75C14.56 11.75 15 11.3 15 10.75C15 10.2 14.55 9.75 14 9.75C13.45 9.75 13 10.2 13 10.75ZM5 10.75C5 11.3 5.44 11.75 6 11.75C6.56 11.75 7 11.3 7 10.75C7 10.2 6.55 9.75 6 9.75C5.45 9.75 5 10.2 5 10.75Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M18.5 7C19.4283 7 20.3185 6.63125 20.9749 5.97487C21.6313 5.3185 22 4.42826 22 3.5C22 2.57174 21.6313 1.6815 20.9749 1.02513C20.3185 0.368749 19.4283 0 18.5 0C17.5717 0 16.6815 0.368749 16.0251 1.02513C15.3687 1.6815 15 2.57174 15 3.5C15 4.42826 15.3687 5.3185 16.0251 5.97487C16.6815 6.63125 17.5717 7 18.5 7Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
}
