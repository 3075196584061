import React, { useEffect, useRef, useState } from "react";
// global functions
import { myFeach, postImage, myPutFeach } from "../../api";
// componentes
import AddPersonModall from "./AddPersonModall";
import SelectPersonModall from "./SelectPersonModall";
import PageLoader from "../reuseable/PageLoader";
// icons
import { MdOutlineDone } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import Confirm from "../reuseable/Confirm";
import SucsessMessageModal from "../reuseable/SucsessMessageModal";
export default function EditYearModal({
  closeModal,
  activeYear,
  handleChangeImgeinPage,
}) {
  const [openAddPersonModall, setopenAddPersonModall] = useState({
    status: false,
    formType: "",
    modalType: "select",
  });
  const [yeatDetailes, setyeatDetailes] = useState();
  const [loader, setloader] = useState({
    status: "loading",
    text: "جاري تحميل بيانات السنة",
  });
  const [activePerson, setactivePerson] = useState();
  const [openConfirmModal, setopenConfirmModal] = useState({
    status: false,
    text: "",
    id: "",
  });
  const [confirmLoader, setconfirmLoader] = useState({
    status: "confirm",
    text: "",
  });
  // to uplode imge of year
  const imgeInputRef = useRef(null);
  const [yearImage, setyearImage] = useState("");
  const [imgeLoader, setimgeLoader] = useState({
    uplode: { status: "", text: "", disabled: false },
    suubmit: { status: "", text: "", disabled: true },
  });
  let Imageformdata = new FormData();
  const getYearDetailes = () => {
    setloader({ status: "loading", text: "جاري تحميل بيانات السنة" });
    myFeach(`years/${activeYear.id}`, "GET")
      .then(({ data }) => {
        console.log("year detailes",data)
        setyeatDetailes(data.year);
        setyearImage(data.year.image);
        setloader({ status: "done", text: "" });
      })
      .catch((err) => {
        setloader({ status: "failed", text: "فشل تحميل بيانات السنة" });
      });
  };
  // handle uplode imge
  const handleUplodeImage = async (e) => {
    setimgeLoader({
      ...imgeLoader,
      uplode: { status: "loading", text: "", disabled: true },
    });
    let file = await e.target.files[0];
    Imageformdata.append("image", file);
    postImage("years/image", Imageformdata)
      .then((result) => {
        setyearImage(result.data.image);
        setimgeLoader({
          suubmit: { ...imgeLoader.suubmit, disabled: false },
          uplode: { status: "done", text: "", disabled: false },
        });
      })
      .catch((err) => {
        let errMsg = JSON.parse(err.message).error;
        setimgeLoader({
          ...imgeLoader,
          uplode: { status: "failed", text: errMsg, disabled: false },
        });
      });
  };
  const handleSubmitImge = () => {
    setimgeLoader({
      ...imgeLoader,
      suubmit: { status: "loading", text: "", disabled: true },
    });
    myPutFeach(`years/${activeYear.id}`, { image: yearImage })
      .then((result) => {
        console.log("rsult", result);
        setimgeLoader({
          ...imgeLoader,
          suubmit: { status: "done", text: "", disabled: false },
        });
        handleChangeImgeinPage(activeYear.id, yearImage);
        getYearDetailes();
      })
      .catch((err) => {
        // need update
        // let errMsg = JSON.parse(err.message).errors[0].msg;
        let errMsg=err.message
        console.log(errMsg)
        setimgeLoader({
          ...imgeLoader,
          suubmit: { status: "failed", text: "فشل تغير الصورة", disabled: false },
        });
      });
  };
  // handle delete person from this year
  const deleteFromYear = (personId) => {
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`people/${personId}/${activeYear.id}`, "DELETE")
      .then((result) => {
        setconfirmLoader({ status: "done", text: "" });
        setopenConfirmModal({
          status: "sucsess",
          text: "تم مسح الشخص والكتب والمقالات المتعلقة به بنجاح",
        });
        getYearDetailes();
      })
      .catch((err) => {
        console.log("err", err);
        // need update
        // console.log("errour",JSON.parse(err.message))
        // let errMsg = JSON.parse(err.message).errors[0].msg;
        setconfirmLoader({ status: "failed", text: "فشل حذف الشخص" });
      });
  };
  // get year detailes in start of component
  useEffect(() => {
    getYearDetailes();
  }, []);
  return (
    <>
      <div className="modal_container">
        {/* modalls */}
        {openAddPersonModall.status === true && (
          <>
            {openAddPersonModall.modalType === "select" ? (
              <SelectPersonModall
                reloadYeardetailes={getYearDetailes}
                activeYear={activeYear}
                role={openAddPersonModall?.formType}
                closeModal={() =>
                  setopenAddPersonModall({
                    ...openAddPersonModall,
                    status: false,
                    modalType: "select",
                  })
                }
                addNew={() => {
                  setopenAddPersonModall({
                    ...openAddPersonModall,
                    modalType: "add",
                  });
                }}
              />
            ) : (
              <AddPersonModall
                regeAllPeople={getYearDetailes}
                isAddToYear={activeYear}
                closeModal={() =>
                  setopenAddPersonModall({
                    ...openAddPersonModall,
                    status: false,
                    modalType: "select",
                  })
                }
                formType={openAddPersonModall.formType}
              />
            )}
          </>
        )}
        {openConfirmModal.status === "confirmDelete" && (
          <Confirm
            close={() => {
              setopenConfirmModal({ status: false, text: "" });
              setconfirmLoader({ status: "confirm", text: "" });
            }}
            text={openConfirmModal.text}
            confirmFun={() => deleteFromYear(activePerson)}
            loader={confirmLoader}
          />
        )}
        {openConfirmModal.status === "sucsess" && (
          <SucsessMessageModal
            close={() => setopenConfirmModal({ status: false, text: "" })}
            text={openConfirmModal.text}
          />
        )}
        {/* end modals */}
        <button onClick={closeModal} className="modal_bg"></button>
        <div className="esc_modal_year_detailes">
          <button onClick={closeModal}>x</button>
        </div>
        <div className="modal_content">
          {loader.status === "done" ? (
            <>
              <div className="flex items-center justify-between">
                <div className="flex flex-col w-60">
                  <div className="my_border p-2 w-4/5">{activeYear.year}</div>
                </div>
                <div>
                  <div
                    onClick={() => imgeInputRef.current.click()}
                    className="dashed_border flex items-center py-3 px-8 my_rounded cursor-pointer"
                  >
                    <input
                      onChange={handleUplodeImage}
                      type="file"
                      ref={imgeInputRef}
                      className="input_file"
                    />
                    <figure
                      className="w-20 h-20 imge_bg my_rounded flex items-center justify-center"
                      style={{
                        backgroundImage: `url(${yearImage})`,
                      }}
                    >
                      {imgeLoader.uplode.status === "loading" && (
                        <div className="loader"></div>
                      )}
                    </figure>
                    <h4 className="mr-2">
                      {yeatDetailes?.image.substring(
                        yeatDetailes.image.length - 15
                      )}
                    </h4>
                    <div className="bg_success w-10 h-10 circle_rounded flex_center mr-40">
                      <MdOutlineDone size={20} />
                    </div>
                  </div>
                  {imgeLoader.uplode.status === "failed" && (
                    <h4 className="w-full text-center text-red-500 text-xs mt-2">
                      {imgeLoader.uplode.text}
                    </h4>
                  )}
                </div>
              </div>
              <button
                onClick={handleSubmitImge}
                className="mt-4 w-full h-12 bg_secondary my_rounded"
                disabled={imgeLoader.suubmit.disabled}
              >
                {imgeLoader.suubmit.status === "loading" ? (
                  <div className="loader"></div>
                ) : (
                  "حفظ الصورة"
                )}
              </button>
              {imgeLoader.suubmit.status == "failed" && (
                <h4 className="w-full text-center text-red-500 text-xs mt-2">
                  {imgeLoader.suubmit.text}
                </h4>
              )}
              <div className="flex justify-between mt-10">
                <div className="w-1/4">
                  <div className="w-full flex justify-between border_buttom">
                    <h3>الهيئة الإدارية</h3>
                    <button
                      className="bg_secondary my_rounded px-4 py-1  h3"
                      onClick={() =>
                        setopenAddPersonModall({
                          ...openAddPersonModall,
                          status: true,
                          formType: "الهيئة الادارية",
                        })
                      }
                    >
                      +
                    </button>
                  </div>
                  <ul>
                    {yeatDetailes?.people.administrativeBoard.map(
                      (elem, index) => (
                        <li
                          key={index}
                          className={`${
                            elem.isDeleted
                              ? "bg-red-100 text-zinc-700"
                              : "bg-gray-300"
                          } flex justify-between items-center my_rounded  p-3 my-2`}
                        >
                          <h4>
                            {" "}
                            {elem.firstName} {elem.lastName}
                          </h4>
                          <button
                            onClick={() => {
                              setopenConfirmModal({
                                id: elem.id,
                                status: "confirmDelete",
                                text: "أنت على وشك حذف هذا الشخص من هذه السنة، هل أنت متأكد؟",
                              });
                              setactivePerson(elem.id);
                            }}
                          >
                            <RiDeleteBinLine color="#F61C0D" />
                          </button>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <div className="w-1/4 ">
                  <div className="w-full flex justify-between border_buttom">
                    <h3>هيئة التدريس</h3>
                    <button
                      className="bg_secondary my_rounded px-4 py-1  h3"
                      onClick={() =>
                        setopenAddPersonModall({
                          ...openAddPersonModall,
                          status: true,
                          formType: "أعضاء هيئة التدريس",
                        })
                      }
                    >
                      +
                    </button>
                  </div>
                  <ul>
                    {yeatDetailes?.people.teachingStaff.map((elem, index) => (
                      <li
                        key={index}
                        className={`${
                          elem.isDeleted
                            ? "bg-red-100 text-zinc-700"
                            : "bg-gray-300"
                        } flex justify-between items-center my_rounded  p-3 my-2`}
                      >
                        <h4>
                          {" "}
                          {elem.firstName} {elem.lastName}
                        </h4>
                        <button
                          onClick={() => {
                            setopenConfirmModal({
                              id: elem.id,
                              status: "confirmDelete",
                              text: "أنت على وشك حذف هذا الشخص من هذه السنة، هل أنت متأكد؟",
                            });
                            setactivePerson(elem.id);
                          }}
                        >
                          <RiDeleteBinLine color="#F61C0D" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="w-1/4">
                  <div className="w-full flex justify-between border_buttom">
                    <h3>الخريجون</h3>
                    <button
                      className="bg_secondary my_rounded  px-4 py-1  h3"
                      onClick={() =>
                        setopenAddPersonModall({
                          ...openAddPersonModall,
                          status: true,
                          formType: "الخريجون",
                        })
                      }
                    >
                      +
                    </button>
                  </div>
                  <ul>
                    {yeatDetailes?.people.graduates.map((elem, index) => (
                      <li
                        key={index}
                        className={`${
                          elem.isDeleted
                            ? "bg-red-100 text-zinc-700"
                            : "bg-gray-300"
                        } flex justify-between items-center my_rounded  p-3 my-2`}
                      >
                        <h4>
                          {" "}
                          {elem.firstName} {elem.lastName}
                        </h4>
                        <button
                          onClick={() => {
                            setopenConfirmModal({
                              id: elem.id,
                              status: "confirmDelete",
                              text: "أنت على وشك حذف هذا الشخص من هذه السنة، هل أنت متأكد؟",
                            });
                            setactivePerson(elem.id);
                          }}
                        >
                          <RiDeleteBinLine color="#F61C0D" />
                        </button>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </>
          ) : (
            <PageLoader loader={loader} />
          )}
        </div>
      </div>
    </>
  );
}
