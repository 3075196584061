import React, { useRef, useState } from "react";
import { RiAttachment2 } from "react-icons/ri";
import { postImage } from "../../api";
import { useEffect } from "react";

export default function CustomFileInput({
  title,
  img,
  addedImage,
  onchangeFun,
  name = "",
  errours = {},
  uplodePath = "",
  isFile,
  isEdit,
  addedFile,
}) {
  const fileInputRef = useRef(null);
  const [loader, setloader] = useState({ status: "", text: "" });
  const [uplodedImagePath, setuplodedImagePath] = useState(
    isEdit?.status ? isEdit.path : ""
  );
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  // handle uplode book and image
  const handleUplodeImage = async (e) => {
    setloader({ status: "loading", text: "جاري الرفع " });
    let file = await e.target.files[0];
    let Imageformdata = new FormData();
    let fileType = isFile === true ? "pdf" : "image";
    Imageformdata.append(fileType, file);
    postImage(uplodePath, Imageformdata)
      .then(({ data }) => {
        fileInputRef.current.value = "";
        let uplodedFilePath;
        if (data.imagePath) {
          uplodedFilePath = data.imagePath;
        } else {
          uplodedFilePath = data.imageUrl ? data.imageUrl : data.pdfUrl;
        }
        onchangeFun(uplodedFilePath);
        setloader({ status: "done", text: "" });
        if (isFile) {
          setuplodedImagePath("https://i.postimg.cc/T2ztjz2w/done.png");
        }
      })
      .catch((err) => {
        console.log(err);
        setloader({
          status: "failed",
          text: JSON.parse(err.message)?.error,
        });
      });
  };
  useEffect(() => {
    if (addedImage) {
      setuplodedImagePath(
        isFile ? "https://i.postimg.cc/T2ztjz2w/done.png" : addedImage
      );
    } else {
      setuplodedImagePath(img);
    }
  }, [addedImage]);
  return (
    <div>
      <div className="my_rounded dashed_border flex items-center p-5 ">
        <figure className="mx-4 w-20 h-20">
          <div
            className="w-20 h-20 flex items-center justify-center"
            style={{
              backgroundImage: `url(${
                uplodedImagePath ? uplodedImagePath : img
              })`,
            }}
          >
            {loader.status === "loading" && <div className="loader"></div>}
          </div>
        </figure>
        <h4 className="mx-4">{title}</h4>
        <button
          type="button"
          onClick={handleButtonClick}
          className="bg_secondary my_rounded px-10 py-2 h4 h-11 flex items-center mx-4"
        >
          <span>إرفاق</span>
          <RiAttachment2 color="white" />
        </button>
      </div>
      <input
        id="myFileInput"
        onChange={handleUplodeImage}
        ref={fileInputRef}
        type="file"
        className="input_file"
        name={name}
      />
      {loader?.status === "failed" && (
        <h4 className="text-xs text-red-400 w-full mt-1">{loader?.text}</h4>
      )}
      {errours[name] && (
        <h4 className="text-xs text-red-400 w-full mt-1">{errours[name]}</h4>
      )}
    </div>
  );
}
