import React, { useEffect, useState } from "react";
// data
import { requests } from "../data/data";
// components
import CustomTable from "../components/reuseable/CustomTable";
// icons
import { BiShow } from "react-icons/bi";
import RequestDetailesModal from "../components/requests/RequestDetailesModal";
import { myFeach } from "../api";
import PageLoader from "../components/reuseable/PageLoader";
import Pagenation from "../components/reuseable/Pagination";
export default function Requests({ regetSidBarCounter }) {
  const [newContentCounter, setnewContentCounter] = useState({});
  const [activeFilter, setactiveFilter] = useState(filters[0]);
  const [activeReqId, setactiveReqId] = useState("");
  const [requestsList, setrequestsList] = useState({
    data: [],
    loader: { status: "loading", text: "جاري تحميل طلبات إضافة المحتوى" },
  });
  const [openDetailesModal, setopenDetailesModal] = useState({
    status: false,
    requestType: "",
  });
  const [page, setpage] = useState(1);
  const handleGetNewContentCount = () => {
    myFeach("dashboard/new-content", "GET")
      .then(({ data }) => {
        setnewContentCounter(data.details);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const getAllReqOfActiveFilter = () => {
    handleGetNewContentCount();
    if (requestsList.loader.status !== "loading") {
      setrequestsList({
        ...requestsList,
        loader: { status: "loading", text: "جاري تحميل الطلبات" },
      });
    }
    myFeach(
      `${activeFilter.apiUrl}${
        activeFilter.key === "docs" ? "&" : "?"
      }limit=10&page=${page}`,
      "GET"
    )
      .then(({ data }) => {
        if (data[activeFilter.apiResponceKey].length == 0) {
          setrequestsList({
            ...requestsList,
            data: [],
            loader: { status: "failed", text: "لا يوجد طلبات لعرضها" },
          });
        } else {
          setrequestsList({
            data: data[activeFilter.apiResponceKey],
            loader: { status: "done", text: "" },
            numberOfPages: data.numberOfPages,
          });
        }
      })
      .catch((err) => {
        console.log("errp...", err);
        setrequestsList({
          ...requestsList,
          loader: { status: "failed", text: "فشل تحميل طلبات إضافة المحتوى" },
        });
      });
  };
  useEffect(() => {
    getAllReqOfActiveFilter();
  }, [activeFilter, page]);
  return (
    <div className="page_container requests_page">
      {openDetailesModal.status && (
        <RequestDetailesModal
          regetReqestList={getAllReqOfActiveFilter}
          regetSidBarCounter={regetSidBarCounter}
          reqestType={activeFilter}
          closeModal={() => setopenDetailesModal({ status: false })}
          reqId={activeReqId}
        />
      )}
      <ul className="filters mt-10 ">
        {filters.map((elem, index) => (
          <li
            onClick={() => {
              setactiveFilter(elem);
              setpage(1);
            }}
            className={` flex items-center ${
              activeFilter.key === elem.key ? " active_filter" : "hiii"
            }`}
            key={index}
          >
            {elem.text}
            {newContentCounter[elem.newContentCounterKey] && (
              <span className="mx-2 circle_rounded w-2 h-2 block bg-green-400"></span>
            )}
          </li>
        ))}
      </ul>
      <div>
        {requestsList.loader.status === "done" ? (
          <>
            <CustomTable header={tableHeader}>
              {requestsList.data.map((elem, index) => (
                <tr key={index} className="border_buttom_greay">
                  <td className="">{elem?.visitor}</td>
                  <td>{elem?.phone}</td>
                  <td>{formatTime(elem.timeLine[0]?.createdAt)}</td>
                  <td
                    className={`${
                      elem.status === "تم الرفض" ? "text-red-400" : ""
                    } ${elem.status === "تم القبول" ? "text-green-400" : ""} 
                    ${elem.status === "تمت المراجعه" ? "text-yellow-500" : ""} 
                    `}
                  >
                    {elem.status}
                  </td>
                  <td>
                    <button
                      className="m-1"
                      onClick={() => {
                        setactiveReqId(elem.id ? elem.id : elem._id);
                        setopenDetailesModal({
                          status: true,
                          requestType: activeFilter,
                        });
                      }}
                    >
                      <BiShow color="#D59A2C" size={25} />
                    </button>
                  </td>
                </tr>
              ))}
            </CustomTable>
            <Pagenation
              setpage={setpage}
              page={page}
              numperOfPages={requestsList?.numberOfPages}
            />
          </>
        ) : (
          <PageLoader
            loader={requestsList.loader}
            text={requestsList.loader.text}
          />
        )}
      </div>
    </div>
  );
}
const filters = [
  {
    text: "صور",
    key: "photo",
    apiUrl: "images/new-content",
    apiResponceKey: "images",
    getDetailesApiReq: "images",
    addedContentKeys: [
      { title: "الوصف", key: "description" },
      { title: "الصورة", key: "imagePath" },
      { key: "albums", title: "الألبوم", getListApiPath: "albums" },
    ],
    aproveApiPath: "images/new-content",
    rejectApiPath: "images/new-content/requests",
    submitChangesApiPath: "images",
    uploudeImagePath: "images/upload",
    newContentCounterKey: "newImages",
  },
  {
    text: "قواعد بيانات",
    key: "database",
    apiUrl: "people/new-content",
    apiResponceKey: "people",
    getDetailesApiReq: "people",
    addedContentKeys: [
      { title: "الصورة", key: "profileImage" },
      { title: "الاسم الأول", key: "firstName" },
      { title: "الاسم الأخير", key: "lastName" },
      { title: "السيرة الذاتية", key: "cv" },
      { title: "سنة الميلاد", key: "yearOfBirth" },
      { title: "سنة الوفاة", key: "yearOfDeath" },
      {
        title: "السنين",
        key: "years",
        getListApiPath: "years/?isDeleted=false",
      },
      { title: "نبذة", key: "brief" },
    ],
    aproveApiPath: "people/new-content",
    rejectApiPath: "people/new-content/requests",
    submitChangesApiPath: "people",
    uploudeImagePath: "people/profile-image",
    newContentCounterKey: "newPeople",
  },
  {
    text: "كتب",
    key: "books",
    apiUrl: "books/new-content",
    apiResponceKey: "books",
    getDetailesApiReq: "books",
    addedContentKeys: [
      { key: "title", title: "عنوان الكتاب" },
      { key: "categories", title: "التصنيف", getListApiPath: "" },
      {key:"bookPath",title:"رابط الكتاب"},
      { key: "numberOfParts", title: "عدد الأجزاء" },
      { key: "publishedAt", title: "سنة النشر" },
      { key: "imagePath", title: "صورة" },
      { key: "categories", title: "التصنيف", getListApiPath: "categories" },
      { key: "brief", title: "نبذة" },
    ],
    aproveApiPath: "books/new-content",
    rejectApiPath: "books/new-content/requests",
    submitChangesApiPath: "books",
    uploudeImagePath: "books/image",
    newContentCounterKey: "newBooks",
  },
  {
    text: "مقاطع مرئية",
    key: "videos",
    apiUrl: "media/new-content",
    apiResponceKey: "media",
    getDetailesApiReq: "media",
    addedContentKeys: [
      { key: "title", title: "عنوان المقطع" },
      { key: "imagePath", title: "صورة المقطع" },
      { key: "mediaPath", title: "رابط المقطع" },
      { key: "publishedAt", title: "تاريخ النشر" },
      { key: "categories", title: "التصنيف", getListApiPath: "categories" },
    ],
    aproveApiPath: "media/new-content",
    rejectApiPath: "media/new-content/requests",
    submitChangesApiPath: "media/video",
    uploudeImagePath: "media/video/image",
    newContentCounterKey: "newVideos",
  },
  {
    text: "وثائق",
    key: "docs",
    apiUrl: "images/new-content?type=%D9%88%D8%AB%D9%8A%D9%82%D9%87",
    apiResponceKey: "images",
    getDetailesApiReq: "images",
    addedContentKeys: [
      { title: "الوصف", key: "description" },
      { title: "الصورة", key: "imagePath" },
    ],
    aproveApiPath: "images/new-content",
    rejectApiPath: "images/new-content/requests",
    submitChangesApiPath: "images",
    uploudeImagePath: "images/upload",
    newContentCounterKey: "newVDocs",
  },
];
const tableHeader = [
  { title: "اسم الزائر", key: "name", size: "sm" },
  { title: "رقم الهاتف", key: "position", size: "sm" },
  { title: "التاريخ", key: "year", size: "sm" },
  { title: "الحالة", key: "detailes", size: "sm" },
  { title: "", key: "controls", size: "sm" },
];
const formatTime = (timestamp) => {
  const date = new Date(timestamp);
  const options = {
    year: "numeric",
    month: "long",
    day: "numeric",
    hour: "numeric",
    minute: "numeric",
    second: "numeric",
    timeZone: "UTC",
    locale: "ar",
  };
  const formattedDate = date.toLocaleString("ar", options);
  return formattedDate;
};
