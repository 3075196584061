import React, { useState } from "react";
// data
import { albumDetailes } from "../data/data";
// components
import HeartIcon from "../components/reuseable/svg-icons/HeartIcon";
import EditIcon from "../components/reuseable/svg-icons/EditIcon";
import DeleteIcon from "../components/reuseable/svg-icons/DeleteIcon";
import AddAlbumModal from "../components/albums/AddAlbumModal";
import AddPhotoModal from "../components/albums/AddPhotoModal";
import { useEffect } from "react";
import PageLoader from "../components/reuseable/PageLoader";
import { myFeach } from "../api";
// icons
import logo from "../assets/logo.svg";
import Pagenation from "../components/reuseable/Pagination";
import Confirm from "../components/reuseable/Confirm";
import SucsessMessageModal from "../components/reuseable/SucsessMessageModal";
export default function Albums() {
  const [albums, setalbums] = useState({
    loader: { status: "loading", text: "جاري تحميل الألبومات" },
    data: [],
  });
  const [albumImages, setalbumImages] = useState({
    loader: { status: "loading", text: "جاري تحميل الصور" },
    data: [],
    numberOfPages: "",
  });
  const [page, setpage] = useState(1);
  const [activeAlbum, setactiveAlbum] = useState("");
  const [openAddAlbumModal, setopenAddAlbumModal] = useState(false);
  const [openAddPhotoModal, setopenAddPhotoModal] = useState({
    status: false,
    isEdit: false,
  });
  const [activePhoto, setactivePhoto] = useState("");
  const [openConfirmModal, setopenConfirmModal] = useState({
    status: false,
    text: "",
    id: "",
    isCover: "",
  });
  const [confirmLoader, setconfirmLoader] = useState({
    status: "confirm",
    text: "",
  });
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // handle get all albums
  const getAllAlbums = () => {
    if (albums.loader.status !== "loading") {
      setalbums({
        ...albums,
        loader: { status: "loading", text: "جاري تحميل الألبومات" },
      });
    }
    myFeach(`albums`, "GET")
      .then(({ data }) => {
        if (data.albums.length == 0) {
          setalbums({
            ...albums,
            data: [],
            loader: { status: "failed", text: "لا يوجد ألبومات لعرضها" },
          });
          setalbumImages({
            ...albumImages,
            loader: { status: "failed", text: "فشل تحميل الصور" },
          });
        } else {
          setalbums({
            data: data.albums,
            loader: { status: "done", text: "" },
          });
          setactiveAlbum(data.albums[0]._id);
        }
      })
      .catch((err) => {
        console.log("errp...", err);
        setalbums({
          ...albums,
          loader: { status: "failed", text: "فشل تحميل الألبومات" },
        });
        setalbumImages({
          ...albumImages,
          loader: { status: "failed", text: "فشل تحميل الصور" },
        });
      });
  };
  // handle gat all images
  const GetAlbumImages = () => {
    if (albumImages.loader.status !== "loading") {
      setalbumImages({
        ...albumImages,
        loader: { status: "loading", text: "جاري تحميل صور الألبوم" },
      });
    }
    myFeach(
      `images?limit=10&page=${page}${
        activeAlbum ? `&album=${activeAlbum}` : ""
      } `,
      "GET"
    )
      .then(({ data }) => {
        if (data?.images?.length == 0) {
          setalbumImages({
            ...albumImages,
            data: [],
            loader: { status: "failed", text: "لا يوجد صور في الألبوم لعرضها" },
            numberOfPages: data.numberOfPages,
          });
        } else {
          setalbumImages({
            data: data.images,
            loader: { status: "done", text: "" },
            numberOfPages: data.numberOfPages,
          });
        }
      })
      .catch((err) => {
        console.log("errp...", err);
        setalbumImages({
          ...albumImages,
          loader: { status: "failed", text: "فشل تحميل الصور" },
        });
      });
  };
  // handle delete image
  const deleteImage = (id) => {
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`images/${id}`, "DELETE")
      .then((result) => {
        setconfirmLoader({ status: "done", text: "" });
        setopenConfirmModal({
          status: "sucsess",
          text: "تم حذف الصورة بنجاح",
        });
        GetAlbumImages();
      })
      .catch((err) => {
        console.log("err", err);
        // need update
        // console.log("errour",JSON.parse(err.message))
        // let errMsg = JSON.parse(err.message).errors[0].msg;
        setconfirmLoader({ status: "failed", text: "فشل حذف الصورة" });
      });
  };
  const handleScrollToTop = () => {
    window.scrollTo(0, 0);
  };
  useEffect(() => {
    getAllAlbums();
  }, []);
  useEffect(() => {
    if (albums.loader.status === "done") {
      GetAlbumImages();
    }
  }, [activeAlbum, page, albums]);
  useEffect(() => {
    handleScrollToTop();
  }, [page, activeAlbum]);
  return (
    <div className="page_container flex px-28 py-20">
      {/* modalls */}
      {openAddAlbumModal.status && (
        <AddAlbumModal
          closeModal={() => setopenAddAlbumModal(false)}
          width="40%"
          regetAlbums={getAllAlbums}
        />
      )}
      {openAddPhotoModal.status && (
        <AddPhotoModal
          oldPhoToData={activePhoto}
          isEdit={openAddPhotoModal.isEdit}
          regetAlbums={getAllAlbums}
          regetPhotos={GetAlbumImages}
          closeModal={() =>
            setopenAddPhotoModal({ status: false, isEdit: false })
          }
        />
      )}
      {openConfirmModal.status === "confirmDelete" && (
        <Confirm
          close={() => {
            setopenConfirmModal({ status: false, text: "" });
            setconfirmLoader({ status: "confirm", text: "" });
          }}
          text={openConfirmModal.text}
          confirmFun={() => deleteImage(activePhoto.id)}
          loader={confirmLoader}
        />
      )}
      {openConfirmModal.status === "sucsess" && (
        <SucsessMessageModal
          close={() => setopenConfirmModal({ status: false, text: "" })}
          text={openConfirmModal.text}
        />
      )}
      {/* modal */}
      <div className="photos w-8/12">
        {albumImages.loader.status === "done" ? (
          <>
            {albumImages.data.map((elem, index) => (
              <div key={index} className="w-full mb-12">
                <div
                  className="album_imge my_rounded imge_bg"
                  style={{ backgroundImage: `url(${elem.imagePath})` }}
                ></div>
                <div className="flex justify-between width_500">
                  <div className="flex items-center">
                    <span>
                      <HeartIcon />{" "}
                    </span>
                    <span>{elem.likes}</span>
                  </div>
                  <div className="flex">
                    <button
                      onClick={() => {
                        setactivePhoto(elem);
                        setopenAddPhotoModal({ status: true, isEdit: true });
                      }}
                    >
                      <EditIcon />
                    </button>
                    <button
                      onClick={() => {
                        scrollToTop();
                        setopenConfirmModal({
                          id: elem.id,
                          status: "confirmDelete",
                          text: "أنت على وشك حذف هذه الصورة هل أنت متأكد؟",
                          isCover: false,
                        });
                        setactivePhoto(elem);
                      }}
                    >
                      <DeleteIcon />
                    </button>
                  </div>
                </div>
                <h4>{elem.description}</h4>
              </div>
            ))}
            <div className="w-4/5">
              <Pagenation
                setpage={setpage}
                page={page}
                numperOfPages={albumImages.numberOfPages}
              />
            </div>
          </>
        ) : (
          <PageLoader
            loader={albumImages.loader}
            text={albumImages.loader.text}
          />
        )}
      </div>
      <div className="albums w-4/12">
        {albums.loader.status === "done" ? (
          <>
            {albums.data.map((elem, index) => (
              <button
                className="w-1/2 p-1 inline-block "
                key={index}
                onClick={() => {
                  setpage(1);
                  setactiveAlbum(elem._id);
                }}
              >
                <div
                  className="w-40 h-40 my_rounded m-auto imge_bg "
                  style={{
                    backgroundImage: `url(${elem.cover ? elem.cover : logo})`,
                  }}
                ></div>
                <h6
                  className={`${elem._id === activeAlbum ? "text_active" : ""}`}
                >
                  {elem.name}
                </h6>
              </button>
            ))}
          </>
        ) : (
          <PageLoader loader={albums.loader} text={albums.loader.text} />
        )}
        <button
          className="w-full h4 text_white bg_secondary h-12 my_rounded mt-2"
          onClick={() => {
            setopenAddAlbumModal({ status: true });
          }}
        >
          + إضافة ألبوم
        </button>
        <button
          className="w-full h4 text_white bg_secondary h-12 my_rounded mt-2"
          onClick={() => setopenAddPhotoModal({ status: true, isEdit: false })}
        >
          + إضافة صورة
        </button>
      </div>
    </div>
  );
}
