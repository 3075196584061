import React from "react";

export default function BookIcon({ pathname }) {
  return (
    <>
      {pathname == "/books" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M12 5.30202V21.332C11.83 21.332 11.65 21.302 11.51 21.222L11.47 21.202C9.55 20.152 6.2 19.052 4.03 18.762L3.74 18.722C2.78 18.602 2 17.702 2 16.742V4.66202C2 3.47202 2.97 2.57202 4.16 2.67202C6.26 2.84202 9.44 3.90202 11.22 5.01202L11.47 5.16202C11.62 5.25202 11.81 5.30202 12 5.30202Z"
            fill="white"
          />
          <path
            d="M22 4.67003V16.74C22 17.7 21.22 18.6 20.26 18.72L19.93 18.76C17.75 19.05 14.39 20.16 12.47 21.22C12.34 21.3 12.18 21.33 12 21.33V5.30003C12.19 5.30003 12.38 5.25003 12.53 5.16003L12.7 5.05003C14.48 3.93003 17.67 2.86003 19.77 2.68003H19.83C21.02 2.58003 22 3.47003 22 4.67003ZM7.75 9.23803H5.5C5.09 9.23803 4.75 8.89803 4.75 8.48803C4.75 8.07803 5.09 7.73803 5.5 7.73803H7.75C8.16 7.73803 8.5 8.07803 8.5 8.48803C8.5 8.89803 8.16 9.23803 7.75 9.23803ZM8.5 12.238H5.5C5.09 12.238 4.75 11.898 4.75 11.488C4.75 11.078 5.09 10.738 5.5 10.738H8.5C8.91 10.738 9.25 11.078 9.25 11.488C9.25 11.898 8.91 12.238 8.5 12.238Z"
            fill="#D59A2C"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            opacity="0.4"
            d="M12 5.30202V21.332C11.83 21.332 11.65 21.302 11.51 21.222L11.47 21.202C9.55 20.152 6.2 19.052 4.03 18.762L3.74 18.722C2.78 18.602 2 17.702 2 16.742V4.66202C2 3.47202 2.97 2.57202 4.16 2.67202C6.26 2.84202 9.44 3.90202 11.22 5.01202L11.47 5.16202C11.62 5.25202 11.81 5.30202 12 5.30202Z"
            fill="white"
          />
          <path
            d="M22 4.67003V16.74C22 17.7 21.22 18.6 20.26 18.72L19.93 18.76C17.75 19.05 14.39 20.16 12.47 21.22C12.34 21.3 12.18 21.33 12 21.33V5.30003C12.19 5.30003 12.38 5.25003 12.53 5.16003L12.7 5.05003C14.48 3.93003 17.67 2.86003 19.77 2.68003H19.83C21.02 2.58003 22 3.47003 22 4.67003ZM7.75 9.23803H5.5C5.09 9.23803 4.75 8.89803 4.75 8.48803C4.75 8.07803 5.09 7.73803 5.5 7.73803H7.75C8.16 7.73803 8.5 8.07803 8.5 8.48803C8.5 8.89803 8.16 9.23803 7.75 9.23803ZM8.5 12.238H5.5C5.09 12.238 4.75 11.898 4.75 11.488C4.75 11.078 5.09 10.738 5.5 10.738H8.5C8.91 10.738 9.25 11.078 9.25 11.488C9.25 11.898 8.91 12.238 8.5 12.238Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
}
