import React, { useRef, useState, useEffect } from "react";
import CustomFileInput from "../reuseable/CustomFileInput";
import { RiAttachment2 } from "react-icons/ri";
import { MdOutlineDone } from "react-icons/md";
// glopal functions
import { myFeach, myPutFeach, postImage } from "../../api";
import SucsessMessageModal from "../reuseable/SucsessMessageModal";
import HtmlEditor from "../reuseable/HtmlEditor";
import PageLoader from "../reuseable/PageLoader";
export default function AddPersonModall({
  closeModal,
  formType,
  regeAllPeople,
  EditPersonId,
  isAddToYear,
}) {
  const imgeInputRef = useRef(null);
  const [personData, setpersonData] = useState({
    years: isAddToYear ? [isAddToYear.id] : [],
  });
  const [submitLoader, setsubmitLoader] = useState({
    uplode: { status: "", text: "", disabled: false },
    suubmit: { status: "", text: "", disabled: false },
  });
  const [yearsLoader, setyearsLoader] = useState({
    status: "loading",
    text: "يتم تحميل السنوات",
  });
  const [degreesLoader, setdegreesLoader] = useState({
    status: "loading",
    text: "يتم تحميل السنوات",
  });
  const [years, setyears] = useState([]);
  const [degrees, setdegrees] = useState([]);
  const [subjects, setsubjects] = useState(["", "", "", ""]);
  const [sucsessModal, setsucsessModal] = useState({ status: false, text: "" });
  const [loader, setloader] = useState({
    status: EditPersonId ? "loading" : "",
    text: EditPersonId ? " يتم تحميل بيانات الشخص الحالية" : "",
  });
  const [errors, seterrors] = useState({});
  const cvRef = useRef(null);
  // handle get all available years to show in dropDowen
  const getAllYears = () => {
    myFeach("years/?isDeleted=false", "GET")
      .then(({ data }) => {
        setyears(data.years);
        setyearsLoader({ text: "", status: "done" });
      })
      .catch((err) => {
        setyearsLoader({ text: "فشل تحميل السنوات", status: "failed" });
      });
  };
  // handle get all available years to show in dropDowen
  const getAllDegrees = () => {
    myFeach("people/administrativeBoard/degree", "GET")
      .then(({ data }) => {
        if (data.degrees.length) {
          setdegrees(data.degrees);
          setdegreesLoader({ text: "", status: "done" });
          if (!EditPersonId) {
            // setpersonData({ ...personData, degree: data.degrees[0] });
          }
        } else {
          setdegreesLoader({ text: "قم بإضافة رتب أولاً", status: "failed" });
        }
      })
      .catch((err) => {
        setdegreesLoader({ text: "فشل تحميل الرتب", status: "failed" });
      });
  };
  // handle get person detailes if its edit page
  const getPerson = () => {
    // setloader({ status: "loading", text: "يتم تحميل بيانات الأشخاص" });
    myFeach(`people/${EditPersonId}`, "GET")
      .then(({ data }) => {
        setloader({ status: "done", text: "" });
        let oldPerson = {
          ...data,
          years: data?.years.map((yearObj) => yearObj._id),
        };
        setpersonData(oldPerson);
      })
      .catch((err) => {
        console.log("error", err);
        setloader({ status: "failed", text: "فشل تحميل بيانات الأشخاص" });
      });
  };
  // handle on change inputs of form
  const handleOnchange = (e) => {
    setpersonData({ ...personData, [e.target.name]: e.target.value });
  };
  const onChangeSubjects = (e, index) => {
    let mySubjects = personData.subjects ? [...personData.subjects] : [];
    mySubjects[index] = e.target.value;
    setpersonData({ ...personData, subjects: mySubjects });
  };
  // handle uplode imge
  const handleUplodeImage = async (e) => {
    setsubmitLoader({
      ...submitLoader,
      uplode: { status: "loading", text: "", disabled: true },
    });
    let file = await e.target.files[0];
    let Imageformdata = new FormData();
    Imageformdata.append("image", file);
    postImage("people/profile-image", Imageformdata)
      .then((result) => {
        if (imgeInputRef.current) {
          imgeInputRef.current.value = "";
        }
        setpersonData({
          ...personData,
          profileImage: result.data.ProfileImage,
        });
        setsubmitLoader({
          suubmit: { ...submitLoader.suubmit, disabled: false },
          uplode: { status: "done", text: "", disabled: false },
        });
      })
      .catch((err) => {
        console.log(err);
        if (imgeInputRef.current) {
          imgeInputRef.current.value = "";
        }
        // need update
        setsubmitLoader({
          ...submitLoader,
          uplode: {
            status: "failed",
            text: "فشل رفع الصورة حاول مرة أخرى",
            disabled: false,
          },
        });
      });
  };
  // handle submit person data
  const checkIfYearEmpty = () => {
    if (personData.years.length === 0) {
      return false;
    } else {
      return true;
    }
  };
  const handleAdd = (e) => {
    personData.cv = cvRef?.current?.getContent();
    if (checkIfYearEmpty()) {
      setsubmitLoader({
        ...submitLoader,
        suubmit: { status: "loading", text: "", disabled: true },
      });
      myFeach(`people/role/${formType}`, "POST", personData, "authrized")
        .then((result) => {
          setsubmitLoader({
            ...submitLoader,
            suubmit: { status: "done", text: "", disabled: false },
          });
          regeAllPeople();
          setsucsessModal({ status: true, text: "تم إضافة الشخص بنجاح" });
          seterrors({});
          if (formType === "أعضاء هيئة التدريس") {
            setpersonData({
              years: [],
              lastName: "",
              firstName: "",
              brief: "",
              cv: "",
              yearOfBirth: "",
              yearOfDeath: "",
              subjects: ["", "", "", ""],
            });
          } else if (formType === "الهيئة الادارية") {
            setpersonData({
              years: [],
              lastName: "",
              firstName: "",
              brief: "",
              cv: "",
              // profileImage: "",
              yearOfBirth: "",
              yearOfDeath: "",
              degree: "",
            });
          } else {
            setpersonData({
              years: [],
              lastName: "",
              firstName: "",
              brief: "",
              cv: "",
              // profileImage: "",
              yearOfBirth: "",
              yearOfDeath: "",
            });
          }
        })
        .catch((err) => {
          let errors = JSON.parse(err.message).errors;
          console.log("0000000", errors);
          setsubmitLoader({
            ...submitLoader,
            suubmit: {
              status: "failed",
              text: "فشل إضافة الشخص حاول مرة أخرى",
              disabled: false,
            },
          });
          console.log(err);
          if (errors) {
            seterrors(errors);
          }
        });
    } else {
      seterrors({ years: "السنة لا يمكن أن تكون فارغة" });
    }
  };
  function removeEmptyStrings(array) {
    return array.filter(function (element) {
      return element !== "";
    });
  }
  const handleEdit = (e) => {
    personData.cv = cvRef?.current?.getContent();
    if (checkIfYearEmpty()) {
      setsubmitLoader({
        ...submitLoader,
        suubmit: { status: "loading", text: "", disabled: true },
      });
      myPutFeach(`people/${EditPersonId}`, {
        ...personData,
        subjects: removeEmptyStrings(personData.subjects),
      })
        .then((result) => {
          setsubmitLoader({
            ...submitLoader,
            suubmit: { status: "done", text: "", disabled: false },
          });
          regeAllPeople();
          setsucsessModal({ status: true, text: "تم تعديل الشخص بنجاح" });
          setTimeout(() => {
            closeModal();
          }, 1000);
        })
        .catch((err) => {
          let errors = JSON.parse(err.message).errors;
          setsubmitLoader({
            ...submitLoader,
            suubmit: {
              status: "failed",
              text: "فشل إضافة الشخص حاول مرة أخرى",
              disabled: false,
            },
          });
          console.log(err);
          if (errors) {
            seterrors(errors);
          }
        });
    } else {
      seterrors({ years: "السنة لا يمكن أن تكون فارغة" });
    }
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (EditPersonId) {
      handleEdit();
    } else {
      handleAdd();
    }
  };
  useEffect(() => {
    if (!isAddToYear) {
      getAllYears();
    }
    if (EditPersonId) {
      getPerson();
    }
    if (formType === "أعضاء هيئة التدريس") {
      setpersonData({ ...personData, subjects: ["", "", "", ""] });
    }
    if (formType === "الهيئة الادارية") {
      getAllDegrees();
    }
  }, []);
  return (
    <div className="modal_container z_index_100">
      <button onClick={closeModal} className="modal_bg"></button>
      <div className="esc_modal_year_detailes">
        <button onClick={closeModal}>x</button>
      </div>
      {sucsessModal.status === true && (
        <SucsessMessageModal
          close={() => setsucsessModal({ status: false, text: "" })}
          text={sucsessModal.text}
        />
      )}
      <div className="modal_content">
        {loader.status !== "loading" && loader.status !== "failed" ? (
          <form onSubmit={handleSubmit}>
            {formType === "الهيئة الادارية" && (
              <h1>إضافة أحد أعضاء الهيئة الإدارية</h1>
            )}
            {formType === "أعضاء هيئة التدريس" && (
              <h1>إضافة أحد أعضاء هيئة التدريس</h1>
            )}
            {formType === "الخريجون" && <h1>إضافة أحد الخريجين</h1>}
            <div className="inputs w-10/12">
              <div className="form_row flex w-full justify-between">
                <div className="flex flex-col">
                  <label>الاسم الأول</label>
                  <input
                    required
                    value={personData?.firstName}
                    onChange={handleOnchange}
                    name="firstName"
                    type="text"
                    className="my_rounded _my_greay_border w-80 h-11 text-sm py-0 px-1"
                  />
                  {errors?.firstName && (
                    <h6 className="text-xs text-red-400">
                      {errors?.firstName}
                    </h6>
                  )}
                </div>
                <div className="flex flex-col">
                  <label>اسم العائلة</label>
                  <input
                    required
                    value={personData?.lastName}
                    onChange={handleOnchange}
                    name="lastName"
                    type="text"
                    className="my_rounded _my_greay_border w-80 h-11 text-sm py-0 px-1"
                  />
                  {errors?.lastName && (
                    <h6 className="text-xs text-red-400">{errors?.lastName}</h6>
                  )}
                </div>
              </div>
              <div className="form_row flex w-full justify-between mt-4">
                <div className="flex flex-col">
                  <label>سنة الميلاد</label>
                  <input
                    // required
                    value={personData?.yearOfBirth}
                    name="yearOfBirth"
                    onChange={handleOnchange}
                    placeholder="1998"
                    min={1800}
                    max={9999}
                    type="number"
                    className="my_rounded _my_greay_border w-80 h-11 py-0 px-1 text_english"
                  />
                  {errors?.yearOfBirth && (
                    <h6 className="text-xs text-red-400">
                      {errors?.yearOfBirth}
                    </h6>
                  )}
                </div>
                <div className="flex flex-col">
                  <label>سنة الوفاة</label>
                  <input
                    // required
                    value={personData?.yearOfDeath}
                    onChange={handleOnchange}
                    name="yearOfDeath"
                    placeholder="2007"
                    min={1800}
                    max={9999}
                    type="number"
                    className="my_rounded _my_greay_border w-80 h-12 px-1 text_english"
                  />
                  {errors?.yearOfDeath && (
                    <h6 className="text-xs text-red-400">
                      {errors?.yearOfDeath}
                    </h6>
                  )}
                </div>
              </div>
              <div className="form_row flex w-full justify-between mt-4">
                {isAddToYear ? "" : ""}
                <div className="flex flex-col">
                  <label>
                    {formType === "الخريجون" && "سنة التخرج"}
                    {formType === "الهيئة الادارية" && "أحد سنوات التعين"}
                    {formType === "أعضاء هيئة التدريس" && "أحد سنوات التدريس"}
                  </label>
                  {isAddToYear ? (
                    <div className="my_rounded _my_greay_border w-80 h-12 px-1 text_english flex items-center">
                      {isAddToYear.year}
                    </div>
                  ) : (
                    <>
                      {yearsLoader.status === "done" ? (
                        <select
                          // defaultValue={personData.years[0]}
                          disabled={EditPersonId ? true : false}
                          required
                          value={
                            personData.years.length !== 0 && personData.years[0]
                          }
                          onChange={(e) =>
                            setpersonData({
                              ...personData,
                              years: [e.target.value],
                            })
                          }
                          className="my_rounded _my_greay_border w-80 h-12 px-1 text_english"
                        >
                          {years.map((elem, index) => (
                            <>
                              <option></option>
                              <option value={elem.id} key={index}>
                                {elem.year}
                              </option>
                            </>
                          ))}
                        </select>
                      ) : (
                        <div className="my_rounded _my_greay_border w-80 h-12 px-1 text_english flex items-center">
                          <div className="loader"></div>
                        </div>
                      )}
                    </>
                  )}
                  {EditPersonId && (
                    <h6 className="text-xs text-gray-400 mt-1">
                      يمكنك حذف أو إضافة سنة لشخص من صفحة تعديل السنة فقط
                    </h6>
                  )}
                  {errors.years && (
                    <h6 className="text-xs text-red-400">{errors.years}</h6>
                  )}
                </div>
                {/* handle show degree input when form type is board */}
                {formType === "الهيئة الادارية" && (
                  <div className="flex flex-col">
                    <label>الرتبة</label>
                    {degreesLoader.status === "done" ? (
                      <select
                        name="degree"
                        required
                        // defaultValue={personData.degree && personData.degree}
                        value={personData.degree}
                        onChange={(e) => {
                          setpersonData({
                            ...personData,
                            degree: e.target.value,
                          });
                        }}
                        className="my_rounded _my_greay_border w-80 h-12 px-1 "
                      >
                        <option></option>
                        {degrees.map((elem, index) => (
                          <>
                            <option value={elem} key={index}>
                              {elem}
                            </option>
                          </>
                        ))}
                      </select>
                    ) : (
                      <div className="my_rounded _my_greay_border w-80 h-12 px-1 text_english flex items-center">
                        <div className="loader"></div>
                      </div>
                    )}
                    {errors.degree && (
                      <h6 className="text-xs text-red-400">{errors.degree}</h6>
                    )}
                  </div>
                )}
              </div>

              {/* handle show subjects inputs if form type is teachers */}
              {formType === "أعضاء هيئة التدريس" && (
                <div className="form_row flex w-full justify-between mt-4">
                  <div className="flex flex-col w-full">
                    <label>المواد</label>
                    <div className="flex justify-between w-full flex-wrap">
                      {subjects?.map((elem, index) => (
                        <input
                          key={index}
                          // required={index == 0 ? true : false}
                          value={
                            personData.subjects && personData?.subjects[index]
                          }
                          onChange={(e) => onChangeSubjects(e, index)}
                          name="subjects"
                          type="text"
                          className="my_rounded _my_greay_border w-48 text-sm h-11 py-0 px-1"
                        />
                      ))}
                    </div>
                  </div>
                </div>
              )}
              <div className="mt-8 w-8/12">
                <div>
                  <div
                    onClick={() => imgeInputRef.current.click()}
                    className="dashed_border flex items-center py-3 px-8 my_rounded cursor-pointer"
                  >
                    <input
                      // required={personData?.profileImage ? false : true}
                      onChange={handleUplodeImage}
                      type="file"
                      ref={imgeInputRef}
                      className="input_file"
                    />
                    <figure
                      className="w-24 h-20 imge_bg my_rounded flex items-center justify-center"
                      style={{
                        backgroundImage: `url(${
                          personData.profileImage
                            ? personData.profileImage
                            : "https://i.postimg.cc/3J8fqYnd/imge.png"
                        })`,
                      }}
                    >
                      {submitLoader.uplode.status === "loading" && (
                        <div className="loader"></div>
                      )}
                    </figure>
                    <h4 className="mr-2">
                      {personData.postImage
                        ? personData?.profileImage?.substring(
                            personData.profileImage.length - 15
                          )
                        : "قم برفع الصورة بحد أقصى 2MB"}
                    </h4>
                    {personData?.profileImage && (
                      <div className="bg_success w-10 h-10 circle_rounded flex_center mr-40">
                        <MdOutlineDone size={25} />
                      </div>
                    )}
                  </div>
                  {submitLoader.uplode.status === "failed" && (
                    <h4 className="w-full text-center text-red-500 text-xs mt-2">
                      {submitLoader.uplode.text}
                    </h4>
                  )}
                </div>
                {errors.profileImage && (
                  <h6 className="text-xs text-red-400">
                    {errors.profileImage}
                  </h6>
                )}
              </div>
              <div className="flex flex-col mt-4">
                <label>نبذة</label>
                <textarea
                  required
                  value={personData?.brief}
                  name="brief"
                  onChange={(e) => {
                    personData.brief = e.target.value;
                  }}
                  className="my_rounded _my_greay_border w-8/12 h-32 p-2"
                ></textarea>
                {errors.brief && (
                  <h6 className="text-xs text-red-400">{errors.brief}</h6>
                )}
              </div>
              <div className="flex flex-col mt-4 w-8/12">
                <label>السيرة الذاتية</label>
                <HtmlEditor inetialvalue={personData.cv} editorRef={cvRef} />
              </div>
            </div>
            <button
              type="submit"
              className="my_rounded bg_secondary w-full mt-4 py-2 h3 h-14"
              disabled={submitLoader.suubmit.disabled}
            >
              {submitLoader.suubmit.status === "loading" ? (
                <div className="loader"></div>
              ) : (
                "حفظ"
              )}
            </button>
            {errors?.error && (
              <h6 className="text-center w-full text-xs text-red-400">
                {errors.error}
              </h6>
            )}
            <div className="h-10 mt-2">
              {submitLoader.suubmit.status === "failed" && (
                <h6 className="text-center w-full text-xs text-red-400">
                  {submitLoader.suubmit.text}
                </h6>
              )}
            </div>
          </form>
        ) : (
          <PageLoader loader={loader} />
        )}
      </div>
    </div>
  );
}
