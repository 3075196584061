import React, { useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import PageLoader from "../reuseable/PageLoader";
import { myFeach, myPutFeach } from "../../api";
import Confirm from "../reuseable/Confirm";
import SucsessMessageModal from "../reuseable/SucsessMessageModal";

export default function CategoryMoal({
  closeModal,
  categorys,
  regetCategorys,
  getCategoriesLoader,
}) {
  const [openAddnewCategoryModal, setopenAddnewCategoryModal] = useState({
    status: false,
    type: "add",
  });
  const [addedCategory, setaddedCategory] = useState("");
  const [loader, setloader] = useState({ status: "", text: "" });
  const [activeCategory, setactiveCategory] = useState({
    name: "",
    id: "",
  });
  const [confirmLoader, setconfirmLoader] = useState({
    status: "confirm",
    text: "",
  });
  const [openConfirmModal, setopenConfirmModal] = useState({
    status: false,
    text: "",
  });
  // handle submit edit category
  const editCategory = () => {
    myPutFeach(`categories/${activeCategory.id}`, { name: addedCategory })
      .then((res) => {
        setloader({ status: "done", text: "  " });
        regetCategorys();
        setopenAddnewCategoryModal({ status: false, type: "add" });
        setaddedCategory("");
        setaddedCategory("");
        console.log(res);
      })
      .catch((err) => {
        setloader({ status: "failed", text: " فشل إضافة التصنيف " });
        console.log(err);
      });
  };
  const addNewCat = () => {
    myFeach(`categories`, "POST", { name: addedCategory }, "authrized")
      .then((result) => {
        setloader({ status: "done", text: "  " });
        regetCategorys();
        setopenAddnewCategoryModal({ status: false, type: "add" });
        console.log(result);
      })
      .catch((err) => {
        setloader({ status: "failed", text: " فشل إضافة التصنيف " });
        console.log(err);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setloader({ status: "loading", text: "جاري إضافة التصنيف" });
    if (openAddnewCategoryModal.type === "edit") {
      editCategory();
    } else {
      addNewCat();
    }
  };
  // handle Delete year function
  const deleteCategory = (id) => {
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`categories/${id}`, "DELETE")
      .then((result) => {
        setconfirmLoader({ status: "done", text: "" });
        setopenConfirmModal({
          status: "sucsess",
          text: "تم حذف التصنيف  بنجاح",
        });
        regetCategorys();
      })
      .catch((err) => {
        setconfirmLoader({ status: "failed", text: "فشل حذف التصنيف" });
      });
  };
  return (
    <div className="modal_container z_index_100 pt-20 ">
      <button className="modal_bg"></button>
      <div className="esc_modal_year_detailes" style={{ width: "50%" }}>
        <button onClick={closeModal}>x</button>
      </div>
      <div
        className="modal_content relative mt-56  categories_modal_content "
        style={{ width: "50%" }}
      >
        {openConfirmModal.status === "confirmDelete" && (
          <Confirm
            close={() => {
              setopenConfirmModal({ status: false, text: "" });
              setconfirmLoader({ status: "confirm", text: "" });
            }}
            text={openConfirmModal.text}
            confirmFun={() => deleteCategory(activeCategory.id)}
            loader={confirmLoader}
          />
        )}
        {openConfirmModal.status === "sucsess" && (
          <SucsessMessageModal
            close={() => setopenConfirmModal({ status: false, text: "" })}
            text={openConfirmModal.text}
          />
        )}
        <>
          {openAddnewCategoryModal.status && (
            <div className=" absolute top-9 left-32  my_rounded shadow-sm shadow-black bg-white flex flex-col px-3 pb-2">
              <div className="w-full ">
                <button
                  onClick={() => {
                    setopenAddnewCategoryModal({ status: false });
                    setaddedCategory("");
                    setloader({ status: "", text: "" });
                  }}
                  className="h3"
                >
                  x
                </button>
              </div>
              <label>التصنيف</label>
              <input
                value={addedCategory}
                className="_my_greay_border w-80 h-9"
                onChange={(e) => setaddedCategory(e.target.value)}
              />
              <button
                disabled={loader.status === "loading"}
                className="bg_secondary h-9 w-80  my_rounded mt-2"
                onClick={handleSubmit}
              >
                {loader.status == "loading" ? (
                  <div className="loader"></div>
                ) : (
                  "حفظ"
                )}
              </button>
              {loader.status === "failed" && (
                <h4 className="text-red-400 w-full text-center text-xs mt-2">
                  {loader.text}
                </h4>
              )}
            </div>
          )}
          <div className="_my_greay_border w-4/5 m-auto">
            <button
              className="w-full h-12 bg_secondary my_rounded"
              onClick={() =>
                setopenAddnewCategoryModal({ status: true, type: "add" })
              }
            >
              إضافة تصنيف +
            </button>

            {getCategoriesLoader.status === "done" ? (
              <>
                {categorys.map((elem, index) => (
                  <div
                    key={index}
                    className="w-full  py-1 px-2 flex items-center justify-between"
                  >
                    <h4>{elem.name}</h4>
                    <div>
                      <button
                        className="mx-1"
                        onClick={() => {
                          setopenAddnewCategoryModal({
                            status: true,
                            type: "edit",
                          });
                          setactiveCategory(elem);
                          setaddedCategory(elem.name);
                        }}
                      >
                        <FiEdit2 size={20} color="green" />
                      </button>
                      <button
                        className="mx-1"
                        onClick={() => {
                          setopenConfirmModal({
                            id: elem.id,
                            status: "confirmDelete",
                            text: "أنت على وشك حذف هذا التصنيف هل أنت متأكد؟",
                          });
                          setactiveCategory({ id: elem.id });
                        }}
                      >
                        <AiFillDelete size={20} color="red" />
                      </button>
                      <button></button>
                    </div>
                  </div>
                ))}
              </>
            ) : (
              <PageLoader
                loader={getCategoriesLoader}
                text={getCategoriesLoader.text}
              />
            )}
          </div>
        </>
      </div>
    </div>
  );
}
const options = [
  { key: "key 1", title: "التصنيف الأول" },
  { key: "key 2", title: "التصنيف الثاني" },
];
