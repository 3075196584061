import React, { useState } from "react";
import ArciveDataBase from "../components/arcive/ArciveDataBase";
import ArciveTimeLine from "../components/arcive/ArciveTimeLine";
export default function Arcive() {
  const [activeFilter, setactiveFilter] = useState("time_line");
  return (
    <div className="page_container arcive_Page pt-10 px-10">
      <ul className="filters w-full">
        {filters.map((elem, index) => (
          <li
            onClick={() => setactiveFilter(elem.key)}
            className={`${activeFilter === elem.key ? "active_filter" : ""}`}
            key={index}
          >
            {elem.text}
          </li>
        ))}
      </ul>
      <div className="content mt-10">
        {activeFilter === "data_base" ? <ArciveDataBase /> : <ArciveTimeLine />}
      </div>
    </div>
  );
}
const filters = [
  { text: "الشريط الزمني", key: "time_line" },
  { text: "قاعدة البيانات", key: "data_base" },
];
