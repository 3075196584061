import React from "react";

export default function CustomTextArea({
  handleOnchange,
  width,
  label,
  name = "",
  value = "",
  errours,
}) {
  return (
    <>
      <div className="form_row flex w-full justify-between mt-4 ">
        <div className={`${width} flex flex-col`}>
          <label>{label}</label>
          <textarea
            onChange={handleOnchange}
            name={name}
            type="text"
            className={`my_rounded _my_greay_border w-full h-36 px-1 `}
            value={value}
          />
        </div>
      </div>
      {errours[name] && (
        <h4 className="w-full text-red-400 text-xs mt-1">{errours[name]}</h4>
      )}
    </>
  );
}
