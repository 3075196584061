import React from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import logo from "../../assets/logo.svg";
import TimeLineIcon from "../reuseable/svg-icons/TimeLineIcon";
import DataBaseIcon from "../reuseable/svg-icons/DataBaseIcon";
import BookIcon from "../reuseable/svg-icons/BookIcon";
import ArciveIcon from "../reuseable/svg-icons/ArciveIcon";
import PhotoIcon from "../reuseable/svg-icons/PhotoIcon";
import VideosIcon from "../reuseable/svg-icons/VideosIcon";
import AudioIcon from "../reuseable/svg-icons/AudioIcon";
import MessageIcon from "../reuseable/svg-icons/MessageIcon";
import RequestIcon from "../reuseable/svg-icons/RequestIcon";
import ArticleIcon from "../reuseable/svg-icons/ArticleIcon";
export default function Sidebar({ newContentNumber, unrededMessageCount }) {
  const location = useLocation();
  const pathname = location.pathname;

  return (
    <div
      id="side_bar"
      className="fixed right-0 top-0 bg_primary h-screen overflow-y-scroll w-72 side_bar"
    >
      <figure className="w-full flex justify-center mt-10 mb-20">
        <img src={logo} />
      </figure>
      <ul className="text_white">
        {sideParItems.map((elem, index) => (
          <Link
            to={elem.path}
            className={`flex items-center  text_hover_active text-2xl mb-4 pr-4 ${
              pathname === elem.path ? "text_active" : ""
            }`}
            key={index}
          >
            <span className="ml-2">{elem.icon(pathname)}</span>
            <span>{elem.text}</span>
            {elem.text === "طلبات إضافة محتوى" && (
              <span className="mr-auto  bg-green-400 circle_rounded w-5 h-5 flex items-center justify-center  text-white text-center ml-2 text-sm">
                {newContentNumber}
              </span>
            )}
            {elem.text === "رسائل الزوار" && (
              <span className="mr-auto  bg-green-400 circle_rounded w-5 h-5 flex items-center justify-center  text-white text-center ml-2 text-sm">
                {unrededMessageCount}
              </span>
            )}
          </Link>
        ))}
      </ul>
    </div>
  );
}

const sideParItems = [
  {
    text: "الشريط الزمني",
    path: "/",
    icon: (pathname) => <TimeLineIcon pathname={pathname} />,
  },
  {
    text: "قواعد البيانات",
    path: "/data-base",
    icon: (pathname) => <DataBaseIcon pathname={pathname} />,
  },
  {
    text: "الكتب",
    path: "/books",
    icon: (pathname) => <BookIcon pathname={pathname} />,
  },
  {
    text: "المقالات",
    path: "/articles",
    icon: (pathname) => <ArticleIcon pathname={pathname} />,
  },
  {
    text: "الصور و الوثائق",
    path: "/albums",
    icon: (pathname) => <PhotoIcon pathname={pathname} />,
  },
  {
    text: "المقاطع المرئية",
    path: "/videos",
    icon: (pathname) => <VideosIcon pathname={pathname} />,
  },
  {
    text: "المقاطع الصوتية",
    path: "/audios",
    icon: (pathname) => <AudioIcon pathname={pathname} />,
  },
  {
    text: "رسائل الزوار",
    path: "/messages",
    icon: (pathname) => <MessageIcon pathname={pathname} />,
  },
  {
    text: "طلبات إضافة محتوى",
    path: "requests",
    icon: (pathname) => <RequestIcon pathname={pathname} />,
  },
  {
    text: "الأرشيف",
    path: "/arcive",
    icon: (pathname) => <ArciveIcon pathname={pathname} />,
  },
];
