import React from "react";
import { MdOutlineError } from "react-icons/md";
export default function PageLoader({ loader }) {
  return (
    <div className="m-auto flex flex-col items-center mt-20">
      {loader.status === "loading" && <div className="page_loader"></div>}
      {loader.status === "failed" && (
        <div className="">
          <MdOutlineError size={50} color="red" />
        </div>
      )}
      <h3 className="w-fit">{loader.text}</h3>
    </div>
  );
}
