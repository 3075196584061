import React from "react";

export default function YoutubeIcon() {
  return (
    <svg
      className="youtube_icon"
      xmlns="http://www.w3.org/2000/svg"
      width="155"
      height="155"
      viewBox="0 0 155 155"
      fill="none"
    >
      <g clipPath="url(#clip0_508_307)">
        <rect
          x="39.3066"
          y="45.981"
          width="76.3876"
          height="63.7799"
          fill="white"
        />
        <path
          d="M122.808 22.6592H32.1915C14.4126 22.6592 0 37.0718 0 54.8507V100.15C0 117.928 14.4126 132.341 32.1915 132.341H122.808C140.587 132.341 155 117.928 155 100.15V54.8507C155 37.0718 140.587 22.6592 122.808 22.6592ZM101.038 79.7041L58.6531 99.9189C57.5237 100.458 56.2192 99.6342 56.2192 98.3831V56.6898C56.2192 55.4209 57.558 54.5985 58.6897 55.1721L101.074 76.6505C102.334 77.289 102.313 79.0962 101.038 79.7041Z"
          fill="#F61C0D"
        />
      </g>
      <defs>
        <clipPath id="clip0_508_307">
          <rect width="155" height="155" fill="white" />
        </clipPath>
      </defs>
    </svg>
  );
}
