import React, { useEffect, useState } from "react";
import { FiEdit2 } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import PageLoader from "../reuseable/PageLoader";
import { myFeach, myPutFeach } from "../../api";
import Confirm from "../reuseable/Confirm";
import SucsessMessageModal from "../reuseable/SucsessMessageModal";

export default function EditAlbumsModal({
  closeModal,
  albums,
  regetalbums,
  getAlbumsLoader,
}) {
  const [openAddnewalbumModal, setopenAddnewalbumModal] = useState({
    status: false,
    type: "add",
  });
  const [addedalbum, setaddedalbum] = useState("");
  const [loader, setloader] = useState({ status: "", text: "" });
  const [activealbum, setactivealbum] = useState({
    name: "",
    id: "",
  });
  const [confirmLoader, setconfirmLoader] = useState({
    status: "confirm",
    text: "",
  });
  const [openConfirmModal, setopenConfirmModal] = useState({
    status: false,
    text: "",
  });
  // handle submit edit album
  const editalbum = () => {
    myPutFeach(`albums/${activealbum._id}`, { name: addedalbum })
      .then((res) => {
        setloader({ status: "done", text: "  " });
        regetalbums();
        setopenAddnewalbumModal({ status: false, type: "add" });
        setaddedalbum("");
        setaddedalbum("");
        console.log(res);
      })
      .catch((err) => {
        setloader({ status: "failed", text: " فشل إضافة الألبوم " });
        console.log(err);
      });
  };
  const AddNewAlbum = () => {
    myFeach(`albums`, "POST", { name: addedalbum }, "authrized")
      .then((result) => {
        setloader({ status: "done", text: "  " });
        regetalbums();
        setopenAddnewalbumModal({ status: false, type: "add" });
        console.log(result);
      })
      .catch((err) => {
        setloader({ status: "failed", text: " فشل إضافة الألبوم " });
        console.log(err);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    setloader({ status: "loading", text: "جاري إضافة الألبوم" });
    if (openAddnewalbumModal.type === "edit") {
      editalbum();
    } else {
      AddNewAlbum();
    }
  };
  // handle Delete year function
  const deletealbum = (id) => {
    console.log(id);
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`albums/${id}`, "DELETE")
      .then((result) => {
        console.log(result);
        setconfirmLoader({ status: "done", text: "" });
        setopenConfirmModal({
          status: "sucsess",
          text: "تم حذف الألبوم  بنجاح",
        });
        regetalbums();
      })
      .catch((err) => {
        console.log(err);
        setconfirmLoader({ status: "failed", text: "فشل حذف الألبوم" });
      });
  };
  useEffect(()=>{
    console.log(albums,"..")
  },[])
  return (
    <div className="modal_container z_index_100 pt-20 ">
      <button className="modal_bg"></button>
      <div className="esc_modal_year_detailes" style={{ width: "50%" }}>
        <button onClick={closeModal}>x</button>
      </div>
      <div
        className="modal_content relative mt-56  categories_modal_content "
        style={{ width: "50%" }}
      >
        {openConfirmModal.status === "confirmDelete" && (
          <Confirm
            close={() => {
              setopenConfirmModal({ status: false, text: "" });
              setconfirmLoader({ status: "confirm", text: "" });
            }}
            text={openConfirmModal.text}
            confirmFun={() => deletealbum(activealbum.id)}
            loader={confirmLoader}
          />
        )}
        {openConfirmModal.status === "sucsess" && (
          <SucsessMessageModal
            close={() => setopenConfirmModal({ status: false, text: "" })}
            text={openConfirmModal.text}
          />
        )}
        {getAlbumsLoader.status === "done" ? (
          <>
            {openAddnewalbumModal.status && (
              <div className=" absolute top-9 left-32  my_rounded shadow-sm shadow-black bg-white flex flex-col px-3 pb-2">
                <div className="w-full ">
                  <button
                    onClick={() => {
                      setopenAddnewalbumModal({ status: false });
                      setaddedalbum("");
                      setloader({ status: "", text: "" });
                    }}
                    className="h3"
                  >
                    x
                  </button>
                </div>
                <label>الألبوم</label>
                <input
                  value={addedalbum}
                  className="_my_greay_border w-80 h-9"
                  onChange={(e) => setaddedalbum(e.target.value)}
                />
                <button
                  disabled={loader.status === "loading"}
                  className="bg_secondary h-9 w-80  my_rounded mt-2"
                  onClick={handleSubmit}
                >
                  {loader.status == "loading" ? (
                    <div className="loader"></div>
                  ) : (
                    "حفظ"
                  )}
                </button>
                {loader.status === "failed" && (
                  <h4 className="text-red-400 w-full text-center text-xs mt-2">
                    {loader.text}
                  </h4>
                )}
              </div>
            )}
            <div className="_my_greay_border w-4/5 m-auto">
              <button
                className="w-full h-12 bg_secondary my_rounded"
                onClick={() =>
                  setopenAddnewalbumModal({ status: true, type: "add" })
                }
              >
                إضافة ألبوم +
              </button>
              {albums.map((elem, index) => (
                <div
                  key={index}
                  className="w-full  py-1 px-2 flex items-center justify-between"
                >
                  <h4>{elem.name}</h4>
                  <div>
                    <button
                      className="mx-1"
                      disabled={
                        elem.name === "الكل" ||
                        elem.name === "أخرى" ||
                        elem.name === "وثائق"
                      }
                      onClick={() => {
                        setopenAddnewalbumModal({
                          status: true,
                          type: "edit",
                        });
                        setactivealbum(elem);
                        setaddedalbum(elem.name);
                      }}
                    >
                      <FiEdit2 size={20} color="green" />
                    </button>
                    <button
                      disabled={
                        elem.name === "الكل" ||
                        elem.name === "أخرى" ||
                        elem.name === "وثائق"
                      }
                      className="mx-1"
                      onClick={() => {
                        setopenConfirmModal({
                          id: elem._id,
                          status: "confirmDelete",
                          text: "أنت على وشك حذف هذا الألبوم هل أنت متأكد؟",
                        });
                        setactivealbum({ id: elem._id });
                      }}
                    >
                      <AiFillDelete size={20} color="red" />
                    </button>
                    <button></button>
                  </div>
                </div>
              ))}
            </div>
          </>
        ) : (
          <PageLoader loader={getAlbumsLoader} text={getAlbumsLoader.text} />
        )}
      </div>
    </div>
  );
}

// import React, { useState } from "react";
// import { FiEdit2 } from "react-icons/fi";
// import { AiFillDelete } from "react-icons/ai";
// import AddAlbumModal from "./AddAlbumModal";

// export default function EditAlbumsModal({ closeModal, addNew }) {
//   const [inputModal, setinputModal] = useState({ status: false });
//   const handleSubmit = (e) => {
//     e.preventDefault();
//   };
//   return (
//     <div className="modal_container z_index_100 pt-20 ">
//       <button className="modal_bg"></button>
//       <div className="esc_modal_year_detailes" style={{ width: "50%" }}>
//         <button onClick={closeModal}>x</button>
//       </div>
//       <div
//         className="modal_content relative mt-56  categories_modal_content "
//         style={{ width: "50%" }}
//       >
//         {inputModal.status && (
//           <AddAlbumModal
//             closeModal={() => setinputModal({ status: false })}
//             width="90%"
//           />
//         )}
//         <div className="_my_greay_border w-4/5 m-auto">
//           <button
//             className="w-full h-12 bg_secondary my_rounded"
//             onClick={() => setinputModal({ status: true })}
//           >
//             إضافة ألبوم +
//           </button>
//           {options.map((elem, index) => (
//             <div className="w-full  py-1 px-2 flex items-center justify-between">
//               <h4> الألبوم 1</h4>
//               <div>
//                 <button className="mx-1">
//                   <FiEdit2 size={20} color="green" />
//                 </button>
//                 <button className="mx-1">
//                   <AiFillDelete size={20} color="red" />
//                 </button>
//                 <button></button>
//               </div>
//             </div>
//           ))}
//         </div>
//       </div>
//     </div>
//   );
// }
// const options = [
//   { key: "key 1", title: "الألبوم الأول" },
//   { key: "key 2", title: "الألبوم الثاني" },
// ];
