import React from "react";

export default function VideosIcon({ pathname }) {
  return (
    <>
      {pathname === "/videos" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
            fill="#D59A2C"
          />
          <path
            d="M9.10156 12V10.52C9.10156 8.60999 10.4516 7.83999 12.1016 8.78999L13.3816 9.52999L14.6616 10.27C16.3116 11.22 16.3116 12.78 14.6616 13.73L13.3816 14.47L12.1016 15.21C10.4516 16.16 9.10156 15.38 9.10156 13.48V12Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            opacity="0.4"
            d="M16.19 2H7.81C4.17 2 2 4.17 2 7.81V16.18C2 19.83 4.17 22 7.81 22H16.18C19.82 22 21.99 19.83 21.99 16.19V7.81C22 4.17 19.83 2 16.19 2Z"
            fill="#D9D9D9"
          />
          <path
            d="M9.10156 12V10.52C9.10156 8.60999 10.4516 7.83999 12.1016 8.78999L13.3816 9.52999L14.6616 10.27C16.3116 11.22 16.3116 12.78 14.6616 13.73L13.3816 14.47L12.1016 15.21C10.4516 16.16 9.10156 15.38 9.10156 13.48V12Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
}
