import React, { useEffect, useRef } from "react";
import ArrowLeft from "./svg-icons/ArrowLeft";
import ArrowRight from "./svg-icons/ArrowRight";
import YoutubeIcon from "./svg-icons/YoutubeIcon";
import { AiFillDelete } from "react-icons/ai";

export default function LibrarySlider({ data, sliderType, openAddCoverModal,handleDelete }) {
  const sliderRef = useRef(null);
  const handleScroll = (direction) => {
    const sliderElement = sliderRef.current;
    if (sliderElement) {
      const scrollAmount =
        direction === "left"
          ? -sliderElement.offsetWidth
          : sliderElement.offsetWidth;

      sliderElement.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };
  return (
    <section className="library_slider">
      <div className="arr_right" onClick={() => handleScroll("right")}>
        <ArrowRight />
      </div>
      <div className="container" ref={sliderRef}>
        {sliderType === "videos" ? (
          <>
            {data.map((elem, index) => (
              <a
                target="_blank"
                href={elem.videoLink}
                className="slider_elem"
                style={{
                  backgroundImage: `url(https://i.postimg.cc/MTMYsrn3/Books-slider-BG.png)`,
                }}
                key={index}
              >
                <div className="youtube_icon ">
                  <YoutubeIcon />
                </div>
                <div className="content">
                  <h2>{elem.title}</h2>
                  <h3>{elem.auther}</h3>
                  <div className="h3">{elem.puplishDate}</div>
                </div>
                <div className="flex items-center mt-auto mb-3 btns">
                  <button
                    className="my_rounded  py-2 px-14 bg-black mx-2 text-3xl text-green-400"
                    onClick={openAddCoverModal}
                  >
                    +
                  </button>
                  <button className="my_rounded text_white p-2 bg-black mx-2">
                    <AiFillDelete size={35} color="red" />
                  </button>
                </div>
              </a>
            ))}
          </>
        ) : (
          <>
            {data.map((elem, index) => (
              <div
                className="slider_elem"
                style={{
                  backgroundImage: `url(https://i.postimg.cc/MTMYsrn3/Books-slider-BG.png)`,
                }}
                key={index}
              >
                <div className="content">
                  <h2>{elem.title}</h2>
                  <h3>{elem.author.fullName}</h3>
                  <div className="h3">{elem.publishedAt}</div>
                </div>
                <div className="flex items-center mt-auto mb-3 btns">
                  <button
                    className="my_rounded  py-2 px-14 bg-black mx-2 text-3xl text-green-400"
                    onClick={openAddCoverModal}
                  >
                    +
                  </button>
                  <button
                    onClick={() => handleDelete(elem.id)}
                    className="my_rounded text_white p-2 bg-black mx-2"
                  >
                    <AiFillDelete size={35} color="red" />
                  </button>
                </div>
                {sliderType === "books" && (
                  <div className="book_imge_container">
                    {!elem?.inList && <h4>قريباُ</h4>}
                    <img src={elem.imagePath} />
                  </div>
                )}
              </div>
            ))}
          </>
        )}
      </div>
      <div className="arr_left" onClick={() => handleScroll("left")}>
        <ArrowLeft />
      </div>
    </section>
  );
}
