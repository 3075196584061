import React, { useEffect, useState } from "react";
import { myFeach } from "../../api";
import PageLoader from "../reuseable/PageLoader";
export default function BookDetailsModal({
  id,
  headers,
  setopenDetailesModal,
}) {
  const [loader, setloader] = useState({ status: "loading", text: "جاري تحميل بيانات الكتاب" });
  const [bookDetailes, setbookDetailes] = useState();
  const closeModal = () => {
    setopenDetailesModal({ status: false, personId: "" });
  };
  const getBookDetailes = () => {
    myFeach(`books/${id}`, "GET")
      .then(({ data }) => {
        setbookDetailes(data)
        setloader({status:"done",text:""});
      })
      .catch((err) => {
        console.log(err);
        setloader({status:"failed",text:"فشل تحميل بيانات الكتاب"});
      });
  };
  useEffect(() => {
    getBookDetailes();
  }, []);
  return (
    <>
      <div className="modal_container">
        <button onClick={closeModal} className="modal_bg"></button>
        <div className="small_modal_conrent">
          <button className="text_greay text-xl" onClick={closeModal}>
            X
          </button>
          {loader.status === "done" ? (
            <div className="person_detaile">
              <figure className="m-auto h-32 w-full ">
                <img
                  className="block h-full my_rounded m-auto"
                  src={bookDetailes.imagePath}
                />
              </figure>
              <h3 className="mx-auto mt-2 w-fit font_primary">
                {bookDetailes?.title}
              </h3>
              <div className="person_data w-4/5 mx-auto mt-10">
                {headers.map((elem, index) => (
                  <div className="row" key={index}>
                    <h4 className={`${elem.class} title `}>{elem.title}</h4>
                    <h4 className={`${elem.class} info`}>
                        {elem.key==="author"?<>
                        {bookDetailes[elem?.key].fullName}{" "}
                        </>:<>
                        {bookDetailes[elem?.key]}{" "}
                        </>}
                    
                    </h4>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <PageLoader text={loader.text} loader={loader} />
          )}
        </div>
      </div>
    </>
  );
}
