import React from "react";

export default function HeartIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="29"
      height="30"
      viewBox="0 0 29 30"
      fill="none"
    >
      <path
        d="M19.5187 4.58643C17.3698 4.58643 15.4464 5.63122 14.2472 7.23404C13.6348 6.41342 12.8394 5.74685 11.9244 5.28727C11.0093 4.82768 9.99973 4.58771 8.97574 4.58643C5.33082 4.58643 2.37451 7.5546 2.37451 11.2233C2.37451 12.6361 2.60009 13.9421 2.99189 15.1531C4.86778 21.0895 10.6498 24.6394 13.5111 25.613C13.9148 25.7555 14.5797 25.7555 14.9833 25.613C17.8447 24.6394 23.6267 21.0895 25.5026 15.1531C25.8944 13.9421 26.1199 12.6361 26.1199 11.2233C26.1199 7.5546 23.1636 4.58643 19.5187 4.58643Z"
        fill="#D59A2C"
      />
    </svg>
  );
}
