import React, { useRef, useState } from "react";
// global functions
import { myFeach, postImage } from "../api";
// icons
import { RiAttachment2 } from "react-icons/ri";
import { RiDeleteBinLine } from "react-icons/ri";
// components
import SelectPersonModall from "../components/timeline/SelectPersonModall";
import PageLoader from "../components/reuseable/PageLoader";
import AddPersonModall from "../components/timeline/AddPersonModall";
import Confirm from "../components/reuseable/Confirm";
import SucsessMessageModal from "../components/reuseable/SucsessMessageModal";
export default function AddYear() {
  const [formData, setformData] = useState({
    year: "",
    image: "",
  });
  const [openAddPersonModall, setopenAddPersonModall] = useState({
    status: false,
    formType: "",
    modalType: "select",
  });
  const [loader, setloader] = useState({ status: "", text: "" });
  const [imgeLoader, setimgeLoader] = useState({ status: "", text: "" });
  const [getYearLoader, setgetYearLoader] = useState({
    status: "done",
    text: "",
  });
  const [yearDetailes, setyearDetailes] = useState({
    people: { graduates: [], teachingStaff: [], administrativeBoard: [] },
  });
  const [yearIscreated, setyearIscreated] = useState({
    status: false,
    id: "",
    detailes: [],
  });
  const [openConfirmModal, setopenConfirmModal] = useState({
    status: false,
    text: "",
    id: "",
  });
  const [confirmLoader, setconfirmLoader] = useState({
    status: "confirm",
    text: "",
  });
  const [activePerson, setactivePerson] = useState("");
  const [imageReqErr, setimageReqErr] = useState({ status: "", text: "" });
  const currentDate = new Date();
  // imge information
  const imgeInputRef = useRef(null);
  let Imageformdata = new FormData();
  // handle uplode imge
  const handleUplodeImage = async (e) => {
    setimgeLoader({ status: "loading", text: "", disabled: true });
    let file = await e.target.files[0];
    Imageformdata.append("image", file);
    postImage("years/image", Imageformdata)
      .then((result) => {
        setformData({ ...formData, image: result.data?.image });
        setimageReqErr({ status: "", text: "" });
        setimgeLoader({ ...imgeLoader, status: "done", disabled: false });
      })
      .catch((err) => {
        let errMsg = JSON.parse(err.message).error;
        setimgeLoader({
          ...imgeLoader,
          status: "failed",
          text: errMsg,
          disabled: false,
        });
      });
  };
  const handleSubmitYear = (e) => {
    e.preventDefault();
    if (!formData.image) {
      setimageReqErr({ status: true, text: "الصورة مطلوبة" });
    } else {
      setloader({ status: "loading", text: "", disabled: true });
      myFeach(`years/`, "POST", formData, "authrized")
        .then((result) => {
          console.log("rsult", result);
          setyearIscreated({
            ...yearIscreated,
            status: true,
            id: result.data.id,
          });
          setloader({
            status: "done",
            text: " ",
            disabled: false,
          });
        })
        .catch((err) => {
          // need update
          let errMsg = JSON.parse(err.message).error;
          setloader({
            status: "failed",
            text: errMsg ? errMsg : "فشل إضافة السنة ",
            disabled: false,
          });
        });
    }
  };
  // handle get year detailes after add evry person
  const getYearDetailes = () => {
    setgetYearLoader({ status: "loading", text: "جاري تحميل بيانات السنة" });
    myFeach(`years/${yearIscreated.id}`, "GET")
      .then(({ data }) => {
        console.log("hiii", data);
        setyearDetailes(data.year);
        setgetYearLoader({ status: "done", text: "" });
      })
      .catch((err) => {
        setgetYearLoader({ status: "failed", text: "فشل تحميل بيانات السنة" });
      });
  };
  // handle delete person from this year
  const deleteFromYear = (personId) => {
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`people/${personId}/${yearIscreated.id}`, "DELETE")
      .then((result) => {
        setconfirmLoader({ status: "done", text: "" });
        setopenConfirmModal({
          status: "sucsess",
          text: "تم مسح الشخص والكتب والمقالات المتعلقة به بنجاح",
        });
        getYearDetailes();
      })
      .catch((err) => {
        console.log("err", err);
        // need update
        // console.log("errour",JSON.parse(err.message))
        // let errMsg = JSON.parse(err.message).errors[0].msg;
        setconfirmLoader({ status: "failed", text: "فشل حذف الشخص" });
      });
  };
  return (
    <div className="page_container py-10 px-8">
      {openAddPersonModall.status === true && (
        <>
          {openAddPersonModall.modalType === "select" ? (
            <SelectPersonModall
              role={openAddPersonModall?.formType}
              reloadYeardetailes={getYearDetailes}
              activeYear={{ id: yearIscreated.id }}
              closeModal={() =>
                setopenAddPersonModall({
                  ...openAddPersonModall,
                  status: false,
                  modalType: "select",
                })
              }
              addNew={() => {
                setopenAddPersonModall({
                  ...openAddPersonModall,
                  modalType: "add",
                });
              }}
            />
          ) : (
            <AddPersonModall
              regeAllPeople={getYearDetailes}
              setformData={setformData}
              isAddToYear={{ id: yearIscreated.id, year: formData.year }}
              formData={formData}
              closeModal={() =>
                setopenAddPersonModall({
                  ...openAddPersonModall,
                  status: false,
                  modalType: "select",
                })
              }
              formType={openAddPersonModall?.formType}
            />
          )}
        </>
      )}
      {openConfirmModal.status === "confirmDelete" && (
        <Confirm
          close={() => {
            setopenConfirmModal({ status: false, text: "" });
            setconfirmLoader({ status: "confirm", text: "" });
          }}
          text={openConfirmModal.text}
          confirmFun={() => deleteFromYear(activePerson)}
          loader={confirmLoader}
        />
      )}
      {openConfirmModal.status === "sucsess" && (
        <SucsessMessageModal
          close={() => setopenConfirmModal({ status: false, text: "" })}
          text={openConfirmModal.text}
        />
      )}
      <div className="">
        <form
          onSubmit={handleSubmitYear}
          className="flex items-start justify-between flex-wrap"
        >
          <div className="flex flex-col">
            <label className="h3">السنة</label>
            <input
              required
              disabled={yearIscreated.status}
              type="number"
              min={1870}
              max={currentDate.getFullYear()}
              onChange={(e) =>
                setformData({ ...formData, year: e.target.value })
              }
              className="p-2 my_rounded  _my_greay_border w-60 mt-2 text_english"
            />
          </div>
          <div>
            <div className="my_rounded dashed_border p-3 flex items-center">
              <figure className="mx-4 w-20 h-20 flex justify-center items-center">
                {imgeLoader.status === "loading" ? (
                  <div className="loader"></div>
                ) : (
                  <img
                    src={
                      formData?.image
                        ? formData?.image
                        : "https://i.postimg.cc/3J8fqYnd/imge.png"
                    }
                    className="block w-full h-full"
                  />
                )}
              </figure>
              <h4 className="mx-4">قم برفع الصورة بحد أقصى للصورة 2MB</h4>
              <input
                onChange={handleUplodeImage}
                type="file"
                className="input_file"
                ref={imgeInputRef}
                disabled={imgeLoader.disabled || yearIscreated.status}
              />
              <button
                type="button"
                disabled={yearIscreated.status}
                className="bg_secondary my_rounded px-10 py-2 h3 flex items-center mx-4"
                onClick={() => imgeInputRef.current.click()}
              >
                <span>إرفاق</span>
                <RiAttachment2 color="white" />
              </button>
            </div>
            <h4 className="w-full text-xs text-center text-red-500 mt-2">
              {imageReqErr.status && <>{imageReqErr.text}</>}
            </h4>
            <h4 className="w-full text-xs text-center text-red-500 mt-2">
              {imgeLoader.status === "failed" && <>{imgeLoader.text}</>}
            </h4>
          </div>
          <button
            type="submit"
            className="my_rounded bg_secondary w-full mt-10 py-2 h-12 h3"
            disabled={yearIscreated.status}
          >
            {loader.status === "loading" ? (
              <div className="loader"></div>
            ) : (
              <>
                {!yearIscreated.status
                  ? "حفظ"
                  : "تم إضافة السنة يمكنك إضافة (مدرس-إداري-خريج)"}
              </>
            )}
          </button>
          {loader.status === "failed" && (
            <h4 className="text-xs text-red-500 w-full text-center mt-2">
              {loader.text}
            </h4>
          )}
        </form>
      </div>
      {getYearLoader?.status !== "done" ? (
        <PageLoader loader={getYearLoader} text={getYearLoader?.text} />
      ) : (
        <div className="flex justify-between mt-20">
          <div className="w-1/4 ">
            <div className="w-full flex justify-between border_buttom">
              <h3>الهيئة الإدارية</h3>
              <button
                disabled={!yearIscreated.status}
                onClick={() =>
                  setopenAddPersonModall({
                    ...openAddPersonModall,
                    status: true,
                    formType: "الهيئة الادارية",
                  })
                }
                className="bg_secondary my_rounded px-4 py-1  h3"
              >
                +
              </button>
            </div>
            <ul>
              {yearDetailes?.people?.administrativeBoard.map((elem, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center my_rounded bg_greay p-3 my-2"
                >
                  <h4>
                    {elem.firstName} {elem.lastName}
                  </h4>
                  <button
                    className="pointer"
                    onClick={() => {
                      setopenConfirmModal({
                        id: elem.id,
                        status: "confirmDelete",
                        text: "أنت على وشك حذف هذا الشخص من هذه السنة، هل أنت متأكد؟",
                      });
                      setactivePerson(elem.id);
                    }}
                  >
                    <RiDeleteBinLine color="#F61C0D" />
                  </button>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-1/4 ">
            <div className="w-full flex justify-between border_buttom">
              <h3>هيئة التدريس</h3>
              <button
                disabled={!yearIscreated.status}
                onClick={() =>
                  setopenAddPersonModall({
                    ...openAddPersonModall,
                    status: true,
                    formType: "أعضاء هيئة التدريس",
                  })
                }
                className="bg_secondary my_rounded px-4 py-1  h3"
              >
                +
              </button>
            </div>
            <ul>
              {yearDetailes?.people?.teachingStaff.map((elem, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center my_rounded bg_greay p-3 my-2"
                >
                  <h4>
                    {elem.firstName} {elem.lastName}
                  </h4>
                  <div
                    className="pointer"
                    onClick={() => {
                      setopenConfirmModal({
                        id: elem.id,
                        status: "confirmDelete",
                        text: "أنت على وشك حذف هذا الشخص من هذه السنة، هل أنت متأكد؟",
                      });
                      setactivePerson(elem.id);
                    }}
                  >
                    <RiDeleteBinLine color="#F61C0D" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
          <div className="w-1/4 ">
            <div className="w-full flex justify-between border_buttom">
              <h3>الخريجون</h3>
              <button
                disabled={!yearIscreated.status}
                onClick={() =>
                  setopenAddPersonModall({
                    ...openAddPersonModall,
                    status: true,
                    formType: "الخريجون",
                  })
                }
                className="bg_secondary my_rounded  px-4 py-1  h3"
              >
                +
              </button>
            </div>
            <ul>
              {yearDetailes?.people?.graduates.map((elem, index) => (
                <li
                  key={index}
                  className="flex justify-between items-center my_rounded bg_greay p-3 my-2"
                >
                  <h4>
                    {elem.firstName} {elem.lastName}
                  </h4>
                  <div
                    className="pointer"
                    onClick={() => {
                      setopenConfirmModal({
                        id: elem.id,
                        status: "confirmDelete",
                        text: "أنت على وشك حذف هذا الشخص من هذه السنة، هل أنت متأكد؟",
                      });
                      setactivePerson(elem.id);
                    }}
                  >
                    <RiDeleteBinLine color="#F61C0D" />
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  );
}
