import React, { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
// icons
import { BiSolidShow } from "react-icons/bi";
import { MdEdit } from "react-icons/md";
import { RiDeleteBinLine } from "react-icons/ri";
import logo from "../assets/logo.svg";
// global fun
import { myFeach } from "../api";
//components
import YearDetailesModal from "../components/timeline/YearDetailesModal";
import YearsDropdowen from "../components/timeline/YearsDropdowen";
import ArciveIcon from "../components/reuseable/svg-icons/ArciveIcon";
import PageLoader from "../components/reuseable/PageLoader";
import EditYearModal from "../components/timeline/EditYearModal";
import Confirm from "../components/reuseable/Confirm";
import SucsessMessageModal from "../components/reuseable/SucsessMessageModal";
export default function TimeLine() {
  const [showDetailesModal, setshowDetailesModal] = useState(false);
  const [showEditYearModal, setshowEditYearModal] = useState();
  const [years, setyears] = useState([]);
  const [activeYear, setactiveYear] = useState();
  const [loader, setloader] = useState({
    text: "جاري تحميل البيانات",
    status: "loading",
  });
  const [confirmLoader, setconfirmLoader] = useState({
    status: "confirm",
    text: "",
  });
  const [openConfirmModal, setopenConfirmModal] = useState({
    status: false,
    text: "",
  });
  const navigate = useNavigate();
  const getAllYears = () => {
    myFeach("years/?isDeleted=false", "GET")
      .then(({ data }) => {
        if (data?.years?.length === 0) {
          setloader({ status: "failed", text: "لا يوجد سنوات لعرضها" });
        } else {
          setyears(data.years);
          setactiveYear(data.years[0]);
          setloader({ text: "", status: "done" });
        }
      })
      .catch((err) => {
        const errour = JSON.parse(err.message);
        // if (errour.status == 401) {
        //   navigate("/login")
        // } else {
          setloader({ text: "فشل تحميل البيانات", status: "failed" });
        // }
      });
  };
  // handle change imge in view when its updated in edit year modal using api
  const updateImageById = (myID, imageUrl) => {
    let tempYears = [...years];
    tempYears.forEach((year) => {
      if (year.id === myID) {
        year.image = imageUrl;
      }
    });
    setyears(tempYears);
  };
  // handle archive fun
  const archiveFun = () => {
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`years/archive/${activeYear.id}`, "PUT")
      .then((result) => {
        console.log("result", result);
        setconfirmLoader({ status: "done", text: "" });
        navigate("/arcive");
      })
      .catch((err) => {
        // need update
        // let errMsg = JSON.parse(err.message).errors[0].msg;
        // console.log("errour",JSON.parse(err.message))
        setconfirmLoader({ status: "failed", text: "فشل أرشفة السنة" });
      });
  };
  // handle Delete year function
  const deleteYear = () => {
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`years/${activeYear.id}`, "DELETE")
      .then((result) => {
        setconfirmLoader({ status: "done", text: "" });
        setopenConfirmModal({ status: "sucsess", text: "تم مسح السنة بنجاح" });
        getAllYears();
      })
      .catch((err) => {
        // need update
        // console.log("errour",JSON.parse(err.message))
        // let errMsg = JSON.parse(err.message).errors[0].msg;
        setconfirmLoader({ status: "failed", text: "فشل حذف السنة" });
      });
  };
  // handle call apis on component start
  useEffect(() => {
    getAllYears();
  }, []);
  // handle prevent scroll if moddal open
  useEffect(() => {
    if (showDetailesModal || showEditYearModal) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "scroll";
    }
  }, [showDetailesModal, showEditYearModal]);


  return (
    <div className="page_container">
      <>
        {loader.status === "done" ? (
          <>
            {/* modales */}
            {showDetailesModal && (
              <YearDetailesModal
                years={years}
                closeModal={() => setshowDetailesModal(false)}
                activeYear={activeYear}
                setactiveYear={setactiveYear}
              />
            )}
            {showEditYearModal === true && (
              <EditYearModal
                closeModal={() => setshowEditYearModal(false)}
                activeYear={activeYear}
                handleChangeImgeinPage={updateImageById}
              />
            )}
            {openConfirmModal.status === "confirm" && (
              <Confirm
                close={() => setopenConfirmModal({ status: false, text: "" })}
                text={openConfirmModal.text}
                confirmFun={openConfirmModal.fun}
                loader={confirmLoader}
              />
            )}
            {openConfirmModal.status === "sucsess" && (
              <SucsessMessageModal
                close={() => setopenConfirmModal({ status: false, text: "" })}
                text={openConfirmModal.text}
              />
            )}
            {/* end modals */}
            <div className="timeline pt-20 pr-16 pl-16">
              <div className="flex flex-row">
                <div className="flex flex-col w-1/5 pt-2">
                  <div className="flex flex-col mb-4">
                    <YearsDropdowen
                      years={years}
                      setactiveYear={setactiveYear}
                      activeYear={activeYear}
                    />
                  </div>
                  <button
                    className="w-full my_rounded bg_secondary mt-2 p-2 flex_center"
                    onClick={() => setshowDetailesModal(true)}
                  >
                    <BiSolidShow color="white" size={25} />
                    <span className="mr-1">عرض التفاصيل</span>
                  </button>
                  <button className="w-full my_rounded bg_success mt-2 p-2 flex_center">
                    <MdEdit color="white" size={25} />
                    <button
                      onClick={() => setshowEditYearModal(true)}
                      className="mr-1"
                    >
                      تعديل
                    </button>
                  </button>
                  <button
                    className="w-full my_rounded bg_primary mt-2 p-2 flex_center"
                    onClick={() =>
                      setopenConfirmModal({
                        status: "confirm",
                        text: "أنت على وشك أرشفة السنة، هل أنت متأكد؟",
                        fun: archiveFun,
                      })
                    }
                  >
                    <ArciveIcon color="white" size={25} />
                    <span className="mr-1">أرشفة</span>
                  </button>
                  <button
                    className="w-full my_rounded bg_red mt-2 p-2 flex_center"
                    onClick={() =>
                      setopenConfirmModal({
                        status: "confirm",
                        text: "أنت على وشك حذف السنة نهائياً، هل أنت متأكد؟",
                        fun: deleteYear,
                      })
                    }
                  >
                    <RiDeleteBinLine color="white" size={25} />
                    <span className="mr-1">حذف</span>
                  </button>
                </div>
                <figure
                  className="w-4/5 pr-10 pt-0 flex_center year_image imge_bg my_rounded mr-4"
                  style={{
                    backgroundImage: `url(${
                      activeYear.image
                        ? activeYear.image
                        : "https://i.postimg.cc/TY9bqtGh/Layer-1.png"
                    })`,
                  }}
                >
                  {/* <img
                    className="block w-full my_rounded"
                    src={activeYear?.image}
                    onError={() =>
                      setactiveYear({ ...activeYear, image: logo })
                    }
                  /> */}
                </figure>
              </div>
              <Link
                to="/add-year"
                className="my_rounded bg_secondary p-2 w-full mt-6 block text-center mb-4"
              >
                + إضافة سنة جديدة
              </Link>
            </div>
          </>
        ) : (
          <>
            <PageLoader loader={loader} text={loader.text} />
            {(loader?.status === "failed"&&
            loader?.text === "لا يوجد سنوات لعرضها") && (
              <Link
                to="/add-year"
                className="my_rounded bg_secondary p-2  mt-6 block text-center mb-4 w-4/5 mx-auto mt-auto "
              >
                + إضافة سنة جديدة
              </Link>
            )}
          </>
        )}
      </>
    </div>
  );
}
