import React from "react";

export default function Confirm({ confirmFun, close, text, loader }) {
  
  return (
    <div className="w-full h-screen fixed z_index_100">
    <div
      style={{ top: "200px" }}
      className="w-96 h-52 position_center  my_rounded flex flex-col justify-between items-center bg-white shadow-2xl z_index_1000 px-2 pt-2 pb-4"
    >
      <div className="w-full flex justify-start">
        <button onClick={close}>X</button>
      </div>
      <h3 className="w-full text-center">{text}</h3>
      <div className="w-full justify-center flex mt-4">
        <button
          className="h-8 text_white my_rounded bg-green-500 mx-3 px-3 py-1 w-20"
          onClick={confirmFun}
        >
          {loader.status === "loading" ? (
            <div className="loader"></div>
          ) : (
            "تأكيد"
          )}
        </button>
        <button
          className="h-8 text_white my_rounded bg-red-500 mx-3 px-3 py-1 w-20"
          onClick={close}
        >
          تراجع
        </button>
      </div>
      {loader.status === "failed" && (
        <h6 className="w-full text-center text-xs text-red-500">
          {loader.text}
        </h6>
      )}
    </div>
    </div>
  );
}
