import React, { useRef, useState } from "react";
import { useEffect } from "react";
import { RiAttachment2 } from "react-icons/ri";
import { myFeach, postImage } from "../../api";
import PageLoader from "../reuseable/PageLoader";
import SucsessMessageModal from "../reuseable/SucsessMessageModal";
export default function AddArticleCoverModal({ closeModal, reloadarticleSlider }) {
  const [addFromExist, setaddFromExist] = useState(true);
  const [articlesList, setarticlesList] = useState({
    data: [],
    loader: { status: "loading", text: "جاري تحميل المقالات" },
  });
  const [selectedArticle, setselectedArticle] = useState("");
  const [addedArticle, setaddedArticle] = useState({
    imagePath: "",
    title: "",
    author: "",
  });
  const [imgeLoader, setimgeLoader] = useState({ status: "", text: "" });
  const [submitLoader, setsubmitLoader] = useState({ status: "", text: "" });
  const [sucsessMsg, setsucsessMsg] = useState(false);
  const [imageErr, setimageErr] = useState(false);
  const fileInputRef = useRef(null);
  const handleButtonClick = () => {
    fileInputRef.current.click();
  };
  const getArticlesData = () => {
    if (articlesList.loader.status !== "loading") {
      setarticlesList({
        ...articlesList,
        loader: { status: "loading", text: "جاري تحميل المقالات" },
      });
    }
    myFeach(`articles?limit=1000`, "GET")
      .then(({ data }) => {
        if (data.articles.length == 0) {
          setarticlesList({
            ...articlesList,
            data: [],
            loader: { status: "failed", text: "لا يوجد مقالات للاختيار منها" },
          });
        } else {
          setarticlesList({
            data: data.articles,
            loader: { status: "done", text: "" },
            numberOfPages: data.numberOfPages,
          });
          setselectedArticle(data.articles[0].id);
        }
      })
      .catch((err) => {
        console.log("errp...", err);
        setarticlesList({
          ...articlesList,
          loader: { status: "failed", text: "فشل تحميل المقالات" },
        });
      });
  };
  // handle uplode imge
  const handleUplodeImage = async (e) => {
    setimgeLoader({ status: "loading", text: "جاري رفع الصورة" });
    let file = await e.target.files[0];
    let Imageformdata = new FormData();
    Imageformdata.append("image", file);
    postImage("articles/image", Imageformdata)
      .then((result) => {
        setaddedArticle({ ...addedArticle, imagePath: result.data.imageUrl });
        setimgeLoader({ status: "done", text: "" });
      })
      .catch((err) => {
        console.log(err);
        setimgeLoader({
          status: "failed",
          text: "فشل رفع الصورة حاول مرة أخرى",
        });
      });
  };
  const handleSubmitselectedArticle = () => {
    setsubmitLoader({ status: "loading", text: "", buttonDisabled: true });
    myFeach(`articles/slider/${selectedArticle}`, "PUT")
      .then((result) => {
        setsubmitLoader({ status: "", text: "" });
        reloadarticleSlider();
        setsucsessMsg(true);
      })
      .catch((err) => {
        console.log(err);
        setsubmitLoader({
          status: "failed",
          // need update
          // text: JSON.parse(err.message).error,
          text: "فشل الإضافة حاول مرة أخرى",
        });
      });
  };
  const handleSubmitCreatedArticle = () => {
    if(!addedArticle.imagePath){
      setimgeLoader({status:"failed",text:"الصورة مطلوبة"})
      return
    }
    setsubmitLoader({ status: "loading", text: "" });
    myFeach(`articles/slider`, "POST", addedArticle, "authrized")
      .then((res) => {
        setsubmitLoader({ status: "done", text: "" });
        setimageErr(false)
        reloadarticleSlider();
        setsucsessMsg(true);
        closeModal();
      })
      .catch((err) => {
        setsubmitLoader({ status: "failed", text: "فشل إضافة الإعلان حاول مرة أخرى" });
        console.log("...............",err);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    if (addFromExist) {
      handleSubmitselectedArticle();
    } else {
      handleSubmitCreatedArticle();
    }
  };
  useEffect(() => {
    getArticlesData();
  }, []);
  return (
    <div className="modal_container">
      {sucsessMsg && (
        <SucsessMessageModal
          close={() => setsucsessMsg(false)}
          text={"تمت الإضافة بنجاح"}
        />
      )}
      <button onClick={closeModal} className="modal_bg"></button>
      <div className="small_modal_conrent">
        <button className="text_greay text-xl" onClick={closeModal}>
          X
        </button>
        <form
          onSubmit={handleSubmit}
          className="w-2/3 m-auto flex flex-col items-center"
        >
          <div>
            {articlesList.loader.status === "done" ? (
              <>
                <label className="block">اختيار من المقالات</label>
                <select
                  onChange={(e) => setselectedArticle(e.target.value)}
                  className={`w-60  h-8 ${
                    !addFromExist ? "_my_greay_border" : "my_border"
                  }`}
                  disabled={!addFromExist}
                >
                  {articlesList.data.map((elem, index) => (
                    <option key={index} value={elem.id}>
                      {elem.title}
                    </option>
                  ))}
                </select>
              </>
            ) : (
              <PageLoader loader={articlesList.loader} text={articlesList.loader} />
            )}
          </div>
          <h3 className="mt-3">أو</h3>
          <button
            type="button"
            onClick={() => setaddFromExist(!addFromExist)}
            className={` my_border p-1 my-4 button_hover ${
              !addFromExist ? "button_ative" : ""
            }`}
          >
            <h5 className="w-72 m-auto text-center">
              {addFromExist
                ? "الإعلان عن مقال سيكون متاح قريباً"
                : "إضافة من المقالات الحالية"}
            </h5>
          </button>
          <div className="w-full h-fit p-2 my_rounded dashed_border flex flex-col items-center">
            <div
              className="w-20 h-20 flex items-center justify-center imge_bg"
              style={{
                backgroundImage: `url(${
                  addedArticle.imagePath
                    ? addedArticle.imagePath
                    : " https://i.postimg.cc/3J8fqYnd/imge.png"
                })`,
              }}
            >
              {imgeLoader.status === "loading" && (
                <div className="loader"></div>
              )}
            </div>
            <input
              type="file"
              onChange={handleUplodeImage}
              className="input_file"
              ref={fileInputRef}
            />
            <h4>قم برفع صورة المقال الحد الأقصى 2MB</h4>
            <button
              disabled={addFromExist}
              type="button"
              onClick={handleButtonClick}
              className="bg_secondary my_rounded px-10 py-2 h4 h-11 flex items-center mx-4 mt-6"
            >
              <span>إرفاق</span>
              <RiAttachment2 color="white" />
            </button>
          </div>
          {imgeLoader.status === "failed" && (
            <h6 className="text-xs text-red-400 mt-2">{imgeLoader.text}</h6>
          )}
          <div className="mt-4">
            <label className="block ">اسم المقال</label>
            <input
              name="title"
              required={!addFromExist}
              onChange={(e) =>
                setaddedArticle({ ...addedArticle, title: e.target.value })
              }
              disabled={addFromExist}
              className={`h-8 w-60 ${
                addFromExist ? "_my_greay_border" : "my_border"
              } `}
            />
          </div>
          <div className="mt-4">
            <label className="block ">اسم المؤلف</label>
            <input
              required={!addFromExist}
              disabled={addFromExist}
              name="author"
              onChange={(e) =>
                setaddedArticle({ ...addedArticle, author: e.target.value })
              }
              className={`h-8 w-60 ${
                addFromExist ? "_my_greay_border" : "my_border"
              } `}
            />
          </div>
          <button
            disabled={submitLoader.buttonDisabled}
            type="submit "
            className="h-11 w-52 bg_secondary my_rounded mt-8 "
          >
            {submitLoader.status === "loading" ? (
              <div className="loader"></div>
            ) : (
              "حفظ"
            )}
          </button>
          {submitLoader.status === "failed" && (
            <h6 className="text-red-500 text-xs m-auto w-fit mt-1">
              {submitLoader.text}
            </h6>
          )}
        </form>
      </div>
    </div>
  );
}

const books = [
  { name: "الكتاب 1", _id: "00000" },
  { name: "الكتاب 1", _id: "00000" },
  { name: "الكتاب 1", _id: "00000" },
  { name: "الكتاب 1", _id: "00000" },
  { name: "الكتاب 1", _id: "00000" },
];
