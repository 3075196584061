import React, { useEffect, useState } from "react";
// data
import { myFeach } from "../../api";
// icons
import { BsSearch } from "react-icons/bs";
import { BiShow } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import { MdOutlineUploadFile, MdSimCardDownload } from "react-icons/md";
import { AiFillDelete } from "react-icons/ai";
// components
import PersonDetailesModal from "../data-base/PersonDetailesModal";
import AddPersonModall from "../timeline/AddPersonModall";
import CustomTable from "../reuseable/CustomTable";
import PageLoader from "../reuseable/PageLoader";
import Pagenation from "../reuseable/Pagination";
import Confirm from "../reuseable/Confirm";
import SucsessMessageModal from "../reuseable/SucsessMessageModal";
export default function DataBase() {
  const [peoples, setpeoples] = useState([]);
  const [activeFilter, setactiveFilter] = useState(filters[0].key);
  const [searchKeys, setsearchKeys] = useState({
    name: "",
    year: "",
    birthData: "",
    deathDate: "",
  });
  const [page, setpage] = useState(1);
  const [lastPage, setlastPage] = useState();
  const [loader, setloader] = useState({
    status: "loading",
    text: "يتم تحميل بيانات الأشخاص",
  });
  const [addPersonModal, setaddPersonModal] = useState({
    status: false,
    formtype: "الهيئة الادارية",
  });
  const [openDetailesModal, setopenDetailesModal] = useState({
    status: false,
    personId: "",
  });
  const [openConfirmModal, setopenConfirmModal] = useState({
    status: false,
    text: "",
    id: "",
  });
  const [confirmLoader, setconfirmLoader] = useState({
    status: "confirm",
    text: "",
  });
  const [activePerson, setactivePerson] = useState("");
  const [EditPersonId, setEditPersonId] = useState("");
  const detailesModalHeader = [
    { title: "الاسم", key: "name", class: "h-11" },
    { title: "نبذة", key: "detailes", class: "" },
    {
      title: activeFilter === "الخريجون" ? "سنة التخرج" : "سنوات التعين",
      key: "years",
      class: "h-11",
    },
    { title: "سنة الميلاد", key: "birth_date", class: "h-11" },
    { title: "سنة الوفاة", key: "death_date", class: "h-11" },
  ];
  // handle change filter
  const handleFilter = (key, role) => {
    setactiveFilter(key);
    setaddPersonModal({ ...addPersonModal, formtype: role });
  };
  // handle get people based on search key
  const getPeoples = () => {
    setloader({ status: "loading", text: "يتم تحميل بيانات الأشخاص" });
    let url = myFeach(
      `people?degree=${activeFilter}${
        searchKeys.fullName ? `&fullName=${searchKeys.fullName}` : ""
      }${searchKeys.birthData ? `&yearOfBirth=${searchKeys.birthData}` : ""}${
        searchKeys.deathDate ? `&yearOfDeath=${searchKeys.deathDate}` : ""
      }&isDeleted=true&page=${page}&limit=10`,
      "GET"
    )
      .then(({ data }) => {
        if (data.people.length === 0) {
          setloader({ status: "failed", text: "لا يوجد نتائج" });
        } else {
          setloader({ status: "done", text: "" });
          setpeoples(data.people);
          setlastPage(data.numberOfPages);
        }
      })
      .catch((err) => {
        console.log("error", err);
        setloader({ status: "failed", text: "فشل تحميل بيانات الأشخاص" });
      });
  };
  // handle Delete person function
  const deletePerson = (id) => {
    console.log("hi from delete", activePerson);
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`/people/${id}`, "DELETE")
      .then((result) => {
        setconfirmLoader({ status: "done", text: "" });
        setopenConfirmModal({
          status: "sucsess",
          text: "تم مسح الشخص والكتب والمقالات المتعلقة به بنجاح",
        });
        getPeoples();
      })
      .catch((err) => {
        console.log("err", err);
        // need update
        // console.log("errour",JSON.parse(err.message))
        // let errMsg = JSON.parse(err.message).errors[0].msg;
        setconfirmLoader({ status: "failed", text: "فشل حذف الشخص" });
      });
  };
  // handle archive fun
  const archiveFun = (id) => {
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`people/archive/${id}`, "PUT")
      .then((result) => {
        setconfirmLoader({ status: "done", text: "" });
        setopenConfirmModal({
          status: "sucsess",
          text: "تم إلغاء أرشفة الشخص  بنجاح",
        });
        getPeoples();
      })
      .catch((err) => {
        // need update
        // let errMsg = JSON.parse(err.message).errors[0].msg;
        // console.log("errour",JSON.parse(err.message))
        setconfirmLoader({ status: "failed", text: "فشل إلغاءأرشفة الشخص" });
      });
  };
  // handle cut string
  function truncateString(string) {
    if (string.length > 50) {
      return string.substring(0, 50) + "...";
    }
    return string;
  }
  const handleSearch = (e) => {
    e.preventDefault();
    getPeoples();
  };
  useEffect(() => {
    if (activeFilter === "الهيئة الادارية") {
      setaddPersonModal({ ...addPersonModal, formtype: "board" });
    } else if (activeFilter === "هيئة التدريس") {
      setaddPersonModal({ ...addPersonModal, formtype: "teachers" });
    } else if (activeFilter === "الخريجون") {
      setaddPersonModal({ ...addPersonModal, formtype: "graduates" });
    }
  }, [activeFilter]);
  useEffect(() => {
    getPeoples();
  }, [activeFilter, page]);
  return (
    <>
      <div className=" py-4  database_page ">
        {/* modales */}
        {openDetailesModal.status === true && (
          <PersonDetailesModal
            personDetailesModal={openDetailesModal}
            setopenDetailesModal={setopenDetailesModal}
            headers={detailesModalHeader}
            // detailes={person_detailes}
            id={activePerson}
          />
        )}
        {addPersonModal.status === true && (
          <AddPersonModall
            regeAllPeople={getPeoples}
            closeModal={() => {
              setaddPersonModal({
                ...addPersonModal,
                status: false,
              });
              setEditPersonId();
            }}
            formType={addPersonModal.formtype}
            EditPersonId={EditPersonId}
          />
        )}
        {openConfirmModal.status === "confirmDelete" && (
          <Confirm
            close={() => setopenConfirmModal({ status: false, text: "" })}
            text={openConfirmModal.text}
            confirmFun={() => deletePerson(activePerson)}
            loader={confirmLoader}
          />
        )}
        {openConfirmModal.status === "confirmArchife" && (
          <Confirm
            close={() => {
              setopenConfirmModal({ status: false, text: "" });
            }}
            text={openConfirmModal.text}
            confirmFun={() => archiveFun(activePerson)}
            loader={confirmLoader}
          />
        )}
        {openConfirmModal.status === "sucsess" && (
          <SucsessMessageModal
            close={() => setopenConfirmModal({ status: false, text: "" })}
            text={openConfirmModal.text}
          />
        )}
        {/* end modales */}
        <div className="flex justify-between">
          <ul className="filters ">
            {filters.map((elem, index) => (
              <li
                // onClick={() => setactiveFilter(elem.key)}
                onClick={() => handleFilter(elem.key, elem.role)}
                className={`${
                  activeFilter === elem.key ? "active_filter" : ""
                }`}
                key={index}
              >
                {elem.text}
              </li>
            ))}
          </ul>
        </div>
        <form onSubmit={handleSearch} className="search_bar flex mt-5">
          <div className="flex flex-col ml-4">
            <label>بحث بالاسم</label>
            <input
              onChange={(e) =>
                setsearchKeys({
                  ...searchKeys,
                  fullName: e.target.value,
                })
              }
              name="name"
              className="_my_greay_border px-2 py-2 h-8 h5"
            />
          </div>
          <div className="flex flex-col ml-4">
            <label>سنة الميلاد</label>
            <input
              type="number"
              onChange={(e) =>
                setsearchKeys({
                  ...searchKeys,
                  fullName: e.target.value,
                })
              }
              name="birthData"
              className="_my_greay_border px-2 py-2 h-8 "
              placeholder="1986"
              min={1000}
              max={9999}
            />
          </div>
          <div className="flex flex-col ml-4">
            <label>سنة الوفاة</label>
            <input
              type="number"
              onChange={(e) =>
                setsearchKeys({
                  ...searchKeys,
                  fullName: e.target.value,
                })
              }
              name="deathDate"
              className="_my_greay_border px-2 py-2 h-8 "
              placeholder="1995"
              min={1000}
              max={9999}
            />
          </div>
          <button
            type="submit"
            className="my_rounded h-8 py-2 px-2 mt-auto bg_secondary"
          >
            <BsSearch color="white" />
          </button>
        </form>
        {loader?.status !== "done" ? (
          <PageLoader loader={loader} text={loader.text} />
        ) : (
          <>
            <CustomTable header={tableHeader} tableRows={peoples}>
              {peoples.map((elem, index) => (
                <tr key={index} className="border_buttom_greay">
                  <td className="flex items-center">
                    <div
                      className="circle_rounded ml-3 block w-14 h-14 imge_bg"
                      style={{
                        backgroundImage: `url(${
                          elem.profileImage
                            ? elem.profileImage
                            : "https://i.postimg.cc/zvQT2nJ6/Avatar.png"
                        })`,
                      }}
                    ></div>
                    <span>
                      {elem.firstName} {elem.lastName}
                    </span>
                  </td>
                  <td>{elem?.degree}</td>
                  <td className="years_cell">
                    {elem.years.length !== 0 ? (
                      <>
                        <span className="first_year">
                          {elem?.years[0].year}
                        </span>
                        <span className="all_years flex flex-col ">
                          {elem.years.map((elem, index) => (
                            <span  className={`block ${elem.isDeleted&&"text-red-400"} flex items-center`} key={index}>
                              {elem.year}
                              {elem.isDeleted&&<MdSimCardDownload/>}
                            </span>
                          ))}
                        </span>
                      </>
                    ) : (
                      "لا يوجد سنة"
                    )}
                  </td>
                  <td className="w-1/3">{truncateString(elem?.brief)}</td>
                  <td>
                    <button
                      className="m-1"
                      onClick={() => {
                        setopenDetailesModal({
                          status: true,
                          personId: elem._id,
                        });
                        setactivePerson(elem._id);
                      }}
                    >
                      <BiShow color="#D59A2C" size={25} />
                    </button>
                    <button
                      className="m-1"
                      onClick={() => {
                        setaddPersonModal({ ...addPersonModal, status: true });
                        setEditPersonId(elem._id);
                      }}
                    >
                      <FiEdit2 color="#4ACA5B" size={25} />
                    </button>
                    <button
                      className="m-1"
                      onClick={() => {
                        setconfirmLoader({ status: "confirm", text: "" });
                        setopenConfirmModal({
                          id: elem._id,
                          status: "confirmArchife",
                          text: "أنت على وشك إلغاء أرشفة هذا الشخص، هل أنت متأكد؟",
                        });
                        setactivePerson(elem._id);
                      }}
                    >
                      <div className="">
                        <MdOutlineUploadFile color="black" size={25} />
                      </div>
                    </button>
                    <button
                      className="m-1"
                      onClick={() => {
                        setconfirmLoader({ status: "confirm", text: "" });
                        setopenConfirmModal({
                          id: elem._id,
                          status: "confirmDelete",
                          text: "أنت على وشك حذف هذا الشخص، هل أنت متأكد؟",
                        });
                        setactivePerson(elem._id);
                      }}
                    >
                      <AiFillDelete color="#F61C0D" size={25} />
                    </button>
                  </td>
                </tr>
              ))}
            </CustomTable>
            <Pagenation
              setpage={setpage}
              page={page}
              numperOfPages={lastPage}
            />
          </>
        )}
      </div>
    </>
  );
}

const filters = [
  // { text: "الجميع", key: "" },
  {
    text: "عميد",
    key: "العمداء",
    role: "الهيئة الادارية",
    yearsKey: "سنوات التعين",
  },
  {
    text: "وكيل",
    key: "الوكلاء",
    role: "الهيئة الادارية",
    yearsKey: "سنوات التعين",
  },
  {
    text: "مدرس",
    key: "المدرسون",
    role: "أعضاء هيئة التدريس",
    yearsKey: "سنوات التدريس",
  },
  { text: "خريج", key: "الخريجون", role: "الخريجون", yearsKey: " سنةالتخرج" },
  // { text: "المدرسون", key: "4" },
  // { text: "الخريجون", key: "5" },
  // { text: "الشعراء", key: "6" },
  // { text: "الكتاب", key: "7" },
];
const tableHeader = [
  { title: "الاسم", key: "name", size: "sm" },
  { title: "المرتبة", key: "position", size: "sm" },
  { title: "العام", key: "year", size: "sm" },
  { title: "نبذة", key: "detailes", size: "w-1/3" },
  { title: "", key: "controls", size: "sm" },
];

