import React from "react";

export default function EditIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="33"
      height="34"
      viewBox="0 0 33 34"
      fill="none"
    >
      <path
        d="M25.2087 7.60043C22.7474 5.05043 20.3412 4.99377 17.8112 7.60043L16.2574 9.1871C16.1199 9.32877 16.0787 9.5271 16.1337 9.71127C17.0962 13.1821 19.7912 15.9588 23.1599 16.9504C23.2012 16.9646 23.2699 16.9646 23.3112 16.9646C23.4487 16.9646 23.5862 16.9079 23.6824 16.8088L25.2087 15.2221C26.4599 13.9329 27.0787 12.7004 27.0787 11.4396C27.0787 10.1646 26.4599 8.90377 25.2087 7.60043ZM20.8637 18.1433C20.4924 17.9591 20.1349 17.7749 19.8049 17.5624C19.5299 17.3924 19.2549 17.2083 18.9937 17.0099C18.7737 16.8683 18.5262 16.6558 18.2787 16.4433C18.2512 16.4291 18.1687 16.3583 18.0587 16.2449C17.6324 15.8908 17.1787 15.4091 16.7524 14.8849C16.7249 14.8566 16.6424 14.7716 16.5737 14.6441C16.4362 14.4883 16.2299 14.2191 16.0512 13.9216C15.8999 13.7233 15.7212 13.4399 15.5562 13.1424C15.3499 12.7883 15.1712 12.4341 15.0062 12.0658C14.8274 11.6691 14.6899 11.3008 14.5662 10.9466L7.16867 18.5683C6.68742 19.0641 6.21992 19.9991 6.12367 20.6933L5.53242 24.9149C5.40867 25.8074 5.64242 26.6433 6.17867 27.1958C6.63242 27.6633 7.25117 27.9041 7.93867 27.9041C8.08992 27.9041 8.24117 27.8899 8.39242 27.8758L12.4762 27.2808C13.1499 27.1816 14.0574 26.7141 14.5387 26.2041L21.9362 18.5824C21.5924 18.4691 21.2487 18.3133 20.8637 18.1433Z"
        fill="#4ACA5B"
      />
    </svg>
  );
}
