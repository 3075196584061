import React from "react";

export default function AudioIcon({ pathname }) {
  return (
    <>
      {pathname !== "/audios" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            opacity="0.4"
            d="M19.1197 9.11999C18.7297 9.11999 18.4197 9.42999 18.4197 9.81999V11.4C18.4197 14.94 15.5397 17.82 11.9997 17.82C8.45969 17.82 5.57969 14.94 5.57969 11.4V9.80999C5.57969 9.41999 5.26969 9.10999 4.87969 9.10999C4.48969 9.10999 4.17969 9.41999 4.17969 9.80999V11.39C4.17969 15.46 7.30969 18.81 11.2997 19.17V21.3C11.2997 21.69 11.6097 22 11.9997 22C12.3897 22 12.6997 21.69 12.6997 21.3V19.17C16.6797 18.82 19.8197 15.46 19.8197 11.39V9.80999C19.8153 9.62665 19.7398 9.45219 19.6092 9.32345C19.4786 9.1947 19.3031 9.12175 19.1197 9.11999Z"
            fill="white"
          />
          <path
            d="M11.9981 2C9.55813 2 7.57812 3.98 7.57812 6.42V11.54C7.57812 13.98 9.55813 15.96 11.9981 15.96C14.4381 15.96 16.4181 13.98 16.4181 11.54V6.42C16.4181 3.98 14.4381 2 11.9981 2ZM13.3081 8.95C13.2381 9.21 13.0081 9.38 12.7481 9.38C12.6981 9.38 12.6481 9.37 12.5981 9.36C12.2081 9.25 11.7981 9.25 11.4081 9.36C11.0881 9.45 10.7781 9.26 10.6981 8.95C10.6081 8.64 10.7981 8.32 11.1081 8.24C11.6981 8.08 12.3181 8.08 12.9081 8.24C13.2081 8.32 13.3881 8.64 13.3081 8.95ZM13.8381 7.01C13.7969 7.12235 13.7218 7.21918 13.6234 7.28721C13.5249 7.35524 13.4078 7.39115 13.2881 7.39C13.2181 7.39 13.1581 7.38 13.0881 7.36C12.3881 7.1 11.6081 7.1 10.9081 7.36C10.7622 7.41174 10.6018 7.40404 10.4615 7.33857C10.3212 7.27309 10.2122 7.15508 10.1581 7.01C10.0481 6.71 10.2081 6.37 10.5081 6.27C11.4706 5.92 12.5256 5.92 13.4881 6.27C13.7881 6.38 13.9481 6.71 13.8381 7.01Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M19.1197 9.11999C18.7297 9.11999 18.4197 9.42999 18.4197 9.81999V11.4C18.4197 14.94 15.5397 17.82 11.9997 17.82C8.45969 17.82 5.57969 14.94 5.57969 11.4V9.80999C5.57969 9.41999 5.26969 9.10999 4.87969 9.10999C4.48969 9.10999 4.17969 9.41999 4.17969 9.80999V11.39C4.17969 15.46 7.30969 18.81 11.2997 19.17V21.3C11.2997 21.69 11.6097 22 11.9997 22C12.3897 22 12.6997 21.69 12.6997 21.3V19.17C16.6797 18.82 19.8197 15.46 19.8197 11.39V9.80999C19.8153 9.62665 19.7398 9.45219 19.6092 9.32345C19.4786 9.1947 19.3031 9.12175 19.1197 9.11999Z"
            fill="white"
          />
          <path
            d="M11.9981 2C9.55813 2 7.57812 3.98 7.57812 6.42V11.54C7.57812 13.98 9.55813 15.96 11.9981 15.96C14.4381 15.96 16.4181 13.98 16.4181 11.54V6.42C16.4181 3.98 14.4381 2 11.9981 2ZM13.3081 8.95C13.2381 9.21 13.0081 9.38 12.7481 9.38C12.6981 9.38 12.6481 9.37 12.5981 9.36C12.2081 9.25 11.7981 9.25 11.4081 9.36C11.0881 9.45 10.7781 9.26 10.6981 8.95C10.6081 8.64 10.7981 8.32 11.1081 8.24C11.6981 8.08 12.3181 8.08 12.9081 8.24C13.2081 8.32 13.3881 8.64 13.3081 8.95ZM13.8381 7.01C13.7969 7.12235 13.7218 7.21918 13.6234 7.28721C13.5249 7.35524 13.4078 7.39115 13.2881 7.39C13.2181 7.39 13.1581 7.38 13.0881 7.36C12.3881 7.1 11.6081 7.1 10.9081 7.36C10.7622 7.41174 10.6018 7.40404 10.4615 7.33857C10.3212 7.27309 10.2122 7.15508 10.1581 7.01C10.0481 6.71 10.2081 6.37 10.5081 6.27C11.4706 5.92 12.5256 5.92 13.4881 6.27C13.7881 6.38 13.9481 6.71 13.8381 7.01Z"
            fill="#D59A2C"
          />
        </svg>
      )}
    </>
  );
}
