import React, { useEffect, useRef } from "react";
import { AiFillDelete } from "react-icons/ai";
import ArrowLeft from "../reuseable/svg-icons/ArrowLeft";
import YoutubeIcon from "../reuseable/svg-icons/YoutubeIcon";
import ArrowRight from "../reuseable/svg-icons/ArrowRight";

export default function ArticlesSlider({
  data,
  sliderType,
  openAddCoverModal,
  handleDelete,
}) {
  const sliderRef = useRef(null);
  const handleScroll = (direction) => {
    const sliderElement = sliderRef.current;
    if (sliderElement) {
      const scrollAmount =
        direction === "left"
          ? -sliderElement.offsetWidth
          : sliderElement.offsetWidth;

      sliderElement.scrollBy({
        left: scrollAmount,
        behavior: "smooth",
      });
    }
  };
  return (
    <section className="library_slider">
      <div className="arr_right" onClick={() => handleScroll("right")}>
        <ArrowRight />
      </div>
      <div className="container" ref={sliderRef}>
        {data.map((elem, index) => (
          <div
            className="slider_elem"
            style={{
              backgroundImage: `url(https://i.postimg.cc/MTMYsrn3/Books-slider-BG.png)`,
            }}
            key={index}
          >
            <div className="content">
              <h2>{elem.title}</h2>
              <h3>{elem.author.fullName}</h3>
              <div className="h3">{elem.publishedAt}</div>
            </div>
            <div className="flex items-center mt-auto mb-3 btns">
              <button
                className="my_rounded  py-2 px-14 bg-black mx-2 text-3xl text-green-400"
                onClick={openAddCoverModal}
              >
                +
              </button>
              <button
                onClick={() => handleDelete(elem.id)}
                className="my_rounded text_white p-2 bg-black mx-2"
              >
                <AiFillDelete size={35} color="red" />
              </button>
            </div>
            <div className="book_imge_container">
              {!elem?.inList && <h4>قريباُ</h4>}
              {elem.imagePath && <img src={elem.imagePath} />}
            </div>
          </div>
        ))}
      </div>
      <div className="arr_left" onClick={() => handleScroll("left")}>
        <ArrowLeft />
      </div>
    </section>
  );
}
