import React, { Children } from "react";

export default function CustomTable({ children, header }) {
  return (
    <table className="my_rounded">
      <thead>
        <tr>
          {header.map((elem, index) => (
            <td key={index} className={elem.size}>
              {elem.title}
            </td>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </table>
  );
}
