import React from "react";

export default function PhotoIcon({ pathname }) {
  return (
    <>
      {pathname == "/albums" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M22.0192 16.82L18.8892 9.50002C18.3192 8.16002 17.4692 7.40002 16.4992 7.35002C15.5392 7.30002 14.6092 7.97002 13.8992 9.25002L11.9992 12.66C11.5992 13.38 11.0292 13.81 10.4092 13.86C9.77916 13.92 9.14916 13.59 8.63916 12.94L8.41916 12.66C7.70916 11.77 6.82916 11.34 5.92916 11.43C5.02916 11.52 4.25916 12.14 3.74916 13.15L2.01916 16.6C1.39916 17.85 1.45916 19.3 2.18916 20.48C2.91916 21.66 4.18916 22.37 5.57916 22.37H18.3392C19.6792 22.37 20.9292 21.7 21.6692 20.58C22.4292 19.46 22.5492 18.05 22.0192 16.82Z"
            fill="#D59A2C"
          />
          <path
            d="M6.96984 8.38097C7.86628 8.38097 8.72599 8.02487 9.35986 7.39099C9.99374 6.75712 10.3498 5.8974 10.3498 5.00097C10.3498 4.10454 9.99374 3.24482 9.35986 2.61095C8.72599 1.97708 7.86628 1.62097 6.96984 1.62097C6.07341 1.62097 5.2137 1.97708 4.57982 2.61095C3.94595 3.24482 3.58984 4.10454 3.58984 5.00097C3.58984 5.8974 3.94595 6.75712 4.57982 7.39099C5.2137 8.02487 6.07341 8.38097 6.96984 8.38097Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M22.0192 16.82L18.8892 9.50002C18.3192 8.16002 17.4692 7.40002 16.4992 7.35002C15.5392 7.30002 14.6092 7.97002 13.8992 9.25002L11.9992 12.66C11.5992 13.38 11.0292 13.81 10.4092 13.86C9.77916 13.92 9.14916 13.59 8.63916 12.94L8.41916 12.66C7.70916 11.77 6.82916 11.34 5.92916 11.43C5.02916 11.52 4.25916 12.14 3.74916 13.15L2.01916 16.6C1.39916 17.85 1.45916 19.3 2.18916 20.48C2.91916 21.66 4.18916 22.37 5.57916 22.37H18.3392C19.6792 22.37 20.9292 21.7 21.6692 20.58C22.4292 19.46 22.5492 18.05 22.0192 16.82Z"
            fill="white"
          />
          <path
            opacity="0.4"
            d="M6.96984 8.38097C7.86628 8.38097 8.72599 8.02487 9.35986 7.39099C9.99374 6.75712 10.3498 5.8974 10.3498 5.00097C10.3498 4.10454 9.99374 3.24482 9.35986 2.61095C8.72599 1.97708 7.86628 1.62097 6.96984 1.62097C6.07341 1.62097 5.2137 1.97708 4.57982 2.61095C3.94595 3.24482 3.58984 4.10454 3.58984 5.00097C3.58984 5.8974 3.94595 6.75712 4.57982 7.39099C5.2137 8.02487 6.07341 8.38097 6.96984 8.38097Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
}
