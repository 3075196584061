import React, { useEffect } from "react";

export default function CustomInput({
  handleOnchange,
  width,
  label,
  name="",
  errours,
  type="text",
  value="",
  langoge="",
  min="",
  max=""
}) {

  return (
    <div className="form_row flex w-full justify-between mt-4 ">
      <div className={`${width} flex flex-col`}>
        <label>{label}</label>
        <input
          onChange={(e)=>handleOnchange(e)}
          name={name}
          className={`my_rounded _my_greay_border w-full h-10 px-1  ${langoge==="english"?"text_english":""}`}
          type={type}
          value={value}
          min={min}
          max={max}
        />
        {errours[name] && (
          <h4 className="w-full text-red-400 text-xs mt-1">{errours[name]}</h4>
        )}
      </div>
    </div>
  );
}
