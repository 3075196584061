import React, { useEffect, useState } from "react";
import logo from "../assets/logo.svg";
import { myFeach } from "../api";
import Cookies from "js-cookie";
import { useNavigate } from "react-router-dom";
export default function Login() {
  const navigate = useNavigate();
  const [formData, setformData] = useState({ email: "", password: "" });
  const [loader, setloader] = useState(false);
  const [errours, seterrours] = useState({ status: false, text: "" });
  // handle input on change
  const handleOnchange = (e) => {
    setformData({ ...formData, [e.target.name]: e.target.value });
  };
  // handle submit form
  const handleSubmit = (e) => {
    e.preventDefault();
    setloader(true);
    seterrours({ status: false });
    myFeach("admin/login","POST",formData)
      .then(({data}) => {
        Cookies.set("token", data.token, { expires: 360 });
        setloader(false);
          document.location.href="/";
      })
      .catch((error) => {
        
        seterrours({ status: true, text: "فشل تسجل الدخول" });
        setloader(false);
      });
  };
  useEffect(() => {
    document.getElementById("side_bar").style.display = "none";
    return () => {
      document.getElementById("side_bar").style.display = "block";
    };
  }, []);
  return (
    <div className="bg_primary h-screen w-full flex justify-center items-center ">
      <form
        onSubmit={handleSubmit}
        className="w-96 h-fit my_rounded bg-white flex flex-col items-center py-8 px-5 login__form"
      >
        <figure className="border_buttom w-full">
          <img className="block m-auto" src={logo} />
        </figure>
        <div className="flex flex-col mt-12 w-full">
          <label className="h4 text-black">اسم المستخدم</label>
          <input
            type="email"
            required
            onChange={handleOnchange}
            name="email"
            className="w-full _my_greay_border text-black px-2 py-1 mt-2 text_english"
          />
        </div>
        <div className="flex flex-col mt-4 w-full">
          <label className="h4 text-black">كلمة المرور</label>
          <input
            required
            type="password"
            onChange={handleOnchange}
            name="password"
            className="w-full _my_greay_border text-black px-2 py-1 mt-2 text_english"
          />
        </div>
        <button className="w-full h-8 bg_secondary my_rounded mt-8 py-1" disabled={loader}>
          {loader ? <div className="loader"></div> : "تسجيل الدخول"}
        </button>
        <h6 className="mx-auto mt-1 text-red-600 text-xs ">{errours.text}</h6>
      </form>
    </div>
  );
}
