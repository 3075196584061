import React from "react";
import CustomInput from "../reuseable/CustomInput";
import { useState } from "react";
import { myFeach } from "../../api";
import SucsessMessageModal from "../reuseable/SucsessMessageModal";

export default function AddAlbumModal({ closeModal, width = "", regetAlbums }) {
  const [albUmName, setalbUmName] = useState("");
  const [loader, setloader] = useState({ status: "", text: "" });
  const [sucsessModal, setsucsessModal] = useState({ status: false, text: "" });
  const inputonChange = (e) => {
    setalbUmName(e.target.value);
  };
  const addNewAlbum = () => {
    setloader({ status: "loading", text: "" });
    myFeach(`albums`, "POST", { name: albUmName }, "authrized")
      .then((result) => {
        setloader({ status: "done", text: "" });
        regetAlbums();
        // console.log(result);
        setsucsessModal({ status: true, text: "تم إضافة الألبوم بنجاح" });
        setalbUmName("");
      })
      .catch((err) => {
        setloader({ status: "failed", text: " فشل إضافة الألبوم " });
        console.log(err);
      });
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    addNewAlbum();
  };
  return (
    <div className="modal_container z_index_100 pt-20 ">
      {sucsessModal.status === true && (
        <SucsessMessageModal
          close={() => setsucsessModal({ status: false, text: "" })}
          text={sucsessModal.text}
        />
      )}
      <button className="modal_bg" onClick={closeModal}></button>

      <div
        className="modal_content p-0 relative mt-56    "
        style={{ width: width, height: "200px" }}
      >
        <button className="h3 top-1 right-3 absolute" onClick={closeModal}>
          x
        </button>
        <form onSubmit={handleSubmit}>
          <div className="flex flex-col w-full">
            <label className="">عنوان الألبوم</label>
            <input
              value={albUmName}
              onChange={inputonChange}
              className="my_rounded _my_greay_border w-full h-10 px-1"
            />
          </div>
          {/* <CustomInput
            width="w-full"
            label="اسم الألبوم"
            handleOnchange={(e) => inputonChange(e)}
          /> */}
          <button
            disabled={loader.status === "loading"}
            className="w-full h-10 bg_secondary text_white h5 my_rounded mt-8 flex justify-center items-center "
          >
            {loader.status === "loading" ? (
              <div className="loader"></div>
            ) : (
              "حفظ"
            )}
          </button>
          {loader.status === "failed" && (
            <h4 className="text-red-400 text-xs mt-2">{loader.text}</h4>
          )}
        </form>
      </div>
    </div>
  );
}
