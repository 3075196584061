import React, { useEffect, useState } from "react";
// data
// components
import ArticlesSlider from "../components/articles/ArticlesSlider";
import CustomTable from "../components/reuseable/CustomTable";
// icons
import { BiShow } from "react-icons/bi";
import { FiEdit2 } from "react-icons/fi";
import { AiFillDelete } from "react-icons/ai";
import { BsSearch } from "react-icons/bs";
import { myFeach } from "../api";
import Pagenation from "../components/reuseable/Pagination";
import PageLoader from "../components/reuseable/PageLoader";
import Confirm from "../components/reuseable/Confirm";
import SucsessMessageModal from "../components/reuseable/SucsessMessageModal";
import AddArticleCoverModal from "../components/articles/AddArticleCoverModal";
import ArticleDetailesModal from "../components/articles/ArticleDetailesModal";
import AddArticleModal from "../components/articles/AddArticleModal";
export default function Books() {
  const [modal, setmodal] = useState({
    status: false,
    modalType: "",
    id: "",
  });
  const [activeArticle, setactiveArticle] = useState("");
  const [activeSlider, setactiveSlider] = useState();
  const [openConfirmModal, setopenConfirmModal] = useState({
    status: false,
    text: "",
    id: "",
    isCover: "",
  });
  const [confirmLoader, setconfirmLoader] = useState({
    status: "confirm",
    text: "",
  });
  const [slider, setslider] = useState({
    data: [],
    loader: { status: "loading", text: "جاري تحميل إعلانات المقالات" },
  });
  const [articlesList, setarticlesList] = useState({
    data: [],
    loader: { status: "loading", text: "يتم تحميل المقالات" },
    numberOfPages: "",
  });
  const [searchKey, setsearchKey] = useState("");
  const [page, setpage] = useState(1);
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
  };
  // check modal status
  const checkModal = (modalName) => {
    let result = false;
    if (modal.status === true && modal.modalType === modalName) {
      result = true;
    }
    return result;
  };
  const getSliderData = () => {
    if (slider.loader.status !== "loading") {
      setslider({
        ...slider,
        loader: { status: "loading", text: "جاري تحميل الإعلانات" },
      });
    }
    myFeach("articles/slider", "GET")
      .then(({ data }) => {
        if (data?.articles?.length == 0) {
          setslider({
            ...slider,
            data: data.articles,
            loader: {
              status: "failed",
              text: "لا يوجد أي إعلانات عن مقالات لعرضها",
            },
          });
        } else {
          setslider({
            ...slider,
            data: data.articles,
            loader: { status: "done", text: "" },
          });
        }
      })
      .catch((err) => {
        console.log("err....", err);
        setslider({
          ...slider,
          loader: { status: "failed", text: "فشل تحميل إعلانات المقالات" },
        });
      });
  };
  const getAllArticles = () => {
    if (articlesList.loader.status !== "loading") {
      setarticlesList({
        ...articlesList,
        loader: { status: "loading", text: "جاري تحميل المقالات" },
      });
    }
    myFeach(`articles?title=${searchKey} &page=${page}&limit=10`, "GET")
      .then(({ data }) => {
        if (data.articles.length == 0) {
          setarticlesList({
            ...articlesList,
            data: [],
            loader: { status: "failed", text: "لا يوجد مقالات لعرضها" },
          });
        } else {
          setarticlesList({
            data: data.articles,
            loader: { status: "done", text: "" },
            numberOfPages: data.numberOfPages,
          });
        }
      })
      .catch((err) => {
        console.log("errp...", err);
        setarticlesList({
          ...articlesList,
          loader: { status: "failed", text: "فشل تحميل المقالات" },
        });
      });
  };
  // handleD delete book
  const deleteArticle = (id) => {
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`articles/${id}`, "DELETE")
      .then((result) => {
        setconfirmLoader({ status: "done", text: "" });
        setopenConfirmModal({
          status: "sucsess",
          text: "تم حذف المقالة بنجاح",
        });
        getAllArticles();
      })
      .catch((err) => {
        console.log("err", err);
        // need update
        // console.log("errour",JSON.parse(err.message))
        // let errMsg = JSON.parse(err.message).errors[0].msg;
        setconfirmLoader({ status: "failed", text: "فشل حذف المقالة" });
      });
  };
  // handleD delete slider
  const deleteSlider = (id) => {
    setconfirmLoader({ status: "loading", text: "" });
    myFeach(`articles/slider/${id}`, "DELETE")
      .then((result) => {
        console.log(result)
        setconfirmLoader({ status: "done", text: "" });
        setopenConfirmModal({
          status: "sucsess",
          text: "تم مسح إعلان المقال بنجاح",
        });
        getSliderData();
      })
      .catch((err) => {
        console.log("err", err);
        // need update
        // console.log("errour",JSON.parse(err.message))
        // let errMsg = JSON.parse(err.message).errors[0].msg;
        setconfirmLoader({ status: "failed", text: "فشل حذف إعلان المقال" });
      });
  };
  useEffect(() => {
    getSliderData();
  }, []);
  useEffect(() => {
    getAllArticles();
  }, [page]);
  return (
    <div className="page_container books_page_container">
      {/* modales */}
      {checkModal("detailes") && (
        <ArticleDetailesModal
          id={modal.id}
          setopenDetailesModal={setmodal}
        />
      )}
      {checkModal("cover") && (
        <AddArticleCoverModal
          closeModal={() => setmodal({ ...modal, status: false })}
          coverType="article"
          title="مقال"
          reloadarticleSlider={getSliderData}
        />
      )}
      {checkModal("book") && (
        <AddArticleModal
          closeModal={() => setmodal({ ...modal, status: false })}
          regetarticles={getAllArticles}
          isEdit={modal.edit}
          articleId={activeArticle}
        />
      )}
      {openConfirmModal.status === "confirmDelete" && (
        <Confirm
          close={() => {
            setopenConfirmModal({ status: false, text: "" });
            setconfirmLoader({ status: "confirm", text: "" });
          }}
          text={openConfirmModal.text}
          confirmFun={
            openConfirmModal.isCover
              ? () => deleteSlider(activeSlider)
              : () => deleteArticle(activeArticle)
          }
          loader={confirmLoader}
        />
      )}
      {openConfirmModal.status === "sucsess" && (
        <SucsessMessageModal
          close={() => setopenConfirmModal({ status: false, text: "" })}
          text={openConfirmModal.text}
        />
      )}
      {/* end madals */}
      {slider.loader.status === "done" ? (
        <ArticlesSlider
          handleDelete={(id) => {
            setopenConfirmModal({
              id: id,
              status: "confirmDelete",
              text: "أنت على وشك حذف هذا الإعلان هل أنت متأكد؟",
              isCover:true
            });
            setactiveSlider(id);
          }}
          openAddCoverModal={() =>
            setmodal({ status: true, modalType: "cover" })
          }
          data={slider.data}
          sliderType={"books"}
        />
      ) : (
        <div className="items-center flex flex-col ">
          <PageLoader loader={slider.loader} text={slider.loader.text} />
          {slider.loader.text === "لا يوجد أي إعلانات عن مقالات لعرضها" && (
            <button
              className="bg_secondary my_rounded px-6 py-2 mt-2"
              onClick={() => setmodal({ status: true, modalType: "cover" })}
            >
              أضافة إعلان +
            </button>
          )}
        </div>
      )}
      <div className="flex justify-between items-center mt-8">
        <form
          onSubmit={(e) => {
            e.preventDefault();
            getAllArticles();
          }}
          className="search flex items-center"
        >
          <input
            placeholder="البحث بعوان المقال"
            onChange={(e) => setsearchKey(e.target.value)}
            className="_my_greay_border px-3 py-1 text-sm h-10"
            type="text"
          />
          <button className="my_rounded h-10 py-2 px-2  bg_secondary mx-2 ">
            <BsSearch color="white" size={25} />
          </button>
        </form>
        <button
          className="bg_secondary my_rounded px-6 py-2"
          onClick={() =>
            setmodal({ modalType: "book", status: true, edit: false })
          }
        >
          أضافة مقال +
        </button>
      </div>
      {articlesList.loader.status === "done" ? (
        <>
          <CustomTable header={tableHeader}>
            {articlesList.data.map((elem, index) => (
              <tr key={index} className="border_buttom_greay">
                <td className="flex items-center w-auto">{elem.title}</td>
                <td className="w-1/3">{elem.author?.fullName}</td>
                <td className="">
                  <button
                    className="m-1"
                    onClick={() =>
                      setmodal({
                        ...modal,
                        status: true,
                        modalType: "detailes",
                        id: elem.id,
                      })
                    }
                  >
                    <BiShow color="#D59A2C" size={25} />
                  </button>
                  <button
                    className="m-1"
                    onClick={() => {
                      setmodal({ modalType: "book", status: true, edit: true });
                      setactiveArticle(elem.id);
                    }}
                  >
                    <FiEdit2 color="#4ACA5B" size={25} />
                  </button>
                  <button
                    className="m-1"
                    onClick={() => {
                      scrollToTop();
                      setopenConfirmModal({
                        id: elem.id,
                        status: "confirmDelete",
                        text: "أنت على وشك حذف هذا المقال هل أنت متأكد؟",
                        isCover:false
                      });
                      setactiveArticle(elem.id);
                    }}
                  >
                    <AiFillDelete color="#F61C0D" size={25} />
                  </button>
                </td>
              </tr>
            ))}
          </CustomTable>
          <Pagenation
            setpage={setpage}
            page={page}
            numperOfPages={articlesList.numberOfPages}
          />
        </>
      ) : (
        <PageLoader loader={articlesList.loader} text={articlesList.loader.text} />
      )}
    </div>
  );
}
const tableHeader = [
  { title: "اسم المقالة", size: "w-auto" },
  { title: "الكاتب", size: "lg" },
  { title: "", size: "sm" },
];
const detailesHeader = [
  { title: "الكاتب", key: "author", class: "h-11" },
  { title: "نبذة", key: "brief", class: "" },
  { title: "تاريخ النشر", key: "publishedAt", class: "h-11" },
  { title: "دار النشر", key: "publisingHouse", class: "h-11" },
  { title: "عدد الأجزاء", key: "numberOfParts", class: "h-11" },
];
