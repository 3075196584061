import React, { useState } from "react";

import { AiOutlineArrowRight, AiOutlineArrowLeft } from "react-icons/ai";

export default function Pagenation({ setpage, numperOfPages, page }) {
  const [inputPage, setinputPage] = useState(page);

  // hanle change page bu input
  const changePage = (e) => {
    e.preventDefault();
    if (inputPage <= numperOfPages) {
      setpage(inputPage);
    }
  };
  // handle next and prev page
  const prevPage = () => {
    if (page !== 1) {
      setinputPage(page - 1);
      setpage(page - 1);
    }
  };

  const NextPage = () => {
    if (page != numperOfPages) {
      setinputPage(page + 1);
      setpage(page + 1);
    }
  };

  const handleOnChange = (e) => {
    if (e.target.value > numperOfPages) {
      return setinputPage("1");
    }
    setinputPage(e.target.value);
  };

  return (
    <>
      <div className="pagenation flex justify-center ltr mt-3 items-center">
        <button
          className="m-2 bg_primary text-white rounded-md p-2 h-10"
          onClick={prevPage}
        >
          <AiOutlineArrowRight />
        </button>
        <form
          onSubmit={(e) => {
            changePage(e);
          }}
        >
          <input
            className="w-10 my_border rounded-md h-10 text-center m-2 font-bold text_secondary text_english"
            value={inputPage}
            onChange={handleOnChange}
            max={numperOfPages}
            min="1"
            type="number"
          />
        </form>
        <p>...</p>
        <button
          className="m-2 bg_primary text-white rounded-md p-2 w-10 h-10 text_sec_hover "
          onClick={() => {
            setpage(numperOfPages);
          }}
        >
          {numperOfPages}
        </button>
        <button
          className="m-2 bg_primary text-white rounded-md p-2 h-10 text_sec_hover"
          onClick={NextPage}
        >
          <AiOutlineArrowLeft />
        </button>
      </div>
    </>
  );
}
