import React, { useState } from "react";
// components
import CustomTable from "../components/reuseable/CustomTable";
import MessageDetailes from "../components/messages/MessageDetailes";
// data
// icons
import { BiShow } from "react-icons/bi";
import { myFeach } from "../api";
import PageLoader from "../components/reuseable/PageLoader";
import { useEffect } from "react";
export default function Messages({ regetMessageCount }) {
  const [messages, setmessages] = useState([]);
  const [loader, setloader] = useState({
    status: "loading",
    text: "يتم تحميل الرسائل",
  });
  const [openDetailesModal, setopenDetailesModal] = useState({
    status: false,
    id: "",
  });
  function truncateString(string) {
    if (string.length > 50) {
      return string.substring(0, 50) + "...";
    }
    return string;
  }
  const getAllmessages = () => {
    myFeach("/messages", "GET")
      .then(({ data }) => {
        setmessages(data.msgs);
        if (data.msgs.length == 0) {
          setloader({ text: "لا يوجد رسائل لعرضها", status: "failed" });
        } else {
          setloader({ text: "", status: "done" });
        }
      })
      .catch((err) => {
        setloader({ text: "فشل تحميل البيانات", status: "failed" });
      });
  };
  useEffect(() => {
    getAllmessages();
  }, []);

  return (
    <div className="page_container py-10 px-12">
      {/* modales */}
      {openDetailesModal.status === true && (
        <MessageDetailes
          regetMessageCount={regetMessageCount}
          openDetailesModal={openDetailesModal}
          setopenDetailesModal={setopenDetailesModal}
          headers={detailesHeader}
          id={openDetailesModal.id}
        />
      )}
      {/* end modals */}
      {loader.status === "done" ? (
        <CustomTable header={tableHeader}>
          {messages.map((elem, index) => (
            <tr key={index} className="border_buttom_greay">
              <td className="w-3/6">{truncateString(elem.msgContent)}</td>
              <td className="">{elem.name}</td>
              <td className="">{elem.createdAt}</td>
              <td className="">
                <button
                  className="m-1"
                  onClick={() =>
                    setopenDetailesModal({
                      ...openDetailesModal,
                      status: true,
                      id: elem._id,
                    })
                  }
                >
                  <BiShow size={25} color="#D59A2C" />
                </button>
              </td>
            </tr>
          ))}
        </CustomTable>
      ) : (
        <PageLoader loader={loader} />
      )}
    </div>
  );
}
const tableHeader = [
  { title: " محتوى الرسالة", size: "w-1/2" },
  { title: "المرسل", size: "sm" },
  { title: "التاريخ", size: "sm" },
  { title: "", size: "sm" },
];
const detailesHeader = [
  { title: "الاسم", key: "name", class: "h-11" },
  { title: "البريد الالكتروني", key: "email", class: "h-11" },
  { title: "الهاتف", key: "phone", class: "h-11" },
  { title: "التاريخ", key: "createdAt", class: "h-11" },
  { title: "الرسالة", key: "msgContent", class: "" },
];
