import React from "react";

export default function YearsDropdowen({years,activeYear,setactiveYear}) {
const handleSelect=(e)=>{
  let newActiveYear=years.filter((elem)=>elem.id==e.target.value)
  setactiveYear(newActiveYear[0])
}
  return (
    <div className="w-full flex flex-col">
      <label>السنة</label>
      <select
        className="my_border p-2 w-4/5"
        value={activeYear?.id}
        onChange={handleSelect}
      >
        {years.map((elem, index) => (
          <option value={elem?.id}  key={index}>{elem?.year}</option>
        ))}
      </select>
    </div>
  );
}
