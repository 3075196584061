import React, { useEffect, useState } from "react";
// global function
import { myFeach } from "../../api";
// component
import PageLoader from "../reuseable/PageLoader";
import SucsessMessageModal from "../reuseable/SucsessMessageModal";
export default function SelectPersonModall({
  closeModal,
  addNew,
  role,
  activeYear,
  reloadYeardetailes,
}) {
  const [peoples, setpeoples] = useState([]);
  const [formData, setformData] = useState({ person: "", year: activeYear.id });
  const [getPeopleLoader, setgetPeopleLoader] = useState({
    status: "loading",
    text: `جاري تحميل بيانات ${role}`,
  });
  const [submitLoader, setsubmitLoader] = useState({ status: "", text: "" });
  const [sucsessMsg, setsucsessMsg] = useState(false);
  // handle get person acording to role "form type"
  const getPersonsByRole = () => {
    myFeach(`people/role/${role}`, "GET")
      .then(({ data }) => {
        if (data.PeopleBaseOnRole.length != 0) {
          setpeoples(data.PeopleBaseOnRole);
          setformData({ ...formData, person: data.PeopleBaseOnRole[0]?.id });
          setgetPeopleLoader({ status: "done", text: "" });
        } else {
          setgetPeopleLoader({
            status: "failed",
            text: "لا يوجد أشخاص لعرضها يمكنك إضافة جديد",
          });
        }
      })
      .catch((err) => {
        setgetPeopleLoader({
          status: "failed",
          text: "فشل تحميل  الأشخاص",
        });
        console.log("err", err);
      });
  };
  // handle submit selected person
  const handleSubmit = (e) => {
    e.preventDefault();
    setsubmitLoader({ status: "loading", text: "", buttonDisabled: true });
    myFeach(`people/${formData.person}/${role}/${formData.year}`, "PUT")
      .then((result) => {
        console.log("person added", result);
        setsubmitLoader({ status: "", text: "" });
        reloadYeardetailes();
        setsucsessMsg(true);
      })
      .catch((err) => {
        console.log(err);
        setsubmitLoader({
          status: "failed",
          // need update
          // text: JSON.parse(err.message).error,
          text: "فشل الإضافة حاول مرة أخرى",
        });
      });
  };
  useEffect(() => {
    getPersonsByRole();
  }, []);
  return (
    <div className="modal_container z_index_100 ">
      <div className="esc_modal_year_detailes" style={{ width: "50%" }}>
        <button onClick={closeModal}>x</button>
      </div>
      <form
        onSubmit={handleSubmit}
        className="modal_content shadow-md"
        style={{ width: "50%" }}
      >
        {sucsessMsg && (
          <SucsessMessageModal
            close={() => setsucsessMsg(false)}
            text={"تمت الإضافة بنجاح"}
          />
        )}
        {getPeopleLoader.status === "done" ? (
          <>
            <div className="flex flex-col">
              <label>اختيار من قواعد البيانات</label>
              <select
                onBlur={(e) =>
                  setformData({ ...formData, person: e.target.value })
                }
                className="h-9 my_border"
              >
                {peoples.map((elem, index) => (
                  <option
                    className={`${elem.isDeleted && "text-red-400"}`}
                    value={elem.id}
                    key={index}
                  >
                    {elem.firstName} {elem.lastName}
                    <span className="mr-auto text-red-400 block text-xs">
                      {elem.isDeleted && (
                        <>
                          {"    "} {"(عنصر مؤرشف)"}
                        </>
                      )}
                    </span>
                  </option>
                ))}
              </select>
            </div>
            <button
              disabled={submitLoader.buttonDisabled}
              type="submit "
              className="bg_secondary my_rounded w-full h-10 mt-10"
            >
              {submitLoader.status === "loading" ? (
                <div className="loader"></div>
              ) : (
                "حفظ"
              )}
            </button>
            {submitLoader.status === "failed" && (
              <h6 className="text-red-500 text-xs m-auto w-fit mt-1">
                {submitLoader.text}
              </h6>
            )}
          </>
        ) : (
          <PageLoader loader={getPeopleLoader} />
        )}
        <button
          disabled={submitLoader.buttonDisabled}
          type="button"
          className="bg_secondary my_rounded w-full h-10 mt-3 mb-52"
          onClick={addNew}
        >
          إضافة جديد
        </button>
      </form>
    </div>
  );
}
