import React, { useEffect, useState } from "react";
import { MdDoneOutline } from "react-icons/md";
export default function SucsessMessageModal({ text, close }) {
  useEffect(() => {
    setTimeout(() => {
      close();
    }, [1000]);
  }, []);
  return (
    <div className="w-full h-screen fixed z_index_100">
      <div className="w-96 h-52 position_center my_rounded flex flex-col items-center bg-white shadow-md z_index_1000 p-4">
        <MdDoneOutline color="green" size={50} />
        <h4 className="w-fit text-center">{text}</h4>
      </div>
    </div>
  );
}
