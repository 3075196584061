import React from "react";

export default function DeleteIcon() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="27"
      height="27"
      viewBox="0 0 27 27"
      fill="none"
    >
      <path
        d="M23.7039 5.88375C21.8926 5.70375 20.0814 5.56875 18.2589 5.4675V5.45625L18.0114 3.99375C17.8426 2.95875 17.5951 1.40625 14.9626 1.40625H12.0151C9.39387 1.40625 9.14637 2.89125 8.96637 3.9825L8.73012 5.4225C7.68387 5.49 6.63762 5.5575 5.59137 5.65875L3.29637 5.88375C2.82387 5.92875 2.48637 6.345 2.53137 6.80625C2.57637 7.2675 2.98137 7.605 3.45387 7.56L5.74887 7.335C11.6439 6.75 17.5839 6.975 23.5464 7.57125H23.6364C24.0639 7.57125 24.4351 7.245 24.4801 6.80625C24.4971 6.58152 24.4253 6.35909 24.2802 6.18665C24.1351 6.01421 23.9282 5.90547 23.7039 5.88375Z"
        fill="#F61C0D"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M20.61 8.71875C20.9925 8.71875 21.3637 8.87625 21.6337 9.1575C21.8925 9.43875 22.0387 9.82125 22.0162 10.2263L21.3187 21.7575C21.195 23.4788 21.0375 25.605 17.1112 25.605H9.88871C5.96246 25.605 5.80496 23.4675 5.68121 21.7575L4.98371 10.215C4.96121 9.82125 5.10746 9.43875 5.36621 9.1575C5.62496 8.87625 6.00746 8.71875 6.38996 8.71875H20.61ZM11.0246 18.5284C10.8664 18.6866 10.7775 18.9012 10.7775 19.125C10.7775 19.3488 10.8664 19.5634 11.0246 19.7216C11.1828 19.8799 11.3974 19.9688 11.6212 19.9688H15.3675C15.5912 19.9688 15.8059 19.8799 15.9641 19.7216C16.1223 19.5634 16.2112 19.3488 16.2112 19.125C16.2112 18.9012 16.1223 18.6866 15.9641 18.5284C15.8059 18.3701 15.5912 18.2812 15.3675 18.2812H11.6212C11.3974 18.2812 11.1828 18.3701 11.0246 18.5284ZM10.0908 14.0284C9.93261 14.1866 9.84372 14.4012 9.84372 14.625C9.84372 14.8488 9.93261 15.0634 10.0908 15.2216C10.2491 15.3799 10.4637 15.4688 10.6875 15.4688H16.3125C16.5362 15.4688 16.7509 15.3799 16.9091 15.2216C17.0673 15.0634 17.1562 14.8488 17.1562 14.625C17.1562 14.4012 17.0673 14.1866 16.9091 14.0284C16.7509 13.8701 16.5362 13.7812 16.3125 13.7812H10.6875C10.4637 13.7812 10.2491 13.8701 10.0908 14.0284Z"
        fill="#F61C0D"
      />
    </svg>
  );
}
