import React, { useEffect, useRef, useState } from "react";
// data
import { dataBaseRequest } from "../../data/data";
import DataBaseReqDetailes from "./DataBaseReqDetailes";
import PageLoader from "../reuseable/PageLoader";
import { myFeach, myPutFeach, postImage } from "../../api";
import SucsessMessageModal from "../reuseable/SucsessMessageModal";
import { MdEdit } from "react-icons/md";
import EditFiledModal from "./EditFiledModal";
import EditSelectFieldModal from "./EditSelectFieldModal";

export default function RequestDetailesModal({
  closeModal,
  reqestType,
  reqId,
  regetReqestList,
  regetSidBarCounter,
}) {
  const [reqDetailes, setreqDetailes] = useState({ data: {}, loader: "" });
  const [submitLoader, setsubmitLoader] = useState({ status: "", text: "" });
  const [imageLoader, setimageLoader] = useState({ status: "", text: "" });
  const [sucsessModal, setsucsessModal] = useState({ status: "", text: "" });
  const [openEditModal, setopenEditModal] = useState({
    status: false,
    text: "",
  });
  const [openEditSelectModal, setopenEditSelectModal] = useState({
    status: "",
    text: "",
  });
  const [activeInputKey, setactiveInputKey] = useState({ title: "", key: "" });
  const [editedFieledValue, seteditedFieledValue] = useState("");
  const uplodeImageRef = useRef(null);
  const handleButtonClick = () => {
    uplodeImageRef.current.click();
  };
  const getReqDetailes = () => {
    if (reqDetailes.loader.status !== "loading") {
      setreqDetailes({
        ...reqDetailes,
        loader: { status: "loading", text: "جاري تحميل تفاصيل الطلب" },
      });
    }
    myFeach(`${reqestType.getDetailesApiReq}/${reqId}`, "GET")
      .then(({ data }) => {
        console.log(data);
        setreqDetailes({
          data: data,
          loader: { status: "done", text: "" },
        });
        // reget list to update status
        regetReqestList();
        regetSidBarCounter();
      })
      .catch((err) => {
        console.log("errp...", err);
        setreqDetailes({
          ...reqDetailes,
          loader: { status: "failed", text: "فشل تحميل تفاصيل الطلب  " },
        });
      });
  };
  const handleSubmitNewImage = (imagePath, key) => {
    console.log("path:", imagePath);
    console.log("key:", key);
    myPutFeach(`${reqestType.submitChangesApiPath}/${reqId}`, {
      [key]: imagePath,
    })
      .then((res) => {
        setimageLoader({ status: "done", text: "" });
        getReqDetailes();
      })
      .catch((err) => {
        console.log(err);
        setimageLoader({ status: "failed", text: "فشل تعديل الصورة" });
      });
  };
  const handleUplodeImage = async (e, key) => {
    setimageLoader({ status: "loading", text: "جاري الرفع " });
    let file = await e.target.files[0];
    let Imageformdata = new FormData();
    Imageformdata.append("image", file);
    postImage(reqestType.uploudeImagePath, Imageformdata)
      .then(({ data }) => {
        let keys = Object.keys(data);
        const keyOfImage = keys[0];
        handleSubmitNewImage(data[keyOfImage], key);
        uplodeImageRef.current.value = "";
        // setimageLoader({ status: "done", text: "" });
      })
      .catch((err) => {
        setimageLoader({ status: "failed", text: "فشل رفع الصورة" });
        console.log(err);
      });
  };
  const handleSubmit = (type) => {
    setsubmitLoader({
      status: type === "approve" ? "loadingApprove" : "loadingReject",
      text: "جاري قبول الطلب",
    });
    let ApiPath =
      type === "approve" ? reqestType.aproveApiPath : reqestType.rejectApiPath;
    myFeach(`${ApiPath}/${reqId}`, "PUT")
      .then((res) => {
        setsubmitLoader({ status: "done", text: " " });
        setsucsessModal({
          status: true,
          text:
            type === "approve" ? "تم قبول الطلب بنجاح" : "تم رفض الطلب بنجاح",
        });
        regetReqestList();
        getReqDetailes();
      })
      .catch((err) => {
        console.log("err", err);
        setsubmitLoader({
          status: "failed",
          text: type === "approve" ? "فشل قبول الطلب" : "فشل رفض الطلب",
        });
      });
  };
  const checkIfCanReject = () => {
    let status = reqDetailes.data.status;
    let flag = true;
    if (
      status === "تم الرفض" ||
      status === "تم القبول" ||
      status === "محرر ومقبول" ||
      status === "تم الرفض"
    ) {
      flag = false;
    }
    return flag;
  };
  const checkIfCanApprove = () => {
    let status = reqDetailes.data.status;
    let flag = true;
    if (
      status === "تم القبول" ||
      status === "محرر ومقبول" ||
      status === "محرر ومقبول"
    ) {
      flag = false;
    }
    return flag;
  };
  useEffect(() => {
    // use request type to feach data acording to request type
    getReqDetailes();
  }, []);
  return (
    <div className="modal_container">
      {sucsessModal.status && (
        <SucsessMessageModal
          close={() => setsucsessModal({ status: false, text: "" })}
          text={sucsessModal.text}
        />
      )}
      {openEditModal.status && (
        <EditFiledModal
          closeModal={() => setopenEditModal({ status: false })}
          seteditedFieledValue={seteditedFieledValue}
          activeInputKey={activeInputKey}
          oldValue={editedFieledValue}
          reqestType={reqestType}
          reqId={reqId}
          regetReqDetailes={getReqDetailes}
        />
      )}
      {openEditSelectModal.status && (
        <EditSelectFieldModal
          closeModal={() => setopenEditSelectModal({ status: false })}
          seteditedFieledValue={seteditedFieledValue}
          activeInputKey={activeInputKey}
          oldValue={editedFieledValue}
          reqestType={reqestType}
          reqId={reqId}
          regetReqDetailes={getReqDetailes}
        />
      )}
      <button onClick={closeModal} className="modal_bg"></button>
      <div className="esc_modal_year_detailes">
        <button onClick={closeModal}>x</button>
      </div>
      <div className="modal_content">
        {reqDetailes.loader.status === "done" ? (
          <>
            {reqDetailes.data?.dataSource && (
              <div className="vistor_data border_buttom_greay">
                <h2 className="font_primary mb-4">بيانات الزائر</h2>
                <div className="flex mt-2">
                  <h6 className="w-40">اسم الزائر</h6>
                  <h6 className="text_greay">
                    {reqDetailes.data.dataSource?.name}
                  </h6>
                </div>
                <div className="flex mt-2">
                  <h6 className="w-40"> رقم الهاتف</h6>
                  <h6 className="text_greay">
                    {reqDetailes.data.dataSource?.phone}
                  </h6>
                </div>
                <div className="flex mt-2">
                  <h6 className="w-40">البريد الإلكتروني</h6>
                  <h6 className="text_greay">
                    {reqDetailes.data.dataSource?.email}
                  </h6>
                </div>
                <div className="flex mt-2">
                  <h6 className="w-40">حساب الفيسبوك</h6>
                  <h6 className="text_greay">
                    {reqDetailes.data.dataSource?.facebookAcc
                      ? reqDetailes.data.dataSource?.facebookAcc
                      : "لا يوجد"}
                  </h6>
                </div>
                <div className="flex mt-2">
                  <h6 className="w-40">المصدر</h6>
                  <h6 className="text_greay">
                    {reqDetailes.data.dataSource?.source
                      ? reqDetailes.data.dataSource?.source
                      : "لا يوجد"}
                  </h6>
                </div>
                {/* <div className="flex mt-2">
                  <h6 className="w-40">صلة القرابة</h6>
                  <h6 className="text_greay">
                    {reqDetailes.data.dataSource?.kinship
                      ? reqDetailes.data.dataSource?.kinship
                      : "لا يوجد"}
                  </h6>
                </div> */}
              </div>
            )}
            <div>
              <h2 className="font_primary my-4">المحتوى المضاف</h2>
              {/* {reqestType === "database" && (
                <DataBaseReqDetailes data={dataBaseRequest.content} />
              )} */}
              {reqDetailes.data?.type && (
                <div className="flex mt-2">
                  <h6 className="w-40">نوع المحتوى</h6>
                  <h6 className="text_greay">
                    <span className="bg-red-200 p-1 my_rounded">
                      {" "}
                      {reqDetailes.data.type}{" "}
                    </span>
                    {reqDetailes.data.degree && (
                      <span className="bg-sky-200 mx-2 p-1 my_rounded">
                        {" "}
                        {reqDetailes.data.degree}
                      </span>
                    )}
                  </h6>
                </div>
              )}
              {reqestType.addedContentKeys.map((elem, index) => (
                <div className="flex mt-2" key={index}>
                  <h6 className="w-40">{elem.title}</h6>
                  {checkifKeyIsImage(elem.key) && (
                    <div className="flex flex-col">
                      <h6 className="flex items-center">
                        <div className="w-40 h-40 relative rq_image_container">
                          <div
                            className="my_rounded  flex items-center rq_image"
                            style={{
                              backgroundImage: `url(${
                                reqDetailes?.data[elem.key]
                                  ? reqDetailes?.data[elem.key]
                                  : "https://i.postimg.cc/5NzCL3Sd/Group-266.png"
                              })`,
                              width: "50px",
                              height: "50px",
                            }}
                          >
                            {imageLoader.status === "loading" && (
                              <div className="loader"></div>
                            )}
                          </div>
                          <h6 className="show_Image">عرض الصورة</h6>
                        </div>
                        {reqestType.key !== "photo" &&
                          reqestType.key !== "docs" && (
                            <span
                              className="pointer mx-10"
                              onClick={handleButtonClick}
                            >
                              <MdEdit color="green" />
                            </span>
                          )}
                        <input
                          className="input_file"
                          type="file"
                          ref={uplodeImageRef}
                          onChange={(e) => handleUplodeImage(e, elem.key)}
                        />
                      </h6>
                      {imageLoader.status === "failed" && (
                        <h6 className="text-xs text-red-500">
                          {imageLoader.text}
                        </h6>
                      )}
                    </div>
                  )}
                  {checkIfFieldIsSelect(elem.key) && (
                    <h6 className="text_greay">
                      <div className="flex items-center">
                        {reqDetailes?.data[elem.key].length ? (
                          <>
                            {reqDetailes?.data[elem.key][0]?.year}
                            {reqDetailes?.data[elem.key][0]?.name}
                          </>
                        ) : (
                          "لا يوجد"
                        )}
                        <span
                          className="mx-10 pointer"
                          onClick={() => {
                            setactiveInputKey(elem);
                            seteditedFieledValue(
                              reqDetailes?.data[elem.key].length
                                ? reqDetailes?.data[elem.key][0]
                                : ""
                            );
                            setopenEditSelectModal({ status: true, text: "" });
                          }}
                        >
                          <MdEdit color="green" />
                        </span>
                      </div>
                    </h6>
                  )}
                  {!checkifKeyIsImage(elem?.key) &&
                    !checkIfFieldIsSelect(elem?.key) &&
                    elem.key !== "bookPath" && (
                      <>
                        <h6 className="text_greay">
                          <div className="flex items-center">
                            {reqDetailes?.data[elem.key]
                              ? reqDetailes?.data[elem.key]
                              : "لا يوجد"}
                            <span
                              className="mx-10 pointer"
                              onClick={() => {
                                setactiveInputKey(elem);
                                seteditedFieledValue(
                                  reqDetailes?.data[elem.key]
                                );
                                setopenEditModal({ status: true, text: "" });
                              }}
                            >
                              <MdEdit color="green" />
                            </span>
                          </div>
                        </h6>
                      </>
                    )}
                  {elem.key === "bookPath" && (
                    <>
                      <h6 className="text_greay">
                        <div className="flex items-center">
                          <a target="_blank" href={reqDetailes?.data.bookPath}>
                          {reqDetailes?.data[elem.key]
                            ? reqDetailes?.data[elem.key]
                            : "لا يوجد"}
                          </a>
                          <span
                            className="mx-10 pointer"
                            onClick={() => {
                              setactiveInputKey(elem);
                              seteditedFieledValue(reqDetailes?.data[elem.key]);
                              setopenEditModal({ status: true, text: "" });
                            }}
                          >
                            <MdEdit color="green" />
                          </span>
                        </div>
                      </h6>
                    </>
                  )}
                </div>
              ))}
              {/* here you want to check for another req type req type and handle render the data according to req type  */}
            </div>
            {reqDetailes?.data?.status && (
              <div className="flex justify-center mt-10 controls">
                <div className="w-5/12 flex flex-col items-center">
                  <button
                    disabled={
                      submitLoader.status === "loadingReject" ||
                      submitLoader.status === "loadingApprove" ||
                      (!checkIfCanApprove() && true)
                    }
                    onClick={() => handleSubmit("approve")}
                    type="button"
                    className="w-11/12 mx-2 bg_success my_rounded h-12"
                  >
                    {submitLoader.status === "loadingApprove" ? (
                      <div className="loader"></div>
                    ) : (
                      "نشر"
                    )}
                  </button>
                  {!checkIfCanApprove() && (
                    <h6 className="text-red-400 w-full text-center text-xs">
                      لا يمكن نشر الطلب إذا كان الطلب حالته:
                      {reqDetailes.data.status}
                    </h6>
                  )}
                </div>
                <div className="w-5/12 flex flex-col items-center">
                  <button
                    disabled={
                      submitLoader.status === "loadingReject" ||
                      submitLoader.status === "loadingApprove" ||
                      (!checkIfCanReject() && true)
                    }
                    onClick={() => handleSubmit("reject")}
                    type="button"
                    className="w-11/12 mx-2 bg_secondary my_rounded h-12"
                  >
                    {submitLoader.status === "loadingReject" ? (
                      <div className="loader"></div>
                    ) : (
                      "رفض"
                    )}
                  </button>
                  {!checkIfCanReject() && (
                    <h6 className="text-red-400 w-full text-center text-xs">
                      لا يمكن رفض الطلب إذا كان الطلب حالته:
                      {reqDetailes.data.status}
                    </h6>
                  )}
                </div>
              </div>
            )}
            {submitLoader.status === "failed" && (
              <h6 className="text-red-400 w-full text-center">
                {submitLoader.text}
              </h6>
            )}
          </>
        ) : (
          <PageLoader
            text={reqDetailes.loader.text}
            loader={reqDetailes.loader}
          />
        )}
      </div>
    </div>
  );
}

// this function used in html to show image if its image
const checkifKeyIsImage = (key) => {
  let flag;
  if (key.includes("mag")) {
    flag = true;
  } else {
    flag = false;
  }
  return flag;
};
// to check the fieled is select or input
const checkIfFieldIsSelect = (key) => {
  let flag;
  if (key === "years" || key === "categories" || key === "albums") {
    flag = true;
  } else {
    flag = false;
  }
  return flag;
};
