import logo from "./logo.svg";
import "./App.css";
import Sidebar from "./components/common/Sidebar";
import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import TimeLine from "./pages/TimeLine";
import AddYear from "./pages/AddYear";
import DataBase from "./pages/DataBase";
import Books from "./pages/Books";
import Articles from "./pages/Articles";
import Audios from "./pages/Audios";
import Messages from "./pages/Messages";
import Albums from "./pages/Albums";
import Arcive from "./pages/Arcive";
import Requests from "./pages/Requests";
import Login from "./pages/Login";
import ProtectedRoute from "./components/Authrization";
import Videos from "./pages/Videos";
import { myFeach } from "./api";
import { useEffect, useState } from "react";
function App() {
  const [newContentNumber, setnewContentNumber] = useState("");
  const [unrededMessageCount, setunrededMessageCount] = useState("");
  const handleGetNewContentNumber = () => {
    myFeach("dashboard/new-content", "GET")
      .then(({ data }) => {
        setnewContentNumber(data.allNewContent);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const handleGetUnreedMessageCount = () => {
    myFeach("messages/unread-count", "GET")
      .then(({ data }) => {
        setunrededMessageCount(data.unreadMessagesCount);
      })
      .catch((err) => {
        console.log(err);
      });
  };
  useEffect(() => {
    handleGetNewContentNumber();
    handleGetUnreedMessageCount();
  });
  return (
    <BrowserRouter>
      <div className="App">
        <Sidebar
          newContentNumber={newContentNumber}
          unrededMessageCount={unrededMessageCount}
        />
        <div className="">
          <Routes>
            <Route path="/login" element={<Login />} />
            <Route
              path="/"
              element={
                <ProtectedRoute>
                  <TimeLine />
                </ProtectedRoute>
              }
            />
            <Route
              path="/time-line"
              element={
                <ProtectedRoute>
                  <TimeLine />
                </ProtectedRoute>
              }
            />
            <Route
              path="/add-year"
              element={
                <ProtectedRoute>
                  <AddYear />
                </ProtectedRoute>
              }
            />
            <Route
              path="/data-base"
              element={
                <ProtectedRoute>
                  <DataBase />
                </ProtectedRoute>
              }
            />
            <Route
              path="/books"
              element={
                <ProtectedRoute>
                  <Books />
                </ProtectedRoute>
              }
            />
            <Route
              path="/articles"
              element={
                <ProtectedRoute>
                  <Articles />
                </ProtectedRoute>
              }
            />
            <Route
              path="/videos"
              element={
                <ProtectedRoute>
                  <Videos />
                </ProtectedRoute>
              }
            />
            <Route
              path="/albums"
              element={
                <ProtectedRoute>
                  <Albums />
                </ProtectedRoute>
              }
            />
            <Route
              path="/audios"
              element={
                <ProtectedRoute>
                  <Audios />
                </ProtectedRoute>
              }
            />
            <Route
              path="/messages"
              element={
                <ProtectedRoute>
                  <Messages regetMessageCount={handleGetUnreedMessageCount} />
                </ProtectedRoute>
              }
            />
            <Route
              path="/arcive"
              element={
                <ProtectedRoute>
                  <Arcive />
                </ProtectedRoute>
              }
            />
            <Route
              path="/requests"
              element={
                <ProtectedRoute>
                  <Requests regetSidBarCounter={handleGetNewContentNumber} />
                </ProtectedRoute>
              }
            />
          </Routes>
        </div>
      </div>
    </BrowserRouter>
  );
}

export default App;
