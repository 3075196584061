import React, { useEffect, useState } from "react";
import { myFeach } from "../../api";
import PageLoader from "../reuseable/PageLoader";
// 
export default function VideoDetailesModal({
  id,
  headers,
  setopenDetailesModal,
  vedio_audio
}) {
  const [loader, setloader] = useState({
    status: "loading",
    text: "جاري تحميل بيانات المقطع",
  });
  const [videoDetailes, setvideoDetailes] = useState();
  const closeModal = () => {
    setopenDetailesModal({ status: false, personId: "" });
  };
  const getvideoDetailes = () => {
    myFeach(`media/${id}`, "GET")
      .then(({ data }) => {
        console.log(data)
        setvideoDetailes(data);
        setloader({ status: "done", text: "" });
      })
      .catch((err) => {
        console.log(err);
        setloader({ status: "failed", text: "فشل تحميل بيانات المقطع" });
      });
  };
  useEffect(() => {
    getvideoDetailes();
  }, []);
  return (
    <>
      <div className="modal_container">
        <button onClick={closeModal} className="modal_bg"></button>
        <div className="small_modal_conrent">
          <button className="text_greay text-xl" onClick={closeModal}>
            X
          </button>
          {loader.status === "done" ? (
            <div className="person_detaile">
              <figure className="m-auto h-32 w-full ">
                <img
                  className="block h-full my_rounded m-auto"
                  src={videoDetailes?.imagePath}
                />
              </figure>
              <h3 className="mx-auto mt-2 w-fit font_primary">
                {videoDetailes?.title}
              </h3>
              <div className="person_data w-4/5 mx-auto mt-10">
                {headers.map((elem, index) => (
                  <div className="row" key={index}>
                    <h4 className={`${elem.class} title `}>{elem.title}</h4>
                    {elem.key == "mediaPath" ? (
                      <a
                        target="_blank"
                        className={`${elem.class} info text_hover_active`}
                        href={videoDetailes[elem?.key]}
                      >
                        {videoDetailes[elem?.key]}
                      </a>
                    ) : (
                      <h4 className={`${elem.class} info`}>
                        {videoDetailes[elem?.key]}
                      </h4>
                    )}
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <PageLoader text={loader.text} loader={loader} />
          )}
        </div>
      </div>
    </>
  );
}
