import React from "react";

export default function DataBaseIcon(pathname) {
  return (
    <>
      {pathname === "/data-base" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M17.5301 7.77C17.4605 7.76 17.3897 7.76 17.3201 7.77C16.5732 7.74556 15.8653 7.43119 15.3463 6.89355C14.8273 6.3559 14.5381 5.63726 14.5401 4.89C14.5401 3.3 15.8301 2 17.4301 2C19.0201 2 20.3201 3.29 20.3201 4.89C20.318 5.63773 20.0261 6.35553 19.5058 6.89258C18.9855 7.42963 18.2774 7.74413 17.5301 7.77ZM20.7921 14.7C19.6721 15.45 18.1021 15.73 16.6521 15.54C17.0321 14.72 17.2321 13.81 17.2421 12.85C17.2421 11.85 17.0221 10.9 16.6021 10.07C18.0821 9.87 19.6521 10.15 20.7821 10.9C22.3621 11.94 22.3621 13.65 20.7921 14.7ZM6.43809 7.77C6.50809 7.76 6.57809 7.76 6.64809 7.77C7.39496 7.74556 8.10293 7.43119 8.62191 6.89355C9.14089 6.3559 9.43005 5.63726 9.42809 4.89C9.42809 3.3 8.13809 2 6.53809 2C4.94809 2 3.64809 3.29 3.64809 4.89C3.65023 5.63773 3.9421 6.35553 4.46237 6.89258C4.98264 7.42963 5.69081 7.74413 6.43809 7.77ZM6.55109 12.85C6.55109 13.82 6.76109 14.74 7.14109 15.57C5.73109 15.72 4.26109 15.42 3.18109 14.71C1.60109 13.66 1.60109 11.95 3.18109 10.9C4.25109 10.18 5.76109 9.89 7.18109 10.05C6.77109 10.89 6.55109 11.84 6.55109 12.85Z"
            fill="#D59A2C"
          />
          <path
            d="M12.12 15.87C12.0337 15.86 11.9464 15.86 11.86 15.87C10.9709 15.8401 10.1283 15.4655 9.51047 14.8253C8.89269 14.1851 8.54825 13.3297 8.55005 12.44C8.55005 10.54 10.08 9 11.99 9C13.89 9 15.43 10.54 15.43 12.44C15.43 14.3 13.97 15.81 12.12 15.87ZM8.87005 17.94C7.36005 18.95 7.36005 20.61 8.87005 21.61C10.59 22.76 13.41 22.76 15.13 21.61C16.64 20.6 16.64 18.94 15.13 17.94C13.42 16.79 10.6 16.79 8.87005 17.94Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            opacity="0.4"
            d="M17.5301 7.77C17.4605 7.76 17.3897 7.76 17.3201 7.77C16.5732 7.74556 15.8653 7.43119 15.3463 6.89355C14.8273 6.3559 14.5381 5.63726 14.5401 4.89C14.5401 3.3 15.8301 2 17.4301 2C19.0201 2 20.3201 3.29 20.3201 4.89C20.318 5.63773 20.0261 6.35553 19.5058 6.89258C18.9855 7.42963 18.2774 7.74413 17.5301 7.77ZM20.7921 14.7C19.6721 15.45 18.1021 15.73 16.6521 15.54C17.0321 14.72 17.2321 13.81 17.2421 12.85C17.2421 11.85 17.0221 10.9 16.6021 10.07C18.0821 9.87 19.6521 10.15 20.7821 10.9C22.3621 11.94 22.3621 13.65 20.7921 14.7ZM6.43809 7.77C6.50809 7.76 6.57809 7.76 6.64809 7.77C7.39496 7.74556 8.10293 7.43119 8.62191 6.89355C9.14089 6.3559 9.43005 5.63726 9.42809 4.89C9.42809 3.3 8.13809 2 6.53809 2C4.94809 2 3.64809 3.29 3.64809 4.89C3.65023 5.63773 3.9421 6.35553 4.46237 6.89258C4.98264 7.42963 5.69081 7.74413 6.43809 7.77ZM6.55109 12.85C6.55109 13.82 6.76109 14.74 7.14109 15.57C5.73109 15.72 4.26109 15.42 3.18109 14.71C1.60109 13.66 1.60109 11.95 3.18109 10.9C4.25109 10.18 5.76109 9.89 7.18109 10.05C6.77109 10.89 6.55109 11.84 6.55109 12.85Z"
            fill="white"
          />
          <path
            d="M12.1198 15.87C12.0334 15.86 11.9462 15.86 11.8598 15.87C10.9707 15.8401 10.128 15.4655 9.51023 14.8253C8.89245 14.1851 8.548 13.3297 8.5498 12.44C8.5498 10.54 10.0798 9 11.9898 9C13.8898 9 15.4298 10.54 15.4298 12.44C15.4298 14.3 13.9698 15.81 12.1198 15.87ZM8.8698 17.94C7.3598 18.95 7.3598 20.61 8.8698 21.61C10.5898 22.76 13.4098 22.76 15.1298 21.61C16.6398 20.6 16.6398 18.94 15.1298 17.94C13.4198 16.79 10.5998 16.79 8.8698 17.94Z"
            fill="white"
          />
        </svg>
      )}
    </>
  );
}
