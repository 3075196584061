import React, { useEffect, useState } from "react";
import { myFeach } from "../../api";
import PageLoader from "../reuseable/PageLoader";
export default function MessageDetailes({
  setopenDetailesModal,
  headers,
  id,
  regetMessageCount
}) {
  const closeModal = () => {
    setopenDetailesModal({ status: false, id: "" });
  };
  const [loader, setloader] = useState({
    status: "loading",
    text: "جاري تحميل بيانات الشخص",
  });
  const [message, setmessage] = useState({});

  // handle get message detailes
  const getMessages = () => {
    // setloader({ status: "loading", text: "يتم تحميل بيانات الأشخاص" });
    myFeach(`messages/read/${id}`, "GET")
      .then(({ data }) => {
        setloader({ status: "done", text: "" });
        setmessage(data.msg)
        regetMessageCount();
      })
      .catch((err) => {
        console.log("error", err);
        setloader({ status: "failed", text: "فشل تحميل بيانات الأشخاص" });
      });
  };

  useEffect(() => {
    getMessages();
  }, []);
  return (
    <>
      <div className="modal_container">
        <button onClick={closeModal} className="modal_bg"></button>
        <div className="small_modal_conrent">
          <button className="text_greay text-xl" onClick={closeModal}>
            X
          </button>
          {loader.status === "done" ? (
            <div className="person_detaile">
              <h3 className="mx-auto mt-2 w-fit font_primary">
                {message?.title}
              </h3>
              <div className="person_data w-4/5 mx-auto mt-10">
                {headers.map((elem, index) => (
                  <div className="row" key={index}>
                    <h4 className={`${elem.class} title `}>{elem.title}</h4>
                    <h4 className={`${elem.class} info`}>
                      {message[elem?.key]}{" "}
                    </h4>
                  </div>
                ))}
              </div>
            </div>
          ) : (
            <PageLoader text={loader.text} loader={loader} />
          )}
        </div>
      </div>
    </>
  );
}
