export const timelineData = {
  year: "",
  imge: "https://i.postimg.cc/QMpMGvnW/bg1.png",
  imgeName: "اسم الصورة.png",
  board: [
    { name: "اسم مؤقت يمكن تغيره" },
    { name: "اسم مؤقت يمكن تغيره" },
    { name: "اسم مؤقت يمكن تغيره" },
    { name: "اسم مؤقت يمكن تغيره" },
  ],
  graduates: [
    { name: "اسم مؤقت يمكن تغيره" },
    { name: "اسم مؤقت يمكن تغيره" },
    { name: "اسم مؤقت يمكن تغيره" },
    { name: "اسم مؤقت يمكن تغيره" },
    { name: "اسم مؤقت يمكن تغيره" },
    { name: "اسم مؤقت يمكن تغيره" },
    { name: "اسم مؤقت يمكن تغيره" },
    { name: "اسم مؤقت يمكن تغيره" },
    { name: "اسم مؤقت يمكن تغيره" },
    { name: "اسم مؤقت يمكن تغيره" },
  ],
  teatchers: [{ name: "اسم مؤقت يمكن تغيره" }, { name: "اسم مؤقت يمكن تغيره" }],
};

export const database_data = [
  {
    _id: "000",
    imge: "https://i.postimg.cc/ht4hjyFJ/person1.png",
    name: "ياسر محمد السيد",
    position: "عميد",
    year: "1998",
    detailes:
      "نص يمكن استبداله استبدالهاستبداله استبداله استبداله استبداله استبداله استبداله استبداله استبداله استبداله استبداله استبداله يمكن استبداله",
  },
  {
    _id: "0001",
    imge: "https://i.postimg.cc/ht4hjyFJ/person1.png",
    name: "ياسر محمد السيد",
    position: "عميد",
    year: "1998",
    detailes: "نص مؤقت يمكن استبداله",
  },
  {
    _id: "0003",
    imge: "https://i.postimg.cc/ht4hjyFJ/person1.png",
    name: "ياسر محمد السيد",
    position: "عميد",
    year: "1998",
    detailes: "نص مؤقت يمكن استبداله",
  },
];
export const person_detailes = {
  img: "https://i.postimg.cc/ht4hjyFJ/person1.png",
  name: "اسم مؤقت يمكن استبداله",
  detailes:
    "نص مؤ نص مؤقت  يمكن استبداله نص مؤقتم   يمكن استبداله نص مؤقتم   يمكن استبداله نص مؤقتم  يمكن استبداله نص مؤقتم استبداله",
  graduation_year: "1985",
  birth_date: "1960",
  death_date: "2003",
};
// library pages data
export const libraryBooksData = {
  latest: [
    {
      bg: "https://i.postimg.cc/MTMYsrn3/Books-slider-BG.png",
      title: "تقويم دار العلوم",
      auther: "محمد عبد المجيد",
      puplishDate: "4/6/2023",
      imge: "https://i.postimg.cc/wMRbmj5v/book.png",
      soon: false,
    },
    {
      bg: "https://i.postimg.cc/MTMYsrn3/Books-slider-BG.png",
      title: "تقويم دار العلوم",
      auther: "محمد عبد المجيد",
      puplishDate: "4/6/2023",
      imge: "https://i.postimg.cc/wMRbmj5v/book.png",
      soon: true,
    },
  ],
  allBooks: [
    {
      imge: "https://i.postimg.cc/fV1yXNNn/book.png",
      title: "تقويم دار العلوم ( تاريخها، خططها، أساتذتها)",
      auther: "محمد عبد الجواد",
      about:
        "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل",
      puplishDate: "۱۹٥۲",
      PublishingHouse: "دار المعارف",
      parts: "۱",
      readingLink: "#",
      downloadLink: "#",
    },
    {
      imge: "https://i.postimg.cc/MMCmr4Rh/image-2.png",
      title: "تقويم دار العلوم ( تاريخها، خططها، أساتذتها)",
      auther: "محمد بن عيسى الترمذي",
      about:
        "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل",
      puplishDate: "۱٤۰۸",
      PublishingHouse: "دار الصديق",
      parts: "۱",
      readingLink: "#",
      downloadLink: "#",
    },
  ],
};
export const bookDetailes = {
  img: "https://i.postimg.cc/MMCmr4Rh/image-2.png",
  title: "تقويم دار العلوم ( تاريخها، خططها، أساتذتها)",
  auther: "محمد بن عيسى الترمذي",
  about:
    "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل",
  puplishDate: "۱٤۰۸",
  PublishingHouse: "دار الصديق",
  parts: 5,
};
// article data
export const libraryArticles = {
  latest: [
    {
      bg: "https://i.postimg.cc/QNY9GTPK/articles-slider.png",
      title: "مشروع تراث دار العلوم",
      auther: "عبد الفتاح جمال",
      puplishDate: "4/6/2023",
    },
    {
      bg: "https://i.postimg.cc/QNY9GTPK/articles-slider.png",
      title: "مشروع تراث دار العلوم",
      auther: "محمد عبد المجيد",
      puplishDate: "4/6/2023",
    },
    {
      bg: "https://i.postimg.cc/QNY9GTPK/articles-slider.png",
      title: "مشروع تراث دار العلوم",
      auther: "محمد عبد المجيد",
      puplishDate: "4/6/2023",
    },
  ],
  allArticles: [
    {
      imge: "https://i.postimg.cc/mrLYhxG6/Rectangle-82.png",
      title: "مشروع تراث دار العلوم",
      auther: "عبد الفتاح جمال",
      descreption:
        "مشروع يعنى بحفظ جزء من التراث المعاصر بالاعتناء بتاريخ دار العلوم وتاريخ خريجيها وتراثهم خلال السنوات المائة الأولى من تاريخ نشأتها سنة ١٨٧٢ حتى سنة ١٩٧٢.",
      date: "الخميس, 1 يونيو 2023",
    },
    {
      imge: "https://i.postimg.cc/mrLYhxG6/Rectangle-82.png",
      title: "مشروع تراث دار العلوم",
      auther: "عبد الفتاح جمال",
      descreption:
        "مشروع يعنى بحفظ جزء من التراث المعاصر بالاعتناء بتاريخ دار العلوم وتاريخ خريجيها وتراثهم خلال السنوات المائة الأولى من تاريخ نشأتها سنة ١٨٧٢ حتى سنة ١٩٧٢.",
      date: "الخميس, 1 يونيو 2023",
    },
  ],
};
export const articleDetailes = {
  img: "https://i.postimg.cc/mrLYhxG6/Rectangle-82.png",
  auther: "كاتب مؤقت",
  publishDate: "24/10/2022",
  title: "عنوان مؤقت يمكن استبداله",
  content: "محتوى المقالة",
};
// videos page
export const libraryVideos = {
  latest: [
    {
      bg: "https://i.postimg.cc/VkqQh6fH/articles-slider.png",
      videoLink: "W",
      title: "تقويم دار العلوم",
      auther: "محمد عبد المجيد",
      puplishDate: "4/6/2023",
    },
    {
      bg: "https://i.postimg.cc/VkqQh6fH/articles-slider.png",
      videoLink: "W",
      title: "تقويم دار العلوم",
      auther: "محمد عبد المجيد",
      puplishDate: "4/6/2023",
    },
    {
      bg: "https://i.postimg.cc/VkqQh6fH/articles-slider.png",
      videoLink: "#",
      title: "تقويم دار العلوم",
      auther: "محمد عبد المجيد",
      puplishDate: "4/6/2023",
    },
    {
      bg: "https://i.postimg.cc/VkqQh6fH/articles-slider.png",
      videoLink: "#",
      title: "تقويم دار العلوم",
      auther: "محمد عبد المجيد",
      puplishDate: "4/6/2023",
    },
  ],
  allVideos: [
    {
      videoLink: "#",
      title: "تراث دار العلوم",
      logo: "https://i.postimg.cc/bYHjDyLX/logo.png",
      coverPhoto: "https://i.postimg.cc/VkqQh6fH/articles-slider.png",
      subTitle: "تراث دار العلوم",
    },
    {
      videoLink: "#",
      title: "تراث دار العلوم",
      logo: "https://i.postimg.cc/bYHjDyLX/logo.png",
      coverPhoto: "https://i.postimg.cc/VkqQh6fH/articles-slider.png",
      subTitle: "تراث دار العلوم",
    },
    {
      videoLink: "#",
      title: "تراث دار العلوم",
      logo: "https://i.postimg.cc/bYHjDyLX/logo.png",
      coverPhoto: "https://i.postimg.cc/VkqQh6fH/articles-slider.png",
      subTitle: "تراث دار العلوم",
    },
    {
      videoLink: "#",
      title: "تراث دار العلوم",
      logo: "https://i.postimg.cc/bYHjDyLX/logo.png",
      coverPhoto: "https://i.postimg.cc/VkqQh6fH/articles-slider.png",
      subTitle: "تراث دار العلوم",
    },
    {
      videoLink: "#",
      title: "تراث دار العلوم",
      logo: "https://i.postimg.cc/bYHjDyLX/logo.png",
      coverPhoto: "https://i.postimg.cc/VkqQh6fH/articles-slider.png",
      subTitle: "تراث دار العلوم",
    },
    {
      videoLink: "#",
      title: "تراث دار العلوم",
      logo: "https://i.postimg.cc/bYHjDyLX/logo.png",
      coverPhoto: "https://i.postimg.cc/VkqQh6fH/articles-slider.png",
      subTitle: "تراث دار العلوم",
    },
    {
      videoLink: "#",
      title: "تراث دار العلوم",
      logo: "https://i.postimg.cc/bYHjDyLX/logo.png",
      coverPhoto: "https://i.postimg.cc/VkqQh6fH/articles-slider.png",
      subTitle: "تراث دار العلوم",
    },
    {
      videoLink: "#",
      title: "تراث دار العلوم",
      logo: "https://i.postimg.cc/bYHjDyLX/logo.png",
      coverPhoto: "https://i.postimg.cc/VkqQh6fH/articles-slider.png",
      subTitle: "تراث دار العلوم",
    },
  ],
};
export const videoDetailes = {
  img: "https://i.postimg.cc/VkqQh6fH/articles-slider.png",
  title: "عنوان مؤقت للمقطع",
  link: "رابط الموقع",
  publishDate: "20/10/2002",
};
// audios page
export const audioDetailes = {
  img: "https://i.postimg.cc/VkqQh6fH/articles-slider.png",
  title: "عنوان مؤقت للمقطع",
  link: "رابط الموقع",
  publishDate: "20/10/2002",
  audio: "test",
};
// vistors messages page
export const messages = [
  {
    messages: "محتوى مؤقت للرسالة يمكن استبداله",
    sender: "ياسر",
    date: "25/3/2023",
  },
  {
    messages: "محتوى مؤقت للرسالة يمكن استبداله",
    sender: "ياسر",
    date: "25/3/2023",
  },
  {
    messages: "محتوى مؤقت للرسالة يمكن استبداله",
    sender: "ياسر",
    date: "25/3/2023",
  },
  {
    messages: "محتوى مؤقت للرسالة يمكن استبداله",
    sender: "ياسر",
    date: "25/3/2023",
  },
];
export const messageDetailes = {
  name: "اسم مؤقت",
  email: "mmm@gmail.com",
  phone: "0100025000",
  date: "25/5/2022",
  message: "رسالة مؤقتة يمكن استبدالها في أي وقت نص مؤقت يمكن استبداله",
};
// albums data
export const albums = [
  {
    img: "https://i.postimg.cc/MTSHxB5T/heroBg.png",
    key: "all",
    title: "  الكل",
  },
  {
    img: "https://i.postimg.cc/MTSHxB5T/heroBg.png",
    key: "001",
    title: "الصور الجماعية للدفعات",
  },
  {
    img: "https://i.postimg.cc/MTSHxB5T/heroBg.png",
    key: "0201",
    title: "هيئات التدريس",
  },
  {
    img: "https://i.postimg.cc/MTSHxB5T/heroBg.png",
    key: "0021",
    title: "الدفعات",
  },
  {
    img: "https://i.postimg.cc/MTSHxB5T/heroBg.png",
    key: "01101",
    title: "الوثائق",
  },
];
export const albumDetailes = [
  {
    img: "https://i.postimg.cc/MTSHxB5T/heroBg.png",
    title: "عنوان مؤقت للصورة",
    likes: "55",
  },
  {
    img: "https://i.postimg.cc/MTSHxB5T/heroBg.png",
    title: "عنوان مؤقت للصورة",
    likes: "55",
  },
  {
    img: "https://i.postimg.cc/MTSHxB5T/heroBg.png",
    title: "عنوان مؤقت للصورة",
    likes: "55",
  },
  {
    img: "https://i.postimg.cc/MTSHxB5T/heroBg.png",
    title: "عنوان مؤقت للصورة",
    likes: "55",
  },
  {
    img: "https://i.postimg.cc/MTSHxB5T/heroBg.png",
    title: "عنوان مؤقت للصورة",
    iikes: "55",
  },
  {
    img: "https://i.postimg.cc/MTSHxB5T/heroBg.png",
    title: "عنوان مؤقت للصورة",
    iikes: "55",
  },
];
// requests
export const requests = [
  {
    name: "اسم مؤقت",
    phone: "+201000258774",
    date: "12 مايو 2022",
    status: "تم النشر",
  },
  {
    name: "اسم مؤقت",
    phone: "+201000258774",
    date: "12 مايو 2022",
    status: "تم النشر",
  },
  {
    name: "اسم مؤقت",
    phone: "+201000258774",
    date: "12 مايو 2022",
    status: "تم النشر",
  },
  {
    name: "اسم مؤقت",
    phone: "+201000258774",
    date: "12 مايو 2022",
    status: "تم النشر",
  },
  {
    name: "اسم مؤقت",
    phone: "+201000258774",
    date: "12 مايو 2022",
    status: "تم النشر",
  },
  {
    name: "اسم مؤقت",
    phone: "+201000258774",
    date: "12 مايو 2022",
    status: "تم النشر",
  },
  {
    name: "اسم مؤقت",
    phone: "+201000258774",
    date: "12 مايو 2022",
    status: "تم النشر",
  },
  {
    name: "اسم مؤقت",
    phone: "+201000258774",
    date: "12 مايو 2022",
    status: "تم النشر",
  },
];
// request detailes
export const dataBaseRequest = {
  vistorData: {
    name: "ياسر",
    phone: "+201000583669",
    email: "vv@gmail.com",
    faceBook: "http//facebook/yasser",
  },
  content: {
    contentType: "خريج",
    name: "محمد أحمد محمود",
    position: "لا توجد إجابة",
    graduationDate: "1998",
    moreDetailes:
      "هذا النص هو مثال لنص يمكن أن يستبدل في نفس المساحة، لقد تم توليد هذا النص من مولد النص العربى، حيث يمكنك أن تولد مثل هذا النص أو العديد من النصوص الأخرى إضافة إلى زيادة عدد الحروف التى يولدها التطبيق.",
    relation: "لا يوجد إجابة",
    source: "لا يوجد إجابة",
  },
};
