import React, { useEffect, useState } from "react";
import { MdOutlineDone } from "react-icons/md";
import YearsDropdowen from "./YearsDropdowen";
import { myFeach } from "../../api";
import PageLoader from "../reuseable/PageLoader";
import logo from "../../assets/logo.svg";
export default function YearDetailesModal({
  closeModal,
  years,
  activeYear,
  setactiveYear,
}) {
  const [yeatDetailes, setyeatDetailes] = useState({});
  const [loader, setloader] = useState({
    status: "loading",
    text: "جاري تحميل بيانات السنة",
  });

  const getYearDetailes = () => {
    myFeach(`years/${activeYear.id}`, "GET")
      .then(({ data }) => {
        setyeatDetailes(data.year);
        setloader({ status: "done", text: "" });
      })
      .catch((err) => {
        setloader({ status: "failed", text: "فشل تحميل بيانات السنة" });
      });
  };
  useEffect(() => {
    getYearDetailes();
  }, [activeYear]);
  return (
    <>
      <div className="modal_container">
        <button onClick={closeModal} className="modal_bg"></button>
        <div className="esc_modal_year_detailes">
          <button onClick={closeModal}>x</button>
        </div>
        <div className="modal_content">
          {loader.status === "done" ? (
            <>
              <div className="flex items-center justify-between">
                <div className="flex flex-col w-60">
                  <YearsDropdowen
                    years={years}
                    setactiveYear={setactiveYear}
                    activeYear={activeYear}
                  />
                </div>
                <div className="dashed_border flex items-center py-3 px-8 my_rounded">
                  <figure
                    className="w-20 h-20 imge_bg my_rounded"
                    style={
                      {
                        // backgroundImage: `url(${yeatDetailes?.image})`,
                      }
                    }
                  >
                    <img
                      className="w-20 h-20 my_rounded block"
                      src={yeatDetailes.image}
                      onError={() =>
                        setyeatDetailes({ ...yeatDetailes, image: logo })
                      }
                    ></img>
                  </figure>
                  <h4 className="mr-2">
                    {yeatDetailes?.image.substring(
                      yeatDetailes.image.length - 15
                    )}
                  </h4>
                  <div className="bg_success w-10 h-10 circle_rounded flex_center mr-40">
                    <MdOutlineDone size={20} />
                  </div>
                </div>
              </div>
              <div className="flex justify-between mt-10">
                <div className="w-1/4">
                  <div className="w-full flex justify-between border_buttom">
                    <h3>الهيئة الإدارية</h3>
                  </div>
                  <ul>
                    {yeatDetailes?.people.administrativeBoard.map(
                      (elem, index) => (
                        <li
                          key={index}
                          className={`${
                            elem.isDeleted
                              ? "bg-red-100 text-zinc-700"
                              : "bg-gray-300"
                          } flex justify-between items-center my_rounded  p-3 my-2`}
                        >
                          <h4>
                            {elem.firstName} {elem.lastName}
                          </h4>
                        </li>
                      )
                    )}
                  </ul>
                </div>
                <div className="w-1/4 ">
                  <div className="w-full flex justify-between border_buttom">
                    <h3>هيئة التدريس</h3>
                  </div>
                  <ul>
                    {yeatDetailes?.people.teachingStaff.map((elem, index) => (
                      <li
                        key={index}
                        className={`${
                          elem.isDeleted
                            ? "bg-red-100 text-zinc-700"
                            : "bg-gray-300"
                        } flex justify-between items-center my_rounded  p-3 my-2`}
                      >
                        <h4>
                          {elem.firstName} {elem.lastName}
                        </h4>
                      </li>
                    ))}
                  </ul>
                </div>
                <div className="w-1/4">
                  <div className="w-full flex justify-between border_buttom">
                    <h3>الخريجون</h3>
                  </div>
                  <ul>
                    {yeatDetailes?.people.graduates.map((elem, index) => (
                      <li
                        key={index}
                        className={`${
                          elem.isDeleted
                            ? "bg-red-100 text-zinc-700"
                            : "bg-gray-300"
                        } flex justify-between items-center my_rounded  p-3 my-2`}
                      >
                        <h4>
                          {elem.firstName} {elem.lastName}
                        </h4>
                      </li>
                    ))}
                  </ul>
                </div>
              </div>
            </>
          ) : (
            <PageLoader loader={loader} />
          )}
        </div>
      </div>
    </>
  );
}
