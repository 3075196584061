import React from "react";

export default function ArciveIcon({ pathname }) {
  return (
    <>
      {pathname === "/arcive" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
        >
          <g clipPath="url(#clip0_2104_152)">
            <rect x="5" y="8" width="14" height="10" fill="white" />
            <path
              d="M19.6842 5.01208L18.3521 3.40208C18.0933 3.07625 17.7004 2.875 17.25 2.875H5.75C5.29958 2.875 4.90667 3.07625 4.63833 3.40208L3.31583 5.01208C3.03792 5.33792 2.875 5.76917 2.875 6.22917V18.2083C2.875 19.2625 3.7375 20.125 4.79167 20.125H18.2083C19.2625 20.125 20.125 19.2625 20.125 18.2083V6.22917C20.125 5.76917 19.9621 5.33792 19.6842 5.01208ZM11.5 16.7708L6.22917 11.5H9.58333V9.58333H13.4167V11.5H16.7708L11.5 16.7708ZM4.90667 4.79167L5.68292 3.83333H17.1829L18.0838 4.79167H4.90667Z"
              fill="#D59A2C"
            />
          </g>
          <defs>
            <clipPath id="clip0_2104_152">
              <rect width="23" height="23" fill="white" />
            </clipPath>
          </defs>
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="23"
          height="23"
          viewBox="0 0 23 23"
          fill="none"
        >
          <g clipPath="url(#clip0_1989_1717)">
            <path
              d="M19.6842 5.01208L18.3521 3.40208C18.0933 3.07625 17.7004 2.875 17.25 2.875H5.75C5.29958 2.875 4.90667 3.07625 4.63833 3.40208L3.31583 5.01208C3.03792 5.33792 2.875 5.76917 2.875 6.22917V18.2083C2.875 19.2625 3.7375 20.125 4.79167 20.125H18.2083C19.2625 20.125 20.125 19.2625 20.125 18.2083V6.22917C20.125 5.76917 19.9621 5.33792 19.6842 5.01208ZM11.5 16.7708L6.22917 11.5H9.58333V9.58333H13.4167V11.5H16.7708L11.5 16.7708ZM4.90667 4.79167L5.68292 3.83333H17.1829L18.0838 4.79167H4.90667Z"
              fill="white"
            />
          </g>
          <defs>
            <clipPath id="clip0_1989_1717">
              <rect width="23" height="23" fill="white" />
            </clipPath>
          </defs>
        </svg>
      )}
    </>
  );
}
