import React from "react";

export default function TimeLineIcon({ pathname }) {
  return (
    <>
      {pathname !== "/time-line" ? (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            opacity="0.4"
            d="M21.9795 15.65C21.5596 15.1324 21.029 14.7155 20.4268 14.43C19.8246 14.1445 19.166 13.9975 18.4995 14C17.4395 14 16.4595 14.37 15.6895 14.99C15.16 15.4085 14.7326 15.942 14.4398 16.5501C14.147 17.1583 13.9964 17.8251 13.9995 18.5C13.9995 19.91 14.6395 21.16 15.6495 21.98C16.4195 22.62 17.4195 23 18.4995 23C19.6395 23 20.6695 22.57 21.4695 21.88C21.9492 21.4569 22.3335 20.9369 22.5973 20.3542C22.861 19.7716 22.9981 19.1396 22.9995 18.5C22.9995 17.42 22.6195 16.42 21.9795 15.65ZM19.5295 18.78C19.5295 19.04 19.3895 19.29 19.1695 19.42L17.7595 20.26C17.6395 20.33 17.5095 20.37 17.3695 20.37C17.1195 20.37 16.8695 20.24 16.7295 20.01C16.5195 19.65 16.6295 19.19 16.9895 18.98L18.0295 18.36V17.1C18.0295 16.69 18.3695 16.35 18.7795 16.35C19.1895 16.35 19.5295 16.69 19.5295 17.1V18.78ZM14.7295 5.86V2.5C14.7295 2.22 14.5095 2 14.2295 2H9.76951C9.48951 2 9.26951 2.22 9.26951 2.5V5.86C9.26951 6.14 9.48951 6.36 9.76951 6.36H14.2295C14.5095 6.36 14.7295 6.14 14.7295 5.86ZM7.24851 2.02C4.68851 2.18 2.93851 3.5 2.28851 5.7C2.18851 6.03 2.42851 6.36 2.76851 6.36H7.26851C7.54851 6.36 7.76851 6.14 7.76851 5.86V2.52C7.76857 2.45262 7.755 2.38593 7.72863 2.32392C7.70227 2.26192 7.66364 2.20588 7.61507 2.15918C7.5665 2.11248 7.50899 2.07608 7.446 2.05216C7.38301 2.02824 7.31584 2.0173 7.24851 2.02ZM16.7495 2.009C19.3095 2.169 21.0595 3.489 21.7095 5.689C21.8095 6.019 21.5695 6.349 21.2295 6.349H16.7295C16.4495 6.349 16.2295 6.129 16.2295 5.849V2.509C16.2295 2.44162 16.243 2.37493 16.2694 2.31292C16.2958 2.25092 16.3344 2.19488 16.383 2.14818C16.4315 2.10148 16.489 2.06508 16.552 2.04116C16.615 2.01724 16.6822 2.0063 16.7495 2.009Z"
            fill="white"
          />
          <path
            d="M22 8.35998V12.74C22 13.11 21.61 13.35 21.28 13.18C20.135 12.5832 18.8282 12.3709 17.5532 12.5744C16.2781 12.7779 15.1024 13.3864 14.2 14.31C13.1031 15.4279 12.4921 16.9338 12.5 18.5C12.5 19.31 12.82 20.35 13.22 21.22C13.38 21.57 13.14 22 12.75 22H7.81C4.6 22 2 19.4 2 16.19V8.35998C2 8.07998 2.22 7.85999 2.5 7.85999H21.5C21.78 7.85999 22 8.07998 22 8.35998Z"
            fill="white"
          />
        </svg>
      ) : (
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="24"
          height="24"
          viewBox="0 0 24 24"
          fill="none"
        >
          <path
            d="M21.98 15.65C21.5601 15.1324 21.0295 14.7155 20.4273 14.43C19.8251 14.1445 19.1665 13.9975 18.5 14C17.44 14 16.46 14.37 15.69 14.99C15.1605 15.4085 14.7331 15.942 14.4403 16.5501C14.1475 17.1583 13.9969 17.8251 14 18.5C14 19.91 14.64 21.16 15.65 21.98C16.42 22.62 17.42 23 18.5 23C19.64 23 20.67 22.57 21.47 21.88C21.9496 21.4569 22.334 20.9369 22.5978 20.3542C22.8615 19.7716 22.9986 19.1396 23 18.5C23 17.42 22.62 16.42 21.98 15.65ZM19.53 18.78C19.53 19.04 19.39 19.29 19.17 19.42L17.76 20.26C17.64 20.33 17.51 20.37 17.37 20.37C17.12 20.37 16.87 20.24 16.73 20.01C16.52 19.65 16.63 19.19 16.99 18.98L18.03 18.36V17.1C18.03 16.69 18.37 16.35 18.78 16.35C19.19 16.35 19.53 16.69 19.53 17.1V18.78ZM14.73 5.86V2.5C14.73 2.22 14.51 2 14.23 2H9.77C9.49 2 9.27 2.22 9.27 2.5V5.86C9.27 6.14 9.49 6.36 9.77 6.36H14.23C14.51 6.36 14.73 6.14 14.73 5.86ZM7.249 2.02C4.689 2.18 2.939 3.5 2.289 5.7C2.189 6.03 2.429 6.36 2.769 6.36H7.269C7.549 6.36 7.769 6.14 7.769 5.86V2.52C7.76906 2.45262 7.75549 2.38593 7.72912 2.32392C7.70275 2.26192 7.66412 2.20588 7.61556 2.15918C7.56699 2.11248 7.50948 2.07608 7.44649 2.05216C7.3835 2.02824 7.31633 2.0173 7.249 2.02ZM16.75 2.009C19.31 2.169 21.06 3.489 21.71 5.689C21.81 6.019 21.57 6.349 21.23 6.349H16.73C16.45 6.349 16.23 6.129 16.23 5.849V2.509C16.2299 2.44162 16.2435 2.37493 16.2699 2.31292C16.2963 2.25092 16.3349 2.19488 16.3834 2.14818C16.432 2.10148 16.4895 2.06508 16.5525 2.04116C16.6155 2.01724 16.6827 2.0063 16.75 2.009Z"
            fill="white"
          />
          <path
            d="M22 8.35998V12.74C22 13.11 21.61 13.35 21.28 13.18C20.135 12.5832 18.8282 12.3709 17.5532 12.5744C16.2781 12.7779 15.1024 13.3864 14.2 14.31C13.1031 15.4279 12.4921 16.9338 12.5 18.5C12.5 19.31 12.82 20.35 13.22 21.22C13.38 21.57 13.14 22 12.75 22H7.81C4.6 22 2 19.4 2 16.19V8.35998C2 8.07998 2.22 7.85999 2.5 7.85999H21.5C21.78 7.85999 22 8.07998 22 8.35998Z"
            fill="#D59A2C"
          />
        </svg>
      )}
    </>
  );
}
