import React from "react";
import { useEffect } from "react";
import { useState } from "react";
import DateIcon from "../reuseable/svg-icons/DateIcon";
import PageLoader from "../reuseable/PageLoader";
import { myFeach } from "../../api";
import parse from "html-react-parser";
export default function ArticleDetailesDetailesModal({
  id,
  setopenDetailesModal,
}) {
  const [articleDetailes, setarticleDetailes] = useState({});
  const [loader, setloader] = useState({
    status: "loading",
    text: "جاري تحميل تفاصيل المقال",
  });
  const closeModal = () => {
    setopenDetailesModal({ status: false, personId: "" });
  };
  const getArticleDetailes = () => {
    myFeach(`articles/${id}`, "GET")
      .then(({ data }) => {
        setarticleDetailes(data.article);
        setloader({ status: "done", text: "" });
      })
      .catch((err) => {
        console.log(err);
        setloader({ status: "failed", text: "فشل تحميل بيانات الكتاب" });
      });
  };
  useEffect(() => {
    getArticleDetailes();
  }, []);
  return (
    <div className="modal_container">
      <button onClick={closeModal} className="modal_bg"></button>
      <div className="esc_modal_year_detailes">
        <button onClick={closeModal}>x</button>
      </div>
      <div className="modal_content">
        {loader.status === "done" ? (
          <>
            {articleDetailes.imagePath && (
              <figure className="w-2/3">
                <img
                  src={articleDetailes?.imagePath}
                  className="h-72 block my_rounded"
                />
              </figure>
            )}
            <h1 className="mt-3 font_primary">{articleDetailes.title}</h1>
            <h4>
              <span>تمت الكتابة بواسطة:</span>
              <span className="text_secondary">
                {articleDetailes.author.fullName}
              </span>
            </h4>
            <div className="flex items-center mb-28">
              <h4 className="ml-2">{articleDetailes.publishedAt}</h4>
              <DateIcon />
            </div>
            <div>{parse(articleDetailes.contant)}</div>
          </>
        ) : (
          <PageLoader loader={loader} text={loader.text} />
        )}
      </div>
    </div>
  );
}
